import React, { useState, useEffect, useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from '../../../redux/store';
import AdminContext from '../../../Context/adminContext';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import styles from './ClientList.module.css';
import {
  getClientsList,
  setTypeFilter,
  setTagsFilter,
  setSearchTerm,
  setinputValue,
  setCurrentPage,
  setSortColumn,
  setSortDirection,
  fetchClientExport,
} from "../../../redux/slices/client";
import {
  getRunners,
  createClient
} from "../../../redux/slices/opportunitiesdata";
import ClientForm from '../../../Components/ClientForm/ClientForm';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import ApiResponseDisplay from '../../../Components/ApiResponseDisplay/ApiResponseDisplay';
import ClientListTable from '../../../Sections/clients/ClientListTable';
import ProjectTablePagination from '../../Projects/ProjectList/ProjectTablePagination';

function ClientList() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];
  const admin = useContext(AdminContext);
  const [addNewPopUp, setAddNewPopUp] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [typeOptions, setTypeOptions] = useState(['All', 'Home Owner', 'Professional']);
  const [tagOptions, setTagOptions] = useState(['All', 'Bad Debtor', 'Google Review', '2024', '2023', '2022', '2021', '2020', '2019']);

  const {
    currentPage,
    clientList,
    tagsFilter,
    typeFilter,
    searchTerm,
    inputValue,
    clientListLoading,
    sortDirection,
    sortColumn,
    error,
  } = useSelector((state) => state.client);


  const totalPages = clientList.totalRows > 0 ? Math.ceil(clientList.totalRows / rowsPerPage) : 0;
  const currentPageValue = clientList.totalRows > 0 ? currentPage : 0;

  useEffect(() => {
    dispatch(getClientsList(typeFilter, tagsFilter, searchTerm, currentPage, rowsPerPage, sortColumn, sortDirection));
    setPaginationLoading(false);
    dispatch(getRunners());
  }, [typeFilter, tagsFilter, sortColumn, sortDirection, currentPage, searchTerm]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortDirection('asc'));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handleSearch = (event) => {
    dispatch(setinputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setSearchTerm(inputValue));
    dispatch(setCurrentPage(1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handleAddNewClientSubmit = (formData) => {
    dispatch(createClient(formData));
    setAddNewPopUp(false);
    dispatch(setinputValue(''));
    dispatch(setSearchTerm(''));
  }

  const handleTypeFilter = (type) => {
    if (type == "All") {
      dispatch(setTypeFilter(''));
      dispatch(setCurrentPage(1));
    } else {
      dispatch(setTypeFilter(type));
      dispatch(setCurrentPage(1));
    }
  };

  const handleTagsFilter = (type) => {
    dispatch(setTagsFilter(type));
    dispatch(setCurrentPage(1));
  };

  return (
    <div className={styles.ClientMainContent}>
      <div className={styles.ClientHeader}>
        <div className={styles.ClientTitle}>
          Client List
        </div>
        {admin &&
          <div className={`${styles.Btn} ${clientListLoading ? styles.AddDisabled : ''}`} onClick={!clientListLoading ? () => dispatch(fetchClientExport()) : null}>
            EXPORT
          </div>
        }
      </div>

      <div className={styles.ClientTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.FilterContainer}>
            <label className={styles.FilterLabel} htmlFor='TypeFilter' style={{ fontSize: '12px' }}>Type:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={typeOptions} initialSelectedValue={typeFilter} onValueChange={handleTypeFilter} disabled={clientListLoading} />
            </div>
          </div>

          <div className={styles.FilterContainer}>
            <label className={styles.FilterLabel} htmlFor='TagsFilter' style={{ fontSize: '12px' }}>Tags:</label>
            <div className={styles.dropdownContainer}>
              <Dropdown options={tagOptions} initialSelectedValue={tagsFilter} onValueChange={handleTagsFilter} disabled={clientListLoading} />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <div className={`${styles.AddBtn} ${clientListLoading ? styles.AddDisabled : ''}`} onClick={!clientListLoading ? () => setAddNewPopUp(true) : null}>
              ADD
            </div>
          )}
          <SearchBar value={inputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={clientListLoading} onClick={handleSearchSubmit} />

        </div>
      </div>

      {clientListLoading ? (
        <React.Fragment>
          <ClientListTable
            rows={[]}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            loading={clientListLoading}
          />

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={clientList.rows.length}
            totalRows={clientList.totalRows}
            rowsPerPage={rowsPerPage}
            loading={clientListLoading}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ClientListTable
            rows={clientList.rows}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            loading={clientListLoading}
          />

          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={clientList.rows.length}
            totalRows={clientList.totalRows}
            rowsPerPage={rowsPerPage}
            loading={clientListLoading}
          />

          {error && error !== null && (
            <ApiResponseDisplay apiResponse={error} setIsResponseShown={setIsResponseShown} />
          )}

          {addNewPopUp && (
            <ClientForm
              Name={''}
              onSubmit={handleAddNewClientSubmit}
              onClose={() => setAddNewPopUp(false)}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default ClientList;