import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import AdminContext from '../../../Context/adminContext';
import Topbar from "../../../Components/Topbar/Topbar";

import './Invoicing.css';
import Table from '../../../Components/Table/TableV2';
import { Box, CircularProgress } from '@mui/material';
import EditDiv from '../../../Components/EditDiv/EditDiv';
import InvSummaryTable from '../../../Components/InvoiceSummaryTable/InvSummaryTable';

import { useDispatch, useSelector } from "../../../redux/store";
import {
  getInvSummaryCards,
  fetchMonthBillBreakdownExport,
  getInvSummaryTable,
} from "../../../redux/slices/invoice";

export const TopbarData = [
  {
    title: "Personal",
    link: "/dashboard/personal",
    disabled: true,
  },
  {
    title: "Opportunities",
    link: "/dashboard/opportunities",
    disabled: false,
  },
  {
    title: "Invoicing",
    link: "/dashboard-invoicing",
    disabled: false,
  },
];

function Invoicing() {
  const API_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = accounts[0];

  const admin = useContext(AdminContext);

  const [reload, setReload] = useState(0);
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [editType, setEditType] = useState('UPDATE');
  const [rowIndex, setRowIndex] = useState(null);
  const [fetchedData, setFetchedData] = useState([]);
  const { invSummaryTableData, invSummaryCardData, isinvSummaryCardLoading, isinvSummaryTableLoading, error } = useSelector((state) => state.invoice);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getInvSummaryTable());
    dispatch(getInvSummaryCards());
  }, [dispatch]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/invoicing/pageData`);
      const data = response.data;
      setFetchedData(data);
    } catch (error) {
      console.error(error);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (data) => () => {
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const handleRowEdit = (data, SQLid, index) => () => {
    let newData = { ...data };
    newData.DataEdit = newData.DataEdit.map((item, i) => ({
      ...item,
      dataName: newData.headers[i + 1],
      data: newData.rows[index][i + 1],
    }));
    setEditType('UPDATE');
    setRowIndex(SQLid);
    handleEdit(newData)();
  };

  const handleUpdateClick = async (DataTitle, selectedValues) => {
    try {
      const response = await fetch(`${API_URL}/api/invoicing/UpdatingInformation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Action: editType,
          SectionName: DataTitle,
          UpdatedValues: selectedValues,
          RowID: rowIndex,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setReload(reload + 1);
        setApiResponse(data.message);
      } else {
        setApiResponse('Error updating supplier (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in Supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
    }
  };

  const handleDoneClick = async (id) => {
    try {
      const response = await fetch(`${API_URL}/api/invoicing/completeInvoiceRequest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ID: id }),
      });
      if (response.ok) {
        const data = await response.json();
        setReload(reload + 1);
        setApiResponse(data.message);
      } else {
        setApiResponse('Error updating supplier (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in Supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [reload]);

  return (
    <>
      <div className='InvoicingMainContent'>
        <div className='dashboardInvoicing'>
          <Topbar TopbarData={TopbarData} />
        </div>
        {loading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(90% - 80px)' }}>
            <CircularProgress size={65} />
          </Box>
        ) : (
          <div className='row'>
            <div className='column'>
              <div className='InvoicingTitle'>Invoices To Raise</div>
              {
              Object.keys(fetchedData.teams)?.length === 0 ?
                <div className='divNoinfo'>No outstanding invoices to be raised.</div>
              : 
              Object.keys(fetchedData.teams).map(teamName => (
                <div className='TeamSection' key={teamName}>
                  <div className='InvoicingSubTitle'>{teamName}</div>
                  <div className='InvoicingTableLayout'>
                    <Table data={fetchedData.teams[teamName]} handleRowEdit={handleRowEdit} handleButtonClick={handleDoneClick} />
                  </div>
                </div>
              ))}
            </div>
            <div className='column'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='InvoicingTitle SummaryTitle'>Invoice Summary (This Month)</div>
                {
                  admin &&
                  <div className={`Btn ${loading ? 'AddDisabled' : ''}`} style={{ marginTop: '35px' }} onClick={!loading ? () => dispatch(fetchMonthBillBreakdownExport()) : null}>
                    EXPORT SUMMARY
                  </div>
                }
              </div>
              <div className='Container'>
                <div className='card-container'>
                  <div className="card-content">
                    <div className="card-title">Planned</div>
                    <div className="card-amount">{`£${Number(invSummaryCardData.planned).toLocaleString('en-GB', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}</div>
                  </div>
                </div>
                <div className='card-container'>
                  <div className="card-content">
                    <div className="card-title">Invoiced</div>
                    <div className="card-amount">{`£${Number(invSummaryCardData.invoiced).toLocaleString('en-GB', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}</div>
                  </div>

                </div>
              </div>

              <div className='invSummaryTable'>
                <InvSummaryTable jsonData={invSummaryTableData} clickableRowAdd={'Projects/ProjectProfile'} />
              </div>
            </div>
          </div>
        )}
      </div>

      {isResponseShown && (
        <div className='ProjectProfileOverlay'>
          <div className='ProjectProfileResponseBox'>
            <div
              style={{
                fontSize: 16,
                fontFamily: 'PT Sans',
                textAlign: 'center',
                margin: '20px',
                marginLeft: '25px',
                marginRight: '25px',
              }}
              dangerouslySetInnerHTML={{ __html: apiResponse }}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                className='ProjectProfileButton'
                onClick={() => setIsResponseShown(false)}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      )}

      {displayEditDiv && <EditDiv data={currentData} handleUpdateClick={handleUpdateClick} setDisplayEditDiv={setDisplayEditDiv} actionType={editType} />}
    </>
  );
}

export default Invoicing;