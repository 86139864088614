import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';


// Redux

// ----------------------------------------------------------------------

let initialState = {
    clientList: { 'rows': [] },
    searchTerm: "",
    inputValue: "",
    sortColumn: "",
    sortDirection: "asc",
    currentPage: 1,
    typeFilter: "All",
    tagsFilter: "All",
    clientListLoading: true,
    error: null,
};

export const slice = createSlice({
    name: "client",
    initialState,
    reducers: {
        // set filter values in redux
        setClientList(state, action) {
            state.clientList = action.payload;
        },

        setTypeFilter(state, action) {
            state.typeFilter = action.payload;
        },

        setTagsFilter(state, action) {
            state.tagsFilter = action.payload;
        },

        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },

        setClientListLoading(state, action) {
            state.clientListLoading = action.payload;
        },

        setinputValue(state, action) {
            state.inputValue = action.payload;
        },

        setSortDirection(state, action) {
            state.sortDirection = action.payload;
        },

        setSortColumn(state, action) {
            state.sortColumn = action.payload;
        },

        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },


        // HAS ERROR
        hasError(state, action) {
            state.isinvSummaryTableLoading = false;
            state.error = action.payload;
        },

    },
});

export const {
    setTypeFilter,
    setTagsFilter,
    setSearchTerm,
    setClientListLoading,
    setinputValue,
    setCurrentPage,
    setSortColumn,
    setSortDirection,
    setPaginationLoading,
    hasError,
} = slice.actions

export default slice.reducer;

export function getClientsList(typeFilter, tagsFilter, searchTerm, page, rowsPerPage, sortColumn, sortDirection) {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.setClientListLoading(true));
        try {
            // Construct the query string
            const queryParams = new URLSearchParams({
                typeFilter,
                tagsFilter,
                page,
                rowsPerPage,
                searchTerm,
                sortColumn,
                sortDirection
            }).toString();


            const response = await axios.get(`${API_URL}/api/clients/all?${queryParams}`);
            dispatch(slice.actions.setClientList(response.data));
            dispatch(slice.actions.setClientListLoading(false));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.setClientListLoading(false));

        }
    };
}

export function fetchClientExport() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async (dispatch) => {
        try {
            // Make an API call to request the client data in CSV format
            const response = await axios.get(`${API_URL}/api/export/clients`, {
                responseType: 'blob',  // Setting responseType to 'blob' to handle binary data.
            });

            // Create a Blob object from the response data
            const file = new Blob([response.data], { type: 'text/csv' });

            // Generate a URL for the Blob object
            const fileURL = URL.createObjectURL(file);

            // Create a temporary anchor element to enable file download
            const tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'Clients Dashboard.csv'); // Setting the default file name for CSV
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click(); // Trigger the download
            document.body.removeChild(tempLink);

            // Revoke the Blob URL to free up resources
            URL.revokeObjectURL(fileURL);

            // Dispatch a success action, could update state to show download was successful
            dispatch({ type: 'DOWNLOAD_SUCCESS' });
        } catch (error) {
            // Log error if the export process fails and dispatch an error state update
            console.error('Error during export:', error);
            dispatch({ type: 'DOWNLOAD_ERROR', error });
        }
    };
}

// export function getInvSummaryCards() {
//     const API_URL = process.env.REACT_APP_API_URL;
//     return async () => {
//         dispatch(slice.actions.setInvSummaryCardLoading(true));
//         try {
//             const response = await axios.get(`${API_URL}/api/invoicing/summary/cards`);
//             dispatch(slice.actions.setInvoiceSummaryCards(response.data.data));
//         } catch (error) {
//             console.error(error);
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }
