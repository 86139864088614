import React from 'react';
import { useMsal } from "@azure/msal-react";
import { useFormik } from "formik";
import { FormikProvider } from 'formik';
import textfieldStyle from "../../GlobalStyles/styles";
import { TextField } from '@mui/material';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import styles from './FeePropFeePopUp.module.css';
import { handleSubmitFee, completeTask } from "../../redux/slices/dashboard";
import { useDispatch } from "../../redux/store";
import * as Yup from 'yup';

const FeePropFeePopUp = ({ isVisible, onClose, onSubmit, taskData }) => {
    const { accounts } = useMsal();
    const account = accounts[0];
    const dispatch = useDispatch();

    // Form validation schema using Yup
    const validationSchema = Yup.object().shape({
        fee: Yup.number().min(0).required('Fee is required'),
        // comment: Yup.string().required('Comment is required'),
        suitableRunner: Yup.string().required('Please select a suitable runner/team'),
    });

    const initialValues = {
        fee: '',
        comment: '',
        suitableRunner: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            const modalData = {
                jobNumber: taskData.jobNumber,
                fee: values.fee,
                comment: values.comment,
                commentForSuitableTeam: values.suitableRunner,
                updatedBy: account.username,
            };

            dispatch(handleSubmitFee(modalData)); // Dispatch the action with modalData
            dispatch(completeTask(taskData.jobNumber, taskData.deadlineID, taskData.deadlineType, taskData.updatedBy));
            onClose(); // Close the modal
            console.log(values);
        },
    });
    const { getFieldProps, touched, errors, handleSubmit } = formik;

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <FormikProvider value={formik} handleSubmit={handleSubmit}>
                    <p className={styles.text}>Please confirm the fee for this project</p>
                    <div className={styles.commentModal_feeSection}>
                        <TextField
                            value={formik.values.fee}
                            type="number"
                            onChange={(e) =>
                                formik.setFieldValue("fee", e?.target?.value)
                            }
                            placeholder="£"
                            sx={textfieldStyle}
                            error={Boolean(touched.fee && errors.fee)}
                        />
                    </div>
                    <div className={styles.commentModal_commentSection}>
                        <TextField
                            fullWidth
                            value={formik.values.comment}
                            type="text"
                            onChange={(e) =>
                                formik.setFieldValue("comment", e?.target?.value)
                            }
                            multiline
                            rows={1}
                            placeholder="Comment"
                            sx={textfieldStyle}
                        />
                    </div>
                    <p className={styles.text}>Please advise on a suitable Team/Runner</p>
                    <div styles={{ marginBlock: '0px' }} className={styles.commentModal_teamCommentSection} >
                        <TextField
                            fullWidth
                            value={formik.values.suitableRunner}
                            type="text"
                            onChange={(e) =>
                                formik.setFieldValue("suitableRunner", e?.target?.value)
                            }
                            multiline
                            rows={1}
                            placeholder="Comment suitable runner"
                            sx={textfieldStyle}
                            error={Boolean(touched.suitableRunner && errors.suitableRunner)}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <div style={{ width: '74px', height: '25px' }}>
                            <button className={buttonStyles.YesButtonComponentStyling} onClick={handleSubmit}>SUBMIT</button>
                        </div>
                        <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                            <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>CANCEL</button>
                        </div>
                    </div>
                </FormikProvider>
            </div>

        </div>
    );
};

export default FeePropFeePopUp;
