import React, { useState, useEffect } from 'react';
import styles from './ProfileForm.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import TickBox from '../TickBox/TickBox';
import BlueLogo from '../../assets/BlueLogo.png';
import CommentBox from '../CommentBox/CommentBox';
import DocumentUpload from './DocumentUpload'

function ProfileForm({data, handleFormSubmit, uploadDocument, setDisplayProfileForm, actionType}) {
  const [selectedValues, setSelectedValues] = useState({});
  const updateSelectedValue = (dataName) => (newValue) => {
    console.log(`Updating ${dataName} with ${newValue}`); // add this line
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const handleClose = () => {
    setDisplayProfileForm(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  const [PatternYesTick, setPatternYesTick] = useState(false);
  const [PatternNoTick, setPatternNoTick] = useState(false);

  useEffect(() => {
    if (data.Pattern == 'Yes') {
      setPatternYesTick(true)
    } else if (data.Pattern == 'No') {
      setPatternNoTick(true)
    }
  }, []);

  const [PatternColoureis, setPatternColoureis] = useState('');
  const handleTickClick_PatternYes = () => {
    if (PatternYesTick == false) {
      setPatternYesTick(true)
      setPatternNoTick(false)
      setPatternColoureis('')
    } else if (PatternYesTick) {
      setPatternYesTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };
  const handleTickClick_PatternNo = () => {
    if (PatternNoTick == false) {
      setPatternNoTick(true)
      setPatternYesTick(false)
      setPatternColoureis('')
    } else if (PatternNoTick) {
      setPatternNoTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };

  const [WorkYesTick, setWorkYesTick] = useState(false);
  const [WorkNoTick, setWorkNoTick] = useState(false);

  useEffect(() => {
    if (data.Work == 'YES') {
      setWorkYesTick(true)
    } else if (data.Work == 'NO') {
      setWorkNoTick(true)
    }

    if (data.Pattern == 'YES') {
      setPatternYesTick(true)
    } else if (data.Pattern == 'NO') {
      setPatternNoTick(true)
    }
  }, []);

  const [workColoureis, setWorkColoureis] = useState('');
  const handleTickClick_WorkYes = () => {
    if (WorkYesTick == false) {
      setWorkYesTick(true)
      setWorkNoTick(false)
      setWorkColoureis('')
    } else if (WorkYesTick) {
      setWorkYesTick(false)
    }
    console.log('WorkYesTick: ', WorkYesTick)
    console.log('WorkNoTick: ', WorkNoTick)
  };
  const handleTickClick_WorkNo = () => {
    if (WorkNoTick == false) {
      setWorkNoTick(true)
      setWorkYesTick(false)
      setWorkColoureis('')
    } else if (WorkNoTick) {
      setWorkNoTick(false)
    }
    console.log('WorkYesTick: ', WorkYesTick)
    console.log('WorkNoTick: ', WorkNoTick)
  };

  const [coloureis, setColoureis] = useState('');
  const [confirmationTick, setConfirmationTick] = useState(false);
  const handleTickClick_confirmation = () => {
    if (confirmationTick == false) {
      setConfirmationTick(true)
      setColoureis('')
    } else if (confirmationTick) {
      setConfirmationTick(false)
      setColoureis('')
    }
    console.log('confirmation: ', confirmationTick)
  };

  const [reasonComment, setReasonComment] = useState(data.Reason);
  const [patternComment, setPatternComment] = useState(data.PatternExplanation);
  const [workComment, setWorkComment] = useState(data.WorkExplanation);

  const [patternCommentColour, setPatternCommentColour] = useState('');
  const [workCommentColour, setWorkCommentColour] = useState('');
  const [reasonCommentColour, setReasonCommentColour] = useState('');

  const [satisfied, setSatisfied] = useState(true);

  const [docUpload, setDocUpload] = useState(false);
  const [docInfo, setDocInfo] = useState(null);

  
  const handleSubmissionClick = () => {
    console.log('selectedValues: ', selectedValues)
    setSatisfied(true)
    
    setPatternCommentColour('')
    setWorkCommentColour('')

    if (PatternYesTick & patternComment=='') {
      setPatternCommentColour('#D32030')
      setSatisfied(false)
    } 

    if (WorkYesTick == false & WorkNoTick == false) {
      setWorkColoureis('#D32030')
      setSatisfied(false)
    } 

    if (PatternYesTick == false & PatternNoTick == false) {
      setPatternColoureis('#D32030')
      setSatisfied(false)
    } 

    if (confirmationTick == false) {
      setColoureis('#D32030')
      setSatisfied(false)
    }
    
    if (WorkYesTick & workComment=='') {
      setWorkCommentColour('#D32030')
      setSatisfied(false)
    } 

    if (reasonComment=='') {
      setReasonCommentColour('#D32030')
      setSatisfied(false)
    }

    console.log('satisfied: ', satisfied)
    
    if (confirmationTick & satisfied) {
      let information = {
        'reasonComment': reasonComment,
        'PatternYesTick': PatternYesTick,
        'PatternNoTick': PatternNoTick,
        'patternComment': patternComment,
        'WorkYesTick': WorkYesTick,
        'WorkNoTick': WorkNoTick,
        'workComment': workComment,
      }
      uploadDocument(docInfo)
      handleFormSubmit(data.DataEditTitle, selectedValues, 'SUBMIT', information)
    }
  };

  const handleDraftClick = () => {
    let information = {
      'reasonComment': reasonComment,
      'PatternYesTick': PatternYesTick,
      'PatternNoTick': PatternNoTick,
      'patternComment': patternComment,
      'WorkYesTick': WorkYesTick,
      'WorkNoTick': WorkNoTick,
      'workComment': workComment,
    }
    uploadDocument(docInfo)
    handleFormSubmit(data.DataEditTitle, selectedValues, 'DRAFT', information)
  };

  return (
    <div className={styles.backGround} onClick={handleClose}>
      <div className={styles.form} onClick={handleFormClick}>
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Employee Return To Work Form</div>

        <div className={styles.dataSection}>
          <div className={styles.row}>
            <div className={styles.dataTitle}>Name:</div>
            <div className={styles.NonEditable}>{data.Name}</div>
          </div>

          <div className={styles.doubleRow}>
            <div className={styles.row}>
              <div className={styles.dataTitle}>First Date of Absence:</div>
              <div className={styles.doubleRowNonEditable}>{data.StartDate}</div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleRowDataTitle}>Return to Work Date:</div>
              <div className={styles.doubleRowNonEditable}>{data.RTWdate}</div>
            </div>
          </div>

          <div className={styles.doubleRow}>
            <div className={styles.row}>
              <div className={styles.dataTitle}>Last Date of Absence:</div>
              <div className={styles.doubleRowNonEditable}>{data.EndDate}</div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleRowDataTitle}>No. of Working Days Absent:</div>
              <div className={styles.doubleRowNonEditable}>{data.Length}</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitleWithDescription}>
              <div className={styles.dataTitleWithExplanation}>Reason For Absence:</div>
              <div className={styles.Explanation}>
                If sick, please specify illness
                <br /><br />
                If injured, please specify injury and how it occurred
              </div>
            </div>
            <div className={styles.LongTExt} style={{marginRight: '5px'}} >
              <CommentBox initialData={reasonComment} onValueChange={setReasonComment} liningColour={reasonCommentColour} other/>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>Is this absence part of an overall pattern?</div>
            <div className={styles.YesNoTicks}>
              <TickBox type={PatternYesTick} tickClick={handleTickClick_PatternYes} coloureis={PatternColoureis}/>
              <div className={styles.Yes}>Yes</div>
              <TickBox type={PatternNoTick} tickClick={handleTickClick_PatternNo} coloureis={PatternColoureis}/>
              <div className={styles.No}>No</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>If you answered Yes, please explain how:</div>
            <div className={styles.LongTExt} style={{marginRight: '5px'}} >
              <CommentBox initialData={patternComment} onValueChange={setPatternComment} liningColour={patternCommentColour} />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>Has work contributed to your absence?</div>
            <div className={styles.YesNoTicks}>
              <TickBox type={WorkYesTick} tickClick={handleTickClick_WorkYes} coloureis={workColoureis}/>
              <div className={styles.Yes}>Yes</div>
              <TickBox type={WorkNoTick} tickClick={handleTickClick_WorkNo} coloureis={workColoureis}/>
              <div className={styles.No}>No</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>If you answered Yes, please explain how we can support you more moving forward:</div>
            <div className={styles.LongTExt} style={{marginRight: '5px'}} >
              <CommentBox initialData={workComment} onValueChange={setWorkComment} liningColour={workCommentColour} />
            </div>
          </div>
        </div>

        <div className={styles.row} style={{marginTop: '10px', whiteSpace: 'nowrap', maxWidth: '400px'}}>
          <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', whiteSpace: 'nowrap', width: '240px'}}>Absences of 7 days or more require a doctors note,<br />If you have a doctors note, please upload it here:</div>
          <div className={styles.YesNoTicks} onClick={handleFormClick}>
            <div className={styles.UPLOAD} onClick={() => setDocUpload(true)}>UPLOAD</div>
          </div>
        </div>

        <div className={styles.row} style={{marginTop: '10px', whiteSpace: 'nowrap', maxWidth: '400px'}}>
          <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', whiteSpace: 'nowrap', width: '200px'}}>I confirm the above information is correct</div>
          <div className={styles.YesNoTicks}>
            <TickBox type={confirmationTick} tickClick={handleTickClick_confirmation} coloureis={coloureis}/>
          </div>
        </div>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.DraftButton} onClick={handleDraftClick}>SAVE DRAFT</div>
          <div className={styles.updateButton} onClick={handleSubmissionClick}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/>
      </div>

      {docUpload && <DocumentUpload DocUpload={setDocInfo} setCloseDocUpload={setDocUpload}/>}
      
    </div>
  );
}

export default ProfileForm;