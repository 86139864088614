import React, { useState, useEffect } from 'react';
import styles from './StartReviewForm.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import DropDown from '../DropDownBox/Dropdown';
import BlueLogo from '../../assets/BlueLogo.png';
import DatePicker from '../DatePicker/DatePicker';

function StartReviewForm({data, handleUpdateClick, setDisplayEditDiv, actionType, required = false}) {
  const [selectedValues, setSelectedValues] = useState(data);
  const [invalidInputs, setInvalidInputs] = useState([]);

  const updateSelectedValue = (attribute, newValue, index) => {
    setSelectedValues(prevValues => prevValues.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          [attribute]: {
            ...item[attribute],
            Selected: newValue
          }
        };
      }
      return item;
    }));
  };

  useEffect(() => {
    console.log('Something changed for selectedValues: ', selectedValues)
  }, [selectedValues]); // Empty dependency array means this runs once on mount

  const validateAndSubmit = () => {
    console.log('selectedValues: ', selectedValues)
    handleUpdateClick(selectedValues); // This will also need to be updated
  };

  const handleClose = () => {
    setDisplayEditDiv(false);
    handleUpdateClick(selectedValues);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  useEffect(() => {
    console.log('selectedValues: ', selectedValues)
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className={styles.backGround} onClick={handleClose}>
      <div 
        className={styles.form}
        onClick={handleFormClick}
      >
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Start Review</div>

        <div className={styles.Titles}>
          <div className={styles.Name}></div>
          <div className={styles.Name}>Lead Reviewer</div>
          <div className={styles.Name}>Assist Reviewer</div>
          <div className={styles.Name}>Feedback 1</div>
          <div className={styles.Name}>Feedback 2</div>
          <div className={styles.Name}>Feedback 3</div>
          <div className={styles.Name}>Date</div>
          <div className={styles.TimeTextHolder}>Time</div>
        </div>

        {data.map((reviewItem, index) => {
          const { Reviewee } = reviewItem; // Destructuring for cleaner code
          return (
            <ul className={styles.List}>
              <li className={styles.dataRow}>
                <div className={styles.Name}>
                  {Reviewee}
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DropDown options={reviewItem.LeadReviewer.Options} initialSelectedValue={reviewItem.LeadReviewer.Selected} onValueChange={(newValue) => updateSelectedValue('LeadReviewer', newValue, index)} PopUp={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DropDown options={reviewItem.AssistReviewer.Options} initialSelectedValue={reviewItem.AssistReviewer.Selected} onValueChange={(newValue) => updateSelectedValue('AssistReviewer', newValue, index)} PopUp={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DropDown options={reviewItem.Feedback1.Options} initialSelectedValue={reviewItem.Feedback1.Selected} onValueChange={(newValue) => updateSelectedValue('Feedback1', newValue, index)} PopUp={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DropDown options={reviewItem.Feedback2.Options} initialSelectedValue={reviewItem.Feedback2.Selected} onValueChange={(newValue) => updateSelectedValue('Feedback2', newValue, index)} PopUp={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DropDown options={reviewItem.Feedback3.Options} initialSelectedValue={reviewItem.Feedback3.Selected} onValueChange={(newValue) => updateSelectedValue('Feedback3', newValue, index)} PopUp={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.commentHolder}>
                  <DatePicker dataName={Reviewee} initialDate={reviewItem.Date.Selected.startDate} onDatesChange={(newValue) => updateSelectedValue('Date', newValue, index)} visableIcon={true} />
                </div>
              </li>
              <li className={styles.dataRow}>
                <div className={styles.dataTitle}>
                </div>
                <div className={styles.TimeHolder}>
                  <DropDown options={reviewItem.Time.Options} initialSelectedValue={reviewItem.Time.Selected} onValueChange={(newValue) => updateSelectedValue('Time', newValue, index)} PopUp={true} />
                </div>
              </li>
            </ul>
          );
        })}

        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={validateAndSubmit}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/>
      </div>
    </div>
  );
}

export default StartReviewForm;