import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from '../ResizableTh';
import { Link } from 'react-router-dom';
import Tick from "../../assets/Tick.svg";
import { useSelector, useDispatch } from '../../redux/store';
import { setSelectedRow } from '../../redux/slices/rowhighlight';

function OppListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 40, // default width for Job No
    1: 120, // default width for Project Address
    2: 100, // default width for Client
    3: 280, // default width for Comment
    4: 40, // default width for Quoted
    5: 70, // default width for Follow Up Date
    6: 70, // default width for Enquiry Date
    7: 70, // default width for FP Sent
    8: 30, // default width for Doc Uploaded
    9: 30, // default width ISA Sent
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const selectedRow = useSelector((state) => state.rowhighlight.selectedRow);

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      const totalWidth = Object.values(prev).reduce((a, b) => a + b, 0);
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;

      if (prev[index] !== width && totalWidth - prev[index] + width <= tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        return newWidths;
      }

      if (totalWidth - prev[index] + width > tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        const excessWidth = totalWidth - prev[index] + width - tableWidth;
        const otherColumns = Object.keys(newWidths).filter((key) => key !== index);
        let remainingExcessWidth = excessWidth;

        const shrinkLeft = index > 0 && index < otherColumns.length;
        const sortedColumns = shrinkLeft ? otherColumns.sort((a, b) => a - b) : otherColumns.sort((a, b) => b - a);

        for (const column of sortedColumns) {
          if (newWidths[column] > 50 + remainingExcessWidth / otherColumns.length) {
            newWidths[column] -= remainingExcessWidth / otherColumns.length;
            remainingExcessWidth -= remainingExcessWidth / otherColumns.length;
          } else {
            remainingExcessWidth -= newWidths[column] - 50;
            newWidths[column] = 50;
          }
        }

        return newWidths;
      }

      return prev;
    });
  }, []);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem('scrollPos');
    if (scrollPos && tableRef.current) {
      tableRef.current.scrollTop = parseFloat(scrollPos);
    }
    console.log(props.rows);
  }, []);

  const uniqueRows = React.useMemo(() => {
    const jobNoSet = new Set();
    return props.rows.filter(row => {
      if (jobNoSet.has(row.jobno)) {
        return false;
      } else {
        jobNoSet.add(row.jobno);
        return true;
      }
    });
  }, [props.rows]);

  return (
    <div className='ProjectListBox'>
      <div id="oppTableContainer" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {['Job No.', 'Project Address', 'Client', 'Comment', 'Quoted', 'Follow Up', 'Enquiry Date', 'FP Sent'].map((header, index) => (
                <ResizableTh onResize={(width) => setColumnWidth(index, width)} index={index} key={index}>
                  <span
                    onClick={props.loading ? null : () =>
                      props.handleSort(
                        header.toLowerCase().replace(' ', '').replace('.', '') // Continue using existing logic for other headers
                      )
                    }
                    className={props.loading ? 'disable-click' : ''}>
                    {header}
                    {props.sortColumn === (header.toLowerCase().replace(' ', '').replace('.', '')) && (
                      props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                    )}
                  </span>
                </ResizableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.loading ? (
              [...Array(50)].map((_, index) => (
                <tr key={index}>
                  {[...Array(8)].map((_, cellIndex) => (
                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] }}>
                      <div className="skeleton" style={{ height: '13px' }}></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              uniqueRows.map((row) => (
                <tr
                  key={row.jobno}
                  onMouseEnter={() => setHoveredRow(row.jobno)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onClick={() => {
                    navigate(`OppProfile#${row.jobno}`);
                    dispatch(setSelectedRow(row.jobno));
                    if (tableRef.current) {
                      localStorage.setItem('scrollPos', tableRef.current.scrollTop);
                    }
                  }}
                  className={`
                  ${hoveredRow === row.jobno && selectedRow !== row.jobno ? 'row-hover' : ''}
                  ${selectedRow === row.jobno ? 'row-selected' : ''}
                `}
                >

                  <td style={{ width: columnWidths[0], maxWidth: '100px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '40px', textAlign: 'center' }}>{row.jobno}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[1], maxWidth: '200px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '77px', width: `${columnWidths[1]}px`, maxWidth: '200px' }}>{row.projectaddress}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[2], maxWidth: '200px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[2]}px` }}>{row.client}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[3], maxWidth: '700px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: columnWidths[3], maxWidth: '700px' }}>{row.comment}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[4], maxWidth: '100px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[4]}`, maxWidth: '100px' }}>£{row.quoted}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[5], maxWidth: '100px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[5], maxWidth: '100px' }}>{row.followup}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[6], maxWidth: '100px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[6], maxWidth: '100px' }}>{row.enquirydate}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[7], maxWidth: '100px' }}>
                    <Link to={`OppProfile#${row.jobno}`} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[7], maxWidth: '100px' }}>{row.fpsent}</div>
                    </Link>
                  </td>

                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OppListTable;