import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import stylies from './Sickness.module.css'
import DatePicker from '../../../Components/DatePicker/DatePicker';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Table from "../../../Components/Table/TableV2";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ProfileForm from '../../../Components/SicknessForm/ProfileForm';
import NewTable from './table';


function Sickness() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { accounts } = useMsal();
  const account = accounts[0]; 
  const [reload, setReload] = useState(0);
  const [loading, setLoading] = useState(false);
  const [displayProfileForm, setDisplayProfileForm] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [leaveType, setLeaveType] = useState("Cough, Cold, Flu");
  const [NameOfSickEmployee, setNameOfSickEmployee] = useState("");
  const [LengthOfLeave, setLengthOfLeave] = useState("Morning");
  const [comment, setComment] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isFormDataFetched, setIsFormDataFetched] = useState(false);
  const [data, setData]= useState(null);
  const [formData, setFormData] = useState(null);
  const [currentData, setCurrentData] = useState('11/07/2022');
  const [leaveDates, setLeaveDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
  ]);

  const dataName = 'dataName'
  const handleFocus = () => {
    setIsFocused(true);
  }
  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  }
  const [selectedValues, setSelectedValues] = useState({});
  const updateSelectedValue = (dataName) => (newValue) => {
    console.log(`Updating ${dataName} with ${newValue}`); // add this line
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const Length_of_Leave_options = [
    "Morning",
    "Afternoon"
  ]

  const leave_type_options = [
    "Cough, Cold, Flu",
    "Digestive/Stomach",
    "Eye Infection",
    "Headache, Migraine",
    "Non-Work Related Injury",
    "Work Related Injury",
    "Ongoing Health Issue",
    "Psychiatric, Stress",
    "Other",
  ];
  
  const handleSubmit = () => {
    setComment('')
  }

  useEffect(() => {
    setIsDataFetched(false);
    const fetchData = fetch(`${API_URL}/api/Profile/Sickness/PageData?userEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    });
  
    Promise.all([fetchData])
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(([fetchedData]) => {
          setData(fetchedData)
          setIsDataFetched(true);
        });
  }, [reload]);

  const [formID, setFormID] = useState("");

  // Then when the UPDATE button is clicked, you send selectedValues to the backend
  const handleFormSubmit = async (DataTitle, selectedValues, action, information) => {
    setIsDataFetched(false);
    setDisplayProfileForm(false)
    console.log('DataTitle, selectedValues', DataTitle, selectedValues)
      try {
        const response = await fetch(`${API_URL}/api/Profile/Sickness/FormUpdate?LeaveID=${encodeURIComponent(formID)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            UserEmail: account.username, 
            Action: action,
            Data: information,
          }),
      })
      if (response.ok) {
        setReload(reload + 1);
      } 
      } catch (error) {
        console.error("Error in handleCancelationReload:", error);
      }
    };

    const uploadDocument = async (docInfo) => {
        try {
          const response = await fetch(`${API_URL}/api/Profile/Sickness/DocUpload?LeaveID=${encodeURIComponent(formID)}`, {
            method: 'POST',
            body: docInfo
        })
        if (response.ok) {
          setReload(reload + 1);
        } 
        } catch (error) {
          console.error("Error in handleCancelationReload:", error);
        }
      };

    const handleReturnedClick = (id) => {
      console.log('handleReturnedClick id: ', id)
    }

    const handleFromRequestClick = (id) => {
      setLoading(true)
      setFormID(id)
      const fetchData = fetch(`${API_URL}/api/Profile/Sickness/FormData?userEmail=${encodeURIComponent(account.username)}&LeaveID=${encodeURIComponent(id)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      });
    
      Promise.all([fetchData])
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(([fetchedData]) => {
          setFormData(fetchedData)
          setLoading(false)
          setDisplayProfileForm(true)
        });
    }

    useEffect(() => {
      console.log('loading: ', loading)
    }, [loading]);

    const headerData = {
      headers: ['Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
    };

    const OpenSicknessTableRef = useRef(null);
    const DocumentTableRef = useRef(null);

    const [OpenSicknessMaxTableWidth, setOpenSicknessMaxTableWidth] = useState(0);
    const [DocumentTableMaxWidth, setDocumentMaxTableWidth] = useState(0);

    useEffect(() => {
      if (OpenSicknessTableRef.current) {
        setOpenSicknessMaxTableWidth(OpenSicknessTableRef.current.offsetWidth);
      }
    }, [OpenSicknessTableRef.current]);

    useEffect(() => {
      if (DocumentTableRef.current) {
        setDocumentMaxTableWidth(DocumentTableRef.current.offsetWidth);
      }
    }, [DocumentTableRef.current]);

    return (
      <>
        {isDataFetched ? (
          <div className={stylies.mainContent}>
            <div className={stylies.OpenSicknessSection}>

              <div className={stylies.title}>Sickness Overview</div>

              <div className={stylies.sicknessInfo}>
                <div className={stylies.numBox} style={{marginRight: '25px'}}>
                  <div className={stylies.number} style={{marginLeft: '20px'}}>{data?.SickDaysTaken}</div>
                  <div className={stylies.takenNumBoxText}>Sick Days Taken in 12 Months</div>
                </div>
                <div className={stylies.numBox}>
                  <div className={stylies.number}>{data?.PeriodsOfAbsence}</div>
                  <div className={stylies.numBoxText}>Periods of Absence in 12 Months</div>
                </div>
              </div>

              <div className={stylies.OpenSicknessTable} ref={OpenSicknessTableRef}>
                <Table data={data?.SicknessLogProfileData} indexPassed={true} handleButtonClick={handleFromRequestClick} Resizable={false} MaxWidthTable={OpenSicknessMaxTableWidth}/>
              </div>
            </div>

            <div className={stylies.OpenSicknessSection}>
              <div className={stylies.title}>Documents</div>
              <div className={stylies.OpenSicknessTable} style={{width: '100%', height: '360px', maxWidth: '10000px'}} ref={DocumentTableRef}>
                <Table data={data?.Documents} indexPassed={false} handleButtonClick={handleReturnedClick} Resizable={false} MaxWidthTable={DocumentTableMaxWidth}/>
              </div>
            </div>

            {loading ? // Here is the code for displaying error/success messages
              <div className={stylies.LoadOverlay}>
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress size={65} />
                </Box>
              </div>
            : 
              displayProfileForm && <ProfileForm data={formData} handleFormSubmit={handleFormSubmit} uploadDocument={uploadDocument} setDisplayProfileForm={setDisplayProfileForm} actionType={'SUBMIT'} />
            }
            
          </div>
        ) : (
          <Box sx={{display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center'}}>
            <CircularProgress size={65}/>
          </Box>
        )}
      </>
    );
  }

export default Sickness;