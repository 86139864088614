import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import HandleLogin from "./HandleLogin";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <MsalProvider instance={msalInstance}>
    <ReduxProvider store={store}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <BrowserRouter>
          <HandleLogin />
        </BrowserRouter>
      </SnackbarProvider>
    </ReduxProvider>
  </MsalProvider>
);

reportWebVitals();
