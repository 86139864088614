import { combineReducers } from 'redux';
import filtersReducer from './slices/filters';
import rowReducer from './slices/rowhighlight';
import suppliersReducer from './slices/suppliers';
import opportunitiesReducer from './slices/opportunitiesdata';
import dashboardReducer from './slices/dashboard';
import invoiceReducer from './slices/invoice';
import raiseInvoiceReducer from './slices/raiseInvoice';
import clientReducer from './slices/client';
import projectReducer from './slices/project';
import settingsReducer from './slices/settings';

const rootReducer = combineReducers({
  filters: filtersReducer,
  project: projectReducer,
  rowhighlight: rowReducer,
  suppliers: suppliersReducer,
  opportunities: opportunitiesReducer,
  dashboard: dashboardReducer,
  invoice: invoiceReducer,
  raiseInvoice: raiseInvoiceReducer,
  client: clientReducer,
  settings: settingsReducer,
});

export { rootReducer };