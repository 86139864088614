import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import Dropdown from '../DropDownBox/DropdownV2';
import CommentBox from '../CommentBox/CommentBox';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import styles from './FollowUpPopUp.module.css';

import { updateFollowUpDate } from "../../redux/slices/opportunitiesdata";
import { useDispatch, useSelector } from "../../redux/store";
import DatePicker from '../DatePicker/DatePicker';

const FollowUpPopUp = ({ isVisible, onClose, onSubmit, jobNumber }) => {
    const { accounts } = useMsal();
    const account = accounts[0];
    const dispatch = useDispatch();

    const [dateRange, setDateRange] = useState({
        startDate: new Date(), // Default to today, adjust as needed
        endDate: new Date(), // Default to today, adjust as needed
    });

    // Function to handle date changes
    const handleDatesChange = (ranges) => {
        const { startDate, endDate } = ranges || {};
        console.log(startDate, endDate)

        if (startDate && endDate) {
            // Assuming startDate and endDate are strings in ISO format, convert them to Date objects
            const start = new Date(startDate);
            const end = new Date(endDate);

            // Adjust for the timezone offset to get the local date-time in ISO format
            const localStartDate = new Date(start.getTime() - (start.getTimezoneOffset() * 60000)).toISOString();
            const localEndDate = new Date(end.getTime() - (end.getTimezoneOffset() * 60000)).toISOString();

            console.log(localEndDate, localStartDate);

            setDateRange({
                startDate: start,
                endDate: end
            });
        } else {
            console.log('startDate or endDate is undefined');
            // Handle the case where dates are undefined
            // For example, reset the date range state or provide default dates
        }

    };

    // Call the `onSubmit` prop when you need to submit the modal's data
    const handleSubmit = () => {
        const formattedStartDate = dateRange.startDate.toISOString();

        // Call `onSubmit` prop function and pass it the data
        dispatch(updateFollowUpDate(jobNumber, formattedStartDate, account.username));// Dispatch the action with modalData

        onSubmit();

        // Clear the form fields
        setDateRange({
            startDate: new Date(), // Default to today
            endDate: new Date(), // Default to today
        })

        // Close the modal
        onClose();
    };

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <p className={styles.text}>Please select a follow up date</p>
                <div styles={{ marginBlock: '0px' }}>
                    <DatePicker
                        initialDate={`${dateRange.startDate.toISOString().split('T')[0]}`}
                        onDatesChange={item => handleDatesChange(item)}
                        visibleIcon={true}
                    />

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <div style={{ width: '74px', height: '25px' }}>
                        <button className={buttonStyles.YesButtonComponentStyling} onClick={handleSubmit}>SUBMIT</button>
                    </div>
                    <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                        <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FollowUpPopUp;
