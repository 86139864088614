import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";

import './App.css';
import './BalooBhaiFontFamily.css';

import AdminContext from './Context/adminContext';

import Sidebar from './Components/Sidebar/Sidebar';

import Dashboard from './Pages/Dashboard/Dashbord';
// import Personal from './Page/Dashboard/Personal/Personal';
import OppDashboard from './Pages/Dashboard/Opportunities/DashboardOpportunities';

import Opportunities from './Pages/Opportunities/Main/Opportunities';

import Projects from './Pages/Projects/Projects';
import ProjectList from './Pages/Projects/ProjectList/ProjectList';
import ProjectProfile from './Pages/Projects/ProjectProfile/ProjectProfile';

import Timesheets from './Pages/Timesheets/Main/Timesheets';

import Client from './Pages/Client/Client';
import ClientList from './Pages/Client/ClientList/ClientList';
import ClientProfile from './Pages/Client/ClientProfile/ClientProfile';

import Suppliers from './Pages/Suppliers/Suppliers';
import SuppliersList from './Pages/Suppliers/SuppliersList/SuppliersList';
import SuppliersProfile from './Pages/Suppliers/SuppliersProfile/SuppliersProfile';

import Invoicing from './Pages/Invoicing/Main/Invoicing';
import InvoiceList from './Pages/Invoicing/Main/InvoicingList';
import RaiseInvoice from './Pages/RaiseInvoice/RaiseInvoice';

import Profile from './Pages/Profile/Profile';
import Holiday from './Pages/Profile/Holiday/Holiday';
import Sickness from './Pages/Profile/Sickness/Sickness';
import Training from './Pages/Profile/Training/Training';
import Reviews from './Pages/Profile/Reviews/Reviews';
import EmployeeInfo from './Pages/Profile/EmployeeInfo/EmployeeInfo';

import Approvals from './Pages/Admin/Admin';
import ApprovalHoliday from './Pages/Admin/Holiday/Holiday';
import ApprovalSickness from './Pages/Admin/Sickness/Sickness';
import AdminReviews from './Pages/Admin/Reviews/Reviews';
import ApprovalEmployeeInfo from './Pages/Admin/EmployeeInfo/EmpInfoList';
import AdminSickness from './Pages/Admin/Sickness/Sickness';
import Settings from './Pages/Admin/Settings/Settings';
import EndOfYearAdjustment from './Pages/Admin/Settings/EndOfYearAdjustment/EndOfYearAdjustment';

function App() {
    const API_URL = process.env.REACT_APP_API_URL;
    const { accounts } = useMsal();
    const account = accounts[0];

    const [count, setCount] = useState(0);
    const [increase, setIncrease] = useState(1);

    useEffect(() => {
        const handleClick = (event) => {
            if (event.ctrlKey && event.button === 0) {
                setCount((prevCount) => prevCount + increase);
                setIncrease(increase + increase)
            }
        };

        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);


    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (account) {
            fetch(`${API_URL}/api/checkIfAdmin?email=${account.username}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => setIsAdmin(data.message));
        }
        console.log('account.username: ', account.username)
    }, [account]);

    return (
        <div className="app-container">
            <AdminContext.Provider value={isAdmin}>
                <Sidebar />

                <Routes>
                    <Route path="/" element={<Navigate to="/projects" />} />

                    <Route path='/dashboard' element={<Dashboard />}>
                        <Route index element={<Navigate to='./opportunities' />} />
                        {/* <Route path='Personal' element={<Personal />} /> */}
                        <Route path='opportunities' element={<OppDashboard />} />
                    </Route>

                    <Route path="/Profile" element={<Profile />}>
                        <Route path="/Profile" element={<Navigate to='./Leave' />} />
                        <Route path="Leave" element={<Holiday />} />
                        <Route path="Sickness" element={<Sickness />} />
                        <Route path="Training" element={<Training />} />
                        <Route path="Reviews" element={<Reviews />} />
                        <Route path="EmployeeInfo" element={<EmployeeInfo editable={false} />} />
                    </Route>

                    <Route path="/projects" element={<Projects />}>
                        <Route index element={<ProjectList />} />
                        <Route path="Projects" element={<ProjectList />} />
                        <Route path="ProjectProfile" element={<ProjectProfile />} />
                    </Route>

                    <Route path="/invoicing" element={<InvoiceList />}>
                        <Route index element={<InvoiceList />} />

                    </Route>

                    <Route path="raise-invoice" element={<RaiseInvoice />} />
                    <Route path="draft-invoice" element={<RaiseInvoice />} />
                    <Route path="/dashboard-invoicing" element={<Invoicing />}>
                        <Route index element={<Invoicing />} />
                    </Route>
                    <Route path="/timesheets" element={<Timesheets />}>
                        <Route index element={<Timesheets />} />
                    </Route>

                    <Route path="/opportunities">
                        <Route index element={<Opportunities />} />
                        <Route path="OppProfile" element={<ProjectProfile />} />
                    </Route>

                    <Route path="/clients" element={<Client />}>
                        <Route index element={<ClientList />} />
                        <Route path="Client" element={<ClientList />} />
                        <Route path="ClientProfile" element={<ClientProfile />} />
                    </Route>

                    <Route path="/suppliers" element={<Suppliers />}>
                        <Route index element={<SuppliersList />} />
                        <Route path="Suppliers" element={<SuppliersList />} />
                        <Route path="SupplierProfile" element={<SuppliersProfile />} />
                    </Route>

                    {/* <Route path="/suppliers" element={<Suppliers />}>
                        <Route index element={<Suppliers />} />
                    </Route> */}

                    <Route path="/Profile" element={<Profile />}>
                        <Route path="/Profile" element={<Navigate to='./Leave' />} />
                        <Route path="Leave" element={<Holiday />} />
                        <Route path="Sickness" element={<Sickness />} />
                        <Route path="Training" element={<Training />} />
                        <Route path="Reviews" element={<Reviews />} />
                        <Route path="EmployeeInfo" element={<EmployeeInfo editable={false} />} />
                    </Route>

                    <Route path="/Admin" element={<Approvals />}>
                        <Route path="/Admin" element={<Navigate to='./Leave' />} />
                        <Route path="Leave" element={<ApprovalHoliday />} />
                        <Route path="Sickness" element={<AdminSickness />} />
                        <Route path="Reviews" element={<AdminReviews />} />
                        <Route path="EmployeeInfo" element={<ApprovalEmployeeInfo />}>
                            <Route path="Profile" element={<EmployeeInfo editable={true} />} />
                        </Route>
                        <Route path="Settings" element={<Settings />}>
                            <Route path="EndOfYearAdjustment" element={<EndOfYearAdjustment />} />
                        </Route>
                    </Route>
                </Routes>
            </AdminContext.Provider>

            <div className="MomentLogo">MOMENT</div>
            {/* <div className="SandboxLogo">SANDBOX</div> */}
        </div>
    );
}

export default App;