import React, { useState, useEffect } from 'react';
import styles from './CompanyCalendar.module.css';
import '../../Pages/Profile/Holiday/Holiday.css';
import Dropdown from "../../Components/DropDownSelect/Dropdown";

const Months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

function CompanyCalendar({ resourceData, CalanderAPItrigger, UserEmail }) {
    const [monthType, setMonthType] = useState(resourceData.month);
    const [yearType, setYearType] = useState(resourceData.year);
    const [preventReload, setPreventReload] = useState(0);
    const days = [...Array(resourceData.days).keys()].map(i => i + 1);

    const getDayClass = (day) => {
        const foundDay = resourceData.specialDays.find(d => d.day === day);
        return foundDay ? foundDay.type : '';
    }

    const getDayClassText = (day) => {
        const foundDay = resourceData.specialDays.find(d => d.day === day);
        return foundDay ? `${foundDay.type}Text` : '';
    }

    const hexToRGBA = (hex, opacity) => {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (hex.length === 4) {
            r = "0x" + hex[1] + hex[1];
            g = "0x" + hex[2] + hex[2];
            b = "0x" + hex[3] + hex[3];

            // 6 digits
        } else if (hex.length === 7) {
            r = "0x" + hex[1] + hex[2];
            g = "0x" + hex[3] + hex[4];
            b = "0x" + hex[5] + hex[6];
        }

        return "rgba(" + +r + "," + +g + "," + +b + "," + opacity + ")";
    }

    const BarType = (resource, day) => {
        const bar = resource.bars.find(bar => day > bar.start && day < bar.end && bar.start !== bar.end);
        if (bar) {
            return <div style={{ background: bar.color }} className={styles.fullBar}></div>;
        }

        const oneDayBar = resource.bars.find(bar => day === bar.end && day === bar.start);
        if (oneDayBar) {
            return <div style={{ background: oneDayBar.color }} className={styles.oneBar}></div>;
        }

        const startBar = resource.bars.find(bar => day === bar.start && bar.start !== bar.end && parseFloat(bar.start) && Number.isInteger(bar.end));
        if (startBar) {
            return <div style={{ background: startBar.color }} className={styles.startBar}></div>;
        }

        let endBar = resource.bars.find(bar => day === bar.end && bar.end !== bar.start && Number.isInteger(bar.start) && Number.isInteger(bar.end));

        if (endBar) {
            return <div style={{ background: endBar.color }} className={styles.endBar}></div>;
        } else {
            endBar = resource.bars.find(bar => day === bar?.end && bar?.end !== bar?.start && Number.isInteger(bar?.end))
            if (endBar) {
                return <div style={{ background: endBar.color }} className={styles.oneBar}></div>;
            }
        }

        const AMbar = resource.bars.find(bar => day === bar.start && !Number.isInteger(bar.end));
        if (AMbar) {
            return <div style={{ background: AMbar.color }} className={styles.AMbar}></div>;
        }
    }

    const PMcheck = (resource, day) => {
        const PMbar = resource.bars.find(bar => (day + 1) === bar.end && !Number.isInteger(bar.start));
        if (PMbar) {
            return <div style={{ background: PMbar.color }} className={styles.PMbar}></div>;
        }
    }

    useEffect(() => {
        setPreventReload(1)
        setMonthType(resourceData.month)
        setYearType(resourceData.year)
        setPreventReload(0)
    }, [resourceData]);

    useEffect(() => {
        if (preventReload === 0) {
            const changeData = {
                yearType: yearType,
                monthType: monthType,
                userEmail: UserEmail
            }
            CalanderAPItrigger(changeData)
        }
    }, [monthType, yearType]);

    const handleChangeByButton = (clickType) => {
        const changeData = {
            yearType: yearType, 
            monthType: monthType, 
            userEmail: UserEmail, 
            buttonClick: clickType 
        }
        CalanderAPItrigger(changeData)
    }

    return (
        <div className={styles.companyHolidayCalendar}>

            <div className={styles.CalendarControlls}>
                <div className={styles.Button} onClick={() => handleChangeByButton('back')} >{'<'}</div>
                <div className={styles.dropdown} style={{ marginRight: '20px' }}>
                    <Dropdown options={Months} selectedValue={monthType} onValueChange={setMonthType} />
                </div>
                <div className={styles.dropdown}>
                    <Dropdown options={resourceData.Years} selectedValue={yearType} onValueChange={setYearType} />
                </div>
                <div className={styles.Button} onClick={() => handleChangeByButton('forward')} >{'>'}</div>
            </div>

            <table className={styles.CalendarTable}>
                <thead>
                    <tr>
                        <th style={{ border: 'none' }}></th>
                        {days.map(day => (
                            <th key={day} className={styles[getDayClassText(day)]}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {resourceData.resources.map(resource => (
                        <tr key={resource.name}>
                            <td>{resource.name}</td>
                            {days.map(day => (
                                <td key={day} className={`${styles[getDayClass(day)]}`}>
                                    {BarType(resource, day)}
                                    {PMcheck(resource, day)}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className={styles.keys}>
                {resourceData.TableKeys.map(key => (
                    <div className={styles.keyBox}>
                        <div className={styles.key}>
                            <div style={{ background: key.color }} className={styles[key.type]}></div>
                            <div className={styles.keyName}>{key.name}</div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
}

export default CompanyCalendar;