import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import Dropdown from '../DropDownBox/Dropdown';
import CommentBox from '../CommentBox/CommentBox';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import styles from './LostOppPopUp.module.css';

import { deadenJob } from "../../redux/slices/opportunitiesdata";
import { useDispatch } from "../../redux/store";

const LostOppPopUp = ({ isVisible, onClose, onSubmit, reasons, jobNumber }) => {
    const { accounts } = useMsal();
    const account = accounts[0];
    const dispatch = useDispatch();
    const [selectedReason, setSelectedReason] = useState([]);

    // Call the `onSubmit` prop when you need to submit the modal's data
    const handleSubmit = () => {
        // Call `onSubmit` prop function and pass it the data
        dispatch(deadenJob(jobNumber, selectedReason, account.username));// Dispatch the action with data

        onSubmit();

        // Clear the form fields
        setSelectedReason([]);

        // Close the modal
        onClose();
    };

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <p className={styles.text}>Please select the reason for lost opportunity</p>
                <Dropdown
                    options={reasons}
                    initialSelectedValue={selectedReason}
                    onValueChange={setSelectedReason}
                    placeholder="Select Lost Opp Reason"
                />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                    <div style={{ width: '74px', height: '25px' }}>
                        <button className={buttonStyles.YesButtonComponentStyling} onClick={handleSubmit}>SUBMIT</button>
                    </div>
                    <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                        <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LostOppPopUp;
