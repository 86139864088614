import React from 'react';
import styles from './EmpInfo.module.css';

function ResizableTh(props) {
  const { onResize, children } = props;
  const ref = React.useRef(null);

  const startResize = (event) => {
    document.documentElement.addEventListener('mousemove', doResize);
    document.documentElement.addEventListener('mouseup', stopResize);
  };

  const doResize = (event) => {
    const rect = ref.current.getBoundingClientRect();
    const newWidth = event.clientX - rect.left;
    if (newWidth > 0) {
      onResize(newWidth);
    }
  };

  const stopResize = () => {
    document.documentElement.removeEventListener('mousemove', doResize);
    document.documentElement.removeEventListener('mouseup', stopResize);
  };

  return (
    <th className={styles.th} ref={ref} style={{ padding: '8px' }}>
      <div className={styles.thContent} style={{ maxWidth: '100%' }}>
        {children}
      </div>
      <div className={styles.resizer} onMouseDown={startResize}>
        <div className={styles.resizerBoxcolor}></div>
      </div>
    </th>
  );
}

export default ResizableTh;
