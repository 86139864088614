import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    IconButton,
    Avatar,
    Popover,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import styles from "./TaskCard.module.css";
import buttonStyle from "../../GlobalStyles/button.module.css";

import FolderIcon from "@mui/icons-material/Folder"; // Assuming you have @mui/icons-material installed
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import folderIcon from "../../assets/folder.svg";
import calendarIcon from "../../assets/Calendar.svg";
import {
    completeTask,
    changeDeadline,
    changeAssignee,
} from "../../redux/slices/dashboard";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"; // For the unchecked state
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // For the checked state
import DatePicker from "../DatePicker/DatePicker";
import FeePropFeePopUp from "../FeePropFeePopUp/FeePropFeePopUp";

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    if (name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
        };
    }
}

const TaskCard = ({ task, setupUploadModal }) => {
    const { accounts } = useMsal();
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_URL;

    const account = accounts[0];

    const isUploadType = task.deadlineType === "upload";
    const [isCompleted, setIsCompleted] = useState(task.completed);
    const [assigneesList, setAssigneesList] = useState([]);
    const [taskData, setTaskData] = useState({});
    const { assignees } = useSelector((state) => state.dashboard);
    const [selectedAssignee, setSelectedAssignee] = useState({});

    const [feeModal, setFeeModal] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchAndSetAssigneesWithImages = async (filteredAssignees) => {
        const updatedAssigneesWithImages = await Promise.all(filteredAssignees.map(async (assignee) => {
            try {
                const imageUrl = await fetchAssigneeImage(assignee.profilePicUrl);
                return { ...assignee, profilePicUrl: imageUrl };
            } catch (error) {
                console.error(`Error fetching profile image for ${assignee.name}:`, error);
                return assignee; // Return original assignee if image fetch fails
            }
        }));
        setAssigneesList(updatedAssigneesWithImages);
    };

    const fetchAssigneeImage = async (url) => {
        const response = await axios.get(`${API_URL}${url}`, { responseType: 'blob' });
        return URL.createObjectURL(response.data);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        if (assignees && task) {
            const handlerTypeProperty = getHandlerTypeProperty(task.deadlineType);
            const filteredAssignees = assignees.filter(assignee => assignee[handlerTypeProperty]);
            fetchAndSetAssigneesWithImages(filteredAssignees);
            const currentAssignee = filteredAssignees.find(assignee => assignee.id === task.assigneeID);
            setSelectedAssignee(currentAssignee || {});
        }
    }, [assignees, task]);

    useEffect(() => {
        // Once assigneesList is updated with images, also update selectedAssignee if it's one of the assignees
        const updatedSelected = assigneesList.find(assignee => assignee.id === selectedAssignee.id);
        if (updatedSelected) {
            setSelectedAssignee(updatedSelected);
        }
    }, [assigneesList, selectedAssignee.id]);


    const getHandlerTypeProperty = (deadlineType) => {
        switch (deadlineType) {
            case "fee_proposal": return 'feeProposalHandler';
            case "isa_todo": return 'isaToDoHandler';
            case "isa_to_sent": return 'isaToSendHandler';
            case "fbps": return 'fbpHandler';
            default: return null;
        }
    };

    // Function to handle date change from DatePicker
    const handleDatesChange = (selection) => {
        const { startDate } = selection; // Assuming you need only the start date

        // Ensure startDate is a Date object
        const dateObject = new Date(startDate);

        // Manually format the date as d-m-Y
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1; // getMonth() is zero-indexed, so add 1
        const year = dateObject.getFullYear();
        const formattedDate = `${day}-${month}-${year}`; // Concatenate the parts with dashes

        // Dispatch the changeDeadline action with all required parameters
        dispatch(
            changeDeadline(
                task.projectNumber,
                task.deadlineID,
                task.deadlineType,
                formattedDate,
                account.username
            )
        );
    };

    const toggleCompletion = (deadlineID, jobNumber, deadlineType) => {
        // Only proceed if the task is currently not completed
        if (!isCompleted) {
            const newCompletionStatus = !isCompleted;
            setIsCompleted(newCompletionStatus);

            setTaskData({ jobNumber, deadlineID, deadlineType, updatedBy: account.username });

            if (newCompletionStatus && deadlineType === "fee_proposal") {
                setFeeModal(true); // Open the Fee Proposal Modal
            } else {
                // Assuming dispatch and completeTask are correctly defined and accessible
                dispatch(
                    completeTask(jobNumber, deadlineID, deadlineType, account.username)
                );
            }
        }
    };

    // Helper function to calculate the difference in days between two dates
    const getDaysDifference = (date1, date2) => {
        const timeDiff = date2 - date1;
        return timeDiff / (1000 * 3600 * 24);
    };

    // Function to format the deadlineDate to "dd/mm" format
    const formatDeadlineDate = (deadlineDate) => {
        if (!deadlineDate) return "";
        const parts = deadlineDate.split("/");
        // Assuming the format is "dd/mm/yyyy"
        if (parts.length === 3) {
            const [day, month] = parts;
            return `${day}/${month}`; // Return "dd/mm" format
        }
        return deadlineDate; // Return original string if format is unexpected
    };

    // Function to determine the class based on the deadline date
    const getDeadlineClass = (deadlineStr) => {
        // Assuming deadlineStr is in "dd/mm" format and the year is the current year
        const [day, month] = deadlineStr.split("/").map(Number);
        const year = new Date().getFullYear();
        const deadline = new Date(year, month - 1, day); // Month is 0-indexed in JS Date
        const today = new Date();

        // Set the hours, minutes, seconds, and milliseconds to 0 for both dates
        today.setHours(0, 0, 0, 0);
        deadline.setHours(0, 0, 0, 0);

        const daysDifference = getDaysDifference(today, deadline);

        if (daysDifference < -2) return "dateOverdue";
        if (daysDifference <= 7) return "dateDueIn7";
        return "dateDueAfter7";
    };

    const deadlineClass = getDeadlineClass(task.deadlineDate);

    const updateAssignee = (assignee) => {
        dispatch(
            changeAssignee(
                task.projectNumber,
                task.deadlineID,
                task.deadlineType,
                task.assigneeID,
                assignee.id,
                assignee.name,
                account.username
            )
        );
    };
    return (
        <Card className={styles.Card}>
            <CardContent className={styles.CardContent}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {!isUploadType && (
                        <IconButton
                            onClick={() =>
                                toggleCompletion(
                                    task.deadlineID,
                                    task.projectNumber,
                                    task.deadlineType
                                )
                            }
                            aria-label="complete task"
                            size="small"
                            style={{ alignSelf: "start", margin: "-6px 0px 0 -6px" }}
                        >
                            {isCompleted ? (
                                <CheckCircleIcon color="primary" fontSize="small" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="small" />
                            )}
                        </IconButton>
                    )}
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className={styles.cardTitle}
                        style={{ flex: 1, wordWrap: "break-word" }}
                    >
                        <a
                            href={`/Projects/ProjectProfile#${task.projectNumber}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            {task.taskTitle || `${task.projectNumber} - ${task.address}`}
                        </a>
                    </Typography>
                </div>
                {!isUploadType && (
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className={`${styles.cardSubtext} ${styles.client}`}
                    >
                        {task.clientName || "Default Company Name"}
                    </Typography>
                )}
                <div className={styles.cardButtons}>
                    {isUploadType ? (
                        // Render the upload type layout
                        task?.files.map((file) => (
                            <React.Fragment key={file.uploadID}>
                                <Button
                                    className={`${buttonStyle.Button} ${file.completed ? buttonStyle.Disabled : ""
                                        }`}
                                    onClick={() =>
                                        !file.completed && setupUploadModal(task, file)
                                    }
                                >
                                    {file.uploadType}
                                </Button>
                            </React.Fragment>
                        ))
                    ) : (
                        // Render the alternative layout
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            {/* Calendar icon to the far left */}
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <DatePicker
                                    initialDate={task.deadlineDate}
                                    onDatesChange={handleDatesChange}
                                    onlyIcon={true}
                                    inputHeight="24px"
                                    borderThickness="1px"
                                    borderColor="#3C96D4"
                                    oppCard={true}
                                />
                            </div>

                            {/* This Typography will have dynamic class based on the deadline */}
                            <Typography
                                variant="body2"
                                className={`${styles.cardSubtext} ${styles.deadline} ${styles[deadlineClass]}`}
                            >
                                {formatDeadlineDate(task.deadlineDate)}{" "}
                                {/* Use the formatted date */}
                            </Typography>

                            {/* All other elements stacked to the far right */}
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    flexGrow: 1,
                                }}
                            >
                                {/* Folder icon next to the text */}
                                <div style={{ marginRight: "6px" }}>
                                    <img
                                        className={styles.FolderIcon}
                                        style={{ cursor: 'pointer' }}
                                        src={folderIcon}
                                        alt="Folder"
                                        onClick={() => { window.open(task.sharepointFolderLink.trim()+'%2FBID', '_blank') }}
                                    />
                                </div>
                                {
                                    selectedAssignee?.profilePicUrl ? (
                                        <Avatar
                                            aria-describedby={id}
                                            onClick={handleClick}
                                            src={selectedAssignee.profilePicUrl} // Use the image URL if available
                                            sx={{
                                                width: "18px",
                                                height: "18px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    ) : (
                                        <Avatar
                                            aria-describedby={id}
                                            onClick={handleClick}
                                            {...stringAvatar(selectedAssignee?.name)} // Fallback to initials if no image URL
                                            sx={{
                                                width: "18px",
                                                height: "18px",
                                                textSize: "10px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    )
                                }

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        ".MuiPaper-root": {
                                            borderRadius: "8px",
                                            border: "1px solid #3C96D4",
                                            boxShadow: "none",
                                            overflow: "hidden",
                                        },
                                    }}
                                >
                                    <List
                                        className={styles.AssigneeDropdown}
                                        sx={{
                                            width: "100%",
                                            maxWidth: 360,
                                            bgcolor: "background.paper",
                                        }}
                                    >
                                        {assigneesList.map((assignee) => (
                                            <ListItem
                                                key={assignee.id}

                                                sx={{ padding: "0px 8px 3px", cursor: "Pointer" }}
                                                onClick={() => updateAssignee(assignee)}
                                            >
                                                <ListItemAvatar sx={{ minWidth: "20px" }}>
                                                    {assignee.profilePicUrl ? (
                                                        <Avatar src={assignee.profilePicUrl}
                                                            sx={{
                                                                width: "17px",
                                                                height: "17px",
                                                                marginRight: "8px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <Avatar
                                                            {...stringAvatar(assignee.name)}
                                                            sx={{
                                                                width: "15px",
                                                                height: "15px",
                                                                marginRight: "8px",
                                                            }}
                                                        />
                                                    )}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={assignee.name}
                                                    primaryTypographyProps={{
                                                        variant: "body2",
                                                        style: {
                                                            color: "#292929",
                                                            textAlign: "left",
                                                            fontFamily: '"PT Sans", sans-serif',
                                                            fontSize: "13px",
                                                            fontStyle: "normal",
                                                            fontWeight: 700,
                                                            lineHeight: "normal",
                                                        },
                                                    }}
                                                    sx={{
                                                        margin: 0,
                                                        "&:hover": {
                                                            borderRadius: "6px",
                                                            background: "#EAF2F9",
                                                        },
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Popover>
                            </div>
                        </div>
                    )}
                </div>
            </CardContent>

            <FeePropFeePopUp taskData={taskData} isVisible={feeModal} onClose={() => setFeeModal(false)} />

        </Card>
    );
};

export default TaskCard;
