import React, { useState, useEffect, useCallback } from 'react';
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import Dropdown from "../../Components/DropDownBox/DropdownV2";
// import CommentBox from '../CommentBox/CommentBox';
// import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import { CircularProgress } from "@mui/material";
import styles from "../../Components/FeePropFeePopUp/FeePropFeePopUp.module.css";
import SearchBar from "../../Components/SearchBar/SearchBar";
import buttonStyles from "../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import stylesSearch from "../../Components/SearchBar/SearchBar.module.css";
import { handleSubmitFee, completeTask } from "../../redux/slices/dashboard";
import { useDispatch, useSelector } from "../../redux/store";
import { VariantType, useSnackbar } from "notistack";
const API_URL = process.env.REACT_APP_API_URL;

const AddBillPayerPopUp = ({ isVisible, onClose, jobNumber, onRefresh }) => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [fee, setFee] = useState(0);
  const [comment, setComment] = useState("");
  const [suitableRunner, setSuitableRunner] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [billPayerOptions, setBillPayerOptions] = useState([]);
  const [optionsAvailable, setOptionsAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const debounceSearch = useCallback(() => {
      if (!inputValue.trim()) return;
      const fetchBillPayers = async () => {
          setLoading(true);
          try {
              const response = await axios.get(
                  `${API_URL}/api/invoice/project/bill/payer/all?billPayer=${inputValue}`
              );
              if (response.data.data.length > 0) {
                  setBillPayerOptions(
                      response.data.data.map((billPayer) => ({
                          id: billPayer.billPayerID,
                          value: billPayer.billPayerName,
                          label: billPayer.billPayerName,
                      }))
                  );
                  setOptionsAvailable(true);
              }
          } catch (error) {
              console.error("Error fetching bill payers:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchBillPayers();
  }, [inputValue, API_URL]);

  useEffect(() => {
      const handler = setTimeout(() => {
          debounceSearch();
      }, 500); // Debounce time in milliseconds

      return () => {
          clearTimeout(handler);
      };
  }, [debounceSearch]);

  if (!isVisible) return null;

  const handleSearchChange = (event) => {
      setInputValue(event.target.value);
  };

  const handleSearchSelection = (value) => {
      setInputValue(value.label);
      setSelectedData(value);
      console.log(value, "selected value");
      setOptionsAvailable(false);
  };

  const handleSearchSubmit = () => {
      setBillPayerOptions([]);
      if (!inputValue) {
          return;
      }
      debounceSearch();
  };

  const handleSubmit = async () => {
      setLoading(true);
      const body = {
          billPayerID: selectedData.id,
          jobNumber,
          updatedBy: account.username,
      };
      try {
          const response = await axios.post(
              `${API_URL}/api/invoice/project/add/bill/payer`,
              body
          );
          if (response.data.success) {
            enqueueSnackbar("Request completed successfully!", {
              variant: "success",
            });
              onRefresh();
              onClose();
          }
      } catch (error) {
          console.error(error);
          throw new Error(`HTTP error! status: ${error.response.status}`);
      } finally {
          setLoading(false);
      }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <p className={styles.text}>
          Please search the Bill Payer you wish to add
        </p>
        <div className={stylesSearch.commentModal_feeSection}>
          <div>
            <div
              style={{ width: "300px" }}
              className={stylesSearch.searchContainer}
            >
              <input
                type="text"
                className={`${stylesSearch.ProjectSearch}`}
                placeholder={"Search..."}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => {
                  if (!e.target.value) e.target.placeholder = "Search...";
                }}
                onChange={handleSearchChange}
                // disabled={props.disabled}
                value={inputValue}
              />
              <button
                onClick={handleSearchSubmit}
                // disabled={props.disabled}
                className={`${stylesSearch.searchButton}`}
              >
                <span className={stylesSearch.searchIconPlaceholder}></span>
              </button>
            </div>
            {optionsAvailable && (
              <Dropdown
                options={billPayerOptions}
                showOnlyOptions={true}
                options_width={"300px"}
                onValueChange={(newValue) => handleSearchSelection(newValue)}
                addNew={false}
                addNewEnabled={false}
                // Pass the new function here
              />
            )}
            {loading && (
              <div className="ProfileHolidayOverlay">
                <div
                  className="ProfileHolidayResponseBox"
                  style={{ borderRadius: "150px", minWidth: "160px" }}
                >
                  <CircularProgress size={65} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <div style={{ width: "74px", height: "25px" }}>
            <button
              className={buttonStyles.YesButtonComponentStyling}
              onClick={handleSubmit}
            >
              Add
            </button>
          </div>
          <div style={{ width: "74px", height: "25px", marginLeft: "10px" }}>
            <button
              className={buttonStyles.NoButtonComponentStyling}
              onClick={onClose}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBillPayerPopUp;

const statusOptions = [
  {
    value: "PROMOTED",
    label: "Promoted",
  },
  {
    value: "QUOTED",
    label: "Quoted",
  },
];



// function MyComponent() {
//   const [inputValue, setInputValue] = useState('');

//   useEffect(() => {
//     const debouncedHandleInput = debounce(handleInput, 300);

//     function handleInput() {
//       // Your code to handle input goes here
//       console.log('Input value:', inputValue);
//     }

//     const inputElement = document.getElementById('myInput');
//     inputElement.addEventListener('input', debouncedHandleInput);

//     return () => {
//       inputElement.removeEventListener('input', debouncedHandleInput);
//     };
//   }, [inputValue]); // Only re-run the effect if inputValue changes

//   function debounce(func, delay) {
//     let timeoutId;

//     return function() {
//       const context = this;
//       const args = arguments;

//       clearTimeout(timeoutId);

//       timeoutId = setTimeout(() => {
//         func.apply(context, args);
//       }, delay);
//     };
//   }

//   function handleChange(event) {
//     setInputValue(event.target.value);
//   }

//   return (
//     <input 
//       id="myInput"
//       type="text" 
//       value={inputValue}
//       onChange={handleChange}
//     />
//   );
// }
