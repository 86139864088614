// Profile.js
import React from 'react';
import styles from './Profile.module.css';
import { Outlet } from 'react-router-dom';
import Topbar from '../../Components/Topbar/Topbar';;

export const TopbarData = [
  {
    title: 'Leave',
    link: '/Profile/Leave',
    disabled: false,
  },
  {
    title: 'Sickness',
    link: '/Profile/Sickness',
    disabled: false,
  },
  {
    title: 'Skills Matrix',
    link: '/Profile/SkillsMatrix',
    disabled: true,
  },
  {
    title: 'Training',
    link: '/Profile/Training',
    disabled: false,
  },
  {
    title: 'Reviews',
    link: '/Profile/Reviews',
    disabled: false,
  },
  {
    title: 'Employee Info',
    link: '/Profile/EmployeeInfo',
    disabled: false,
  },
];

function Profile() {
  return (
    <div className={styles.profileContainer}>
      <Topbar TopbarData={TopbarData}  />
      <Outlet />
    </div>
  );
}

export default Profile;