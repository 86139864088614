import React, { useState, useEffect } from 'react';
import styles from './RevieweeForm.module.css';
import BlueLogo from '../../assets/BlueLogo.png';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import TickBox from '../TickBox/TickBox';
import CommentBox from '../CommentBox/CommentBoxV2';

function ColleagueForm({data, handleFormSubmit, secretFormSubmit, setDisplayProfileForm, actionType}) {
  const [selectedValues, setSelectedValues] = useState({});
  const [ticks, setTicks] = useState({ PatternYes: false, PatternNo: false, WorkYes: false, WorkNo: false, Confirmation: false });
  const [errorColors, setErrorColors] = useState({});
  const [satisfied, setSatisfied] = useState(true);
  const [docInfo, setDocInfo] = useState(null);

  const toggleTick = (tickName) => {
    setTicks({ ...ticks, [tickName]: !ticks[tickName] });
  };

  useEffect(() => {
  }, [data]);

  const validateForm = () => {
    let isValid = true;
    const newErrorColors = {};

    if (Comment1.trim() === '') {
      setComment1Colour('#D32030');
      isValid = false;
    } else {setComment1Colour('#3C96D4');}

    if (Comment2.trim() === '') {
      setComment2Colour('#D32030');
      isValid = false;
    } else {setComment2Colour('#3C96D4');}

    if (Comment3.trim() === '') {
      setComment3Colour('#D32030');
      isValid = false;
    } else {setComment3Colour('#3C96D4');}

    if (Comment4.trim() === '') {
      setComment4Colour('#D32030');
      isValid = false;
    } else {setComment4Colour('#3C96D4');}

    if (Comment5.trim() === '') {
      setComment5Colour('#D32030');
      isValid = false;
    } else {setComment5Colour('#3C96D4');}

    if (Comment6.trim() === '') {
      setComment6Colour('#D32030');
      isValid = false;
    } else {setComment6Colour('#3C96D4');}

    if (Comment7.trim() === '') {
      setComment7Colour('#D32030');
      isValid = false;
    } else {setComment7Colour('#3C96D4');}

    if (Comment8.trim() === '') {
      setComment8Colour('#D32030');
      isValid = false;
    } else {setComment8Colour('#3C96D4');}

    if (Comment9.trim() === '') {
      setComment9Colour('#D32030');
      isValid = false;
    } else {setComment9Colour('#3C96D4');}

    if (Comment10.trim() === '') {
      setComment10Colour('#D32030');
      isValid = false;
    } else {setComment10Colour('#3C96D4');}

    if (Comment11.trim() === '') {
      setComment11Colour('#D32030');
      isValid = false;
    } else {setComment11Colour('#3C96D4');}

    if (Comment12.trim() === '') {
      setComment12Colour('#D32030');
      isValid = false;
    } else {setComment12Colour('#3C96D4');}

    if (Comment13.trim() === '') {
      setComment13Colour('#D32030');
      isValid = false;
    } else {setComment13Colour('#3C96D4');}

    if (Comment14.trim() === '') {
      setComment14Colour('#D32030');
      isValid = false;
    } else {setComment14Colour('#3C96D4');}

    if (confirmationTick == false) {
      setColoureis('#D32030')
      isValid = false;
    } else {setColoureis('#3C96D4');}
    
    // Add more validation logic as needed
    setErrorColors(newErrorColors);
    setSatisfied(isValid);
    return isValid;
  };

  const [Comment1, setComment1] = useState(data.Q1);
  const [Comment1Colour, setComment1Colour] = useState('');
  
  const [Comment2, setComment2] = useState(data.Q2);
  const [Comment2Colour, setComment2Colour] = useState('');
  
  const [Comment3, setComment3] = useState(data.Q3);
  const [Comment3Colour, setComment3Colour] = useState('');
  
  const [Comment4, setComment4] = useState(data.Q4);
  const [Comment4Colour, setComment4Colour] = useState('');
  
  const [Comment5, setComment5] = useState(data.Q5);
  const [Comment5Colour, setComment5Colour] = useState('');
  
  const [Comment6, setComment6] = useState(data.Q6);
  const [Comment6Colour, setComment6Colour] = useState('');
  
  const [Comment7, setComment7] = useState(data.Q7);
  const [Comment7Colour, setComment7Colour] = useState('');
  
  const [Comment8, setComment8] = useState(data.Q8);
  const [Comment8Colour, setComment8Colour] = useState('');
  
  const [Comment9, setComment9] = useState(data.Q9);
  const [Comment9Colour, setComment9Colour] = useState('');
  
  const [Comment10, setComment10] = useState(data.Q10);
  const [Comment10Colour, setComment10Colour] = useState('');
  
  const [Comment11, setComment11] = useState(data.Q11);
  const [Comment11Colour, setComment11Colour] = useState('');
  
  const [Comment12, setComment12] = useState(data.Q12);
  const [Comment12Colour, setComment12Colour] = useState('');
  
  const [Comment13, setComment13] = useState(data.Q13);
  const [Comment13Colour, setComment13Colour] = useState('');
  
  const [Comment14, setComment14] = useState(data.Q14);
  const [Comment14Colour, setComment14Colour] = useState('');

  const handleSubmissionClick = () => {
    if (validateForm()) {
      console.log('ALL GUCCHIII')
      let information = {
        'Comment1': Comment1,
        'Comment2': Comment2,
        'Comment3': Comment3,
        'Comment4': Comment4,
        'Comment5': Comment5,
        'Comment6': Comment6,
        'Comment7': Comment7,
        'Comment8': Comment8,
        'Comment9': Comment9,
        'Comment10': Comment10,
        'Comment11': Comment11,
        'Comment12': Comment12,
        'Comment13': Comment13,
        'Comment14': Comment14,
      }
      handleFormSubmit('Colleague Feedback For', selectedValues, 'SUBMIT', information)
    }
  };

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      handleSecretDraftClick();
    }, 6000); // 60,000 milliseconds = 60 seconds

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [
    Comment1,
    Comment2,
    Comment3,
    Comment4,
    Comment5,
    Comment6,
    Comment7,
    Comment8,
    Comment9,
    Comment10,
    Comment11,
    Comment12,
    Comment13,
    Comment14
    // add any other dependencies here
  ]);

  const handleSecretDraftClick = () => {
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
      'Comment4': Comment4,
      'Comment5': Comment5,
      'Comment6': Comment6,
      'Comment7': Comment7,
      'Comment8': Comment8,
      'Comment9': Comment9,
      'Comment10': Comment10,
      'Comment11': Comment11,
      'Comment12': Comment12,
      'Comment13': Comment13,
      'Comment14': Comment14,
    }
    secretFormSubmit('Colleague Feedback For', selectedValues, 'DRAFT', information)
  };

  const handleDraftClick = () => {
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
      'Comment4': Comment4,
      'Comment5': Comment5,
      'Comment6': Comment6,
      'Comment7': Comment7,
      'Comment8': Comment8,
      'Comment9': Comment9,
      'Comment10': Comment10,
      'Comment11': Comment11,
      'Comment12': Comment12,
      'Comment13': Comment13,
      'Comment14': Comment14,
    }
    handleFormSubmit('Colleague Feedback For', selectedValues, 'DRAFT', information)
    setDisplayProfileForm(false);
  };

  const handleClose = () => {
    setDisplayProfileForm(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };
  

  function QuestionAndAnswer({ number, question, initData, ValChange, linColour }) {
    const { GrayNum, QandA, GrayQuestion, BlackAnswer } = styles;
    const handleValueChange = (value) => {
      // Any additional logic can go here.
      ValChange(value);
    };

    return (
      <div className={styles.NumAndQandA} key={number}>
        <div className={GrayNum}>
          {number}
        </div>
        <div className={QandA}>
          <div className={GrayQuestion}>
            {question}
          </div>
          <CommentBox initialData={initData} onValueChange={handleValueChange} liningColour={linColour} />
        </div>
      </div>
    );
  }

  const [coloureis, setColoureis] = useState('');
  const [confirmationTick, setConfirmationTick] = useState(false);
  const handleTickClick_confirmation = () => {
    if (confirmationTick == false) {
      setConfirmationTick(true)
      setColoureis('')
    } else if (confirmationTick) {
      setConfirmationTick(false)
      setColoureis('')
    }
    console.log('confirmation: ', confirmationTick)
  };

  const [PatternYesTick, setPatternYesTick] = useState(false);
  const [PatternNoTick, setPatternNoTick] = useState(false);

  const [PatternColoureis, setPatternColoureis] = useState('');
  const handleTickClick_PatternYes = () => {
    if (PatternYesTick == false) {
      setPatternYesTick(true)
      setPatternNoTick(false)
      setPatternColoureis('')
    } else if (PatternYesTick) {
      setPatternYesTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };
  const handleTickClick_PatternNo = () => {
    if (PatternNoTick == false) {
      setPatternNoTick(true)
      setPatternYesTick(false)
      setPatternColoureis('')
    } else if (PatternNoTick) {
      setPatternNoTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };

  return (
    <div className={styles.backGround} onClick={handleDraftClick}>
      <div className={styles.form} onClick={handleFormClick}>
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Colleague Review Feedback</div>

        <div className={styles.BlackText} style={{marginBottom: '14px'}}>The below form is to be completed by the team members ahead of each employee’s review.</div>
        <div className={styles.BlackText}>Please answer these questions in line with the company values of <span className={styles.BoldBlueText}>Authenticity</span> and <span className={styles.BoldBlueText}>Transparency</span>, and complete all boxes to ensure well rounded feedback is given.</div>
        

        <div className={styles.QuestionSection}>Job Knowledge and Ability</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment1} onValueChange={setComment1} liningColour={Comment1Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment2} onValueChange={setComment2} liningColour={Comment2Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>Quality of Work</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment3} onValueChange={setComment3} liningColour={Comment3Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment4} onValueChange={setComment4} liningColour={Comment4Colour} />
          </div>
        </div>
        <div className={styles.QuestionSection}>Timekeeping & Deadlines</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment5} onValueChange={setComment5} liningColour={Comment5Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment6} onValueChange={setComment6} liningColour={Comment6Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>Attitude to Work</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment7} onValueChange={setComment7} liningColour={Comment7Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment8} onValueChange={setComment8} liningColour={Comment8Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>Interaction with Others</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment9} onValueChange={setComment9} liningColour={Comment9Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment10} onValueChange={setComment10} liningColour={Comment10Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>Culture & Values</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment11} onValueChange={setComment11} liningColour={Comment11Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment12} onValueChange={setComment12} liningColour={Comment12Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>Extra Responsibilities Within Blue/Team</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.QandA} style={{marginRight: '25px'}}>
            <div className={styles.GrayQuestion}>
              Strengths
            </div>
            <CommentBox initialData={Comment13} onValueChange={setComment13} liningColour={Comment13Colour} />
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Improvements
            </div>
            <CommentBox initialData={Comment14} onValueChange={setComment14} liningColour={Comment14Colour} />
          </div>
        </div>

        <div className={styles.confirmationSection}>
          <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', whiteSpace: 'nowrap', width: '200px'}}>I confirm the above information is correct</div>
          <div className={styles.YesNoTicks}>
            <TickBox type={confirmationTick} tickClick={handleTickClick_confirmation} coloureis={coloureis}/>
          </div>
        </div>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.DraftButton} onClick={handleDraftClick}>SAVE DRAFT</div>
          <div className={styles.updateButton} onClick={handleSubmissionClick}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <div className={styles.BlueLogoSection}><img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/></div>
      </div>
    </div>
  );
}

export default ColleagueForm;
