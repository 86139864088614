import React, { useState, useEffect, useCallback, useContext } from "react";
import axios from "axios";
// import Modal from 'react-modal';
import { Grid, Stack } from "@mui/material";
import {
  titleCase,
  formatStatus,
} from "../../../Components/FormattingFunctions";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./ProjectProfile.css";
import BackBtn from "../../../assets/BackBtn.svg";
import Dropdown from "../../../Components/DropDownSelect/Dropdown";
import EditDiv from "../../../Components/EditDiv/EditDiv";
import AdminContext from "../../../Context/adminContext";
import { useMsal } from "@azure/msal-react";
import EditIcon from "../../../assets/Edit.svg";
import MapPin from "../../../assets/MapPin.svg";
import FolderIcon from "../../../assets/folder.svg";
import Skeleton from "@mui/material/Skeleton";
import { Box, CircularProgress } from "@mui/material";
import "../../../Components/YesNoButtonsCSS/YesNoButtons.css";
import ProgressBar from "../../../Components/ProgressBar/ProgressBar";
import Table from "../../../Components/Table/TableV2";
import SliderSwitch from "../../../Components/SliderSwitch/SliderSwitch";
import HorizontalButtons from "../../../Components/HorizontalButtons/HorizontalButtons";
import AppointedPopUp from "../../../Components/AppointedPopUp/AppointedPopUp";
import LostOppPopUp from "../../../Components/LostOppPopUp/LostOppPopUp";
import FollowUpPopUp from "../../../Components/FollowUpPopUp/FollowUpPopUp";

import AddBillPayerPopUp from "../../../Sections/projects/AddBillPayerPopUp";
import PhasePopUp from "../../../Sections/projects/PhasePopup";
import PhaseTable from "../../../Sections/projects/PhaseTable";
import PlannedInvoicedTable from "../../../Sections/projects/PlannedInvoicedTable";
import PlannedInvoicedPopUp from "../../../Sections/projects/PlannedInvoicedPopUp";
import BillPayerTable from "../../../Sections/projects/BillPayerTable";

import { useDispatch } from '../../../redux/store';

import {
  deleteBillingPlan
} from "../../../redux/slices/project";

function ProjectProfile() {
  const { accounts } = useMsal();
  const account = accounts[0];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobno = parseInt(window.location.hash.substring(1));
  const API_URL = process.env.REACT_APP_API_URL;
  const [jobData, setJobData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState("");

  const [status_type_options, setStatusTypes] = useState([]);
  const [statusActivityMap, setActivityMap] = useState([]);
  const [logBook, AssignLogBook] = useState([]);
  const [isLogBookLoading, setIsLogBookLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [promoteModal, setPromoteModal] = useState(false);
  const [lostOppModal, setLostOppModal] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const commentInput = document.querySelector(".CommentInput");
  const pulsatingTime = 500; // 5 seconds in milliseconds

  const [currentData, setCurrentData] = useState(null);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [text, setText] = useState('');
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);
  const [addressEditInfo, setAddressEdit] = useState([]);
  const [runnerTeamEditInfo, setRunnerTeamEdit] = useState([]);
  const [statsEditInfo, setStatsEdit] = useState([]);
  const [FollowUpEditInfo, setFollowUpEdit] = useState([]);
  const [ReqInvInfo, setReqInvEdit] = useState([]);
  const [handlers, setHandlers] = useState([]);
  const [lostReasons, setLostReasons] = useState([]);
  const [editType, setEditType] = useState("UPDATE");
  const [selectedValue, setSelectedValue] = useState("SUMMARY - PHASE");
  const [timesheetLoading, setTimesheetLoading] = useState(false);

  //Phase States
  const [phaseListLoading, setPhaseListLoading] = useState(false);
  const [phaseListData, setPhaseListData] = useState({});
  const [phaseModal, setPhaseModal] = useState(false);
  const [isUpdatingPhase, setIsUpdatingPhase] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState({});

  //invoices States
  const [pInvoiceListLoading, setpInvoiceListLoading] = useState(false);
  const [pInvoiceListData, setpInvoiceListData] = useState({});
  const [pInvoiceModal, setpInvoiceModal] = useState(false);
  const [isUpdatingPInvoice, setIsUpdatingPInvoice] = useState(false);
  const [selectedPInvocied, setSelectedPInvoice] = useState({});
  const editable = useContext(AdminContext);
  const [InvoicedData, setInvoicedData] = useState([]);

  // Bill payer states
  const [billPayerLoading, setBillPayerLoading] = useState(false);
  const [billPayerData, setBillPayerData] = useState({});
  const [billPayerModal, setAddBillPayerModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const scrollParam = queryParams.get("scroll");

    console.log(scrollParam);

    if (scrollParam) {
      // Scroll to the section with the ID that matches the 'scroll' query parameter
      const section = document.getElementById(scrollParam);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]); // This will run when the location (URL) changes

  const fetchDataRecords = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/projects/projectProfile/projectInfo?projectNumber=${jobno}`
      );
      setJobData(result.data);
      setAddressEdit(result.data.UpdateProjectAddress);
      setRunnerTeamEdit(result.data.UpdateProjectInfo);
      setStatsEdit(result.data.UpdateProjectStats);
      setFollowUpEdit(result.data.UpdateFollowUpDate);
      setReqInvEdit(result.data.RequestInvoice);
      setLastUpdated(getFormattedDateTime());
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };

  const fetchTimesheetData = async () => {
    setTimesheetLoading(true);
    const viewMode = selectedValue;
    try {
      const result = await axios.get(
        `${API_URL}/api/timesheets/view?Mode=${viewMode}&projectNumber=${jobno}`
      );
      setTimesheetData(result.data.TimesheetData);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    } finally {
      setTimesheetLoading(false);
    }
  };

  const fetchLogBook = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/projects/projectProfile/projectLogBook?projectNumber=${jobno}`
      );
      AssignLogBook(result.data);
      setIsLogBookLoading(false);
    } catch (error) {
      console.error("Error fetching project logbook:", error);
      setIsLogBookLoading(false);
    }
  };

  const fetchFilters = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/projects/projectProfile/filters`
      );
      setActivityMap(result.data);

      // Extract the keys from the result.data object and store them in a list
      const activityKeys = Object.keys(result.data);

      // Sort the keys in descending order based on the number of elements in their corresponding values array
      activityKeys.sort(
        (a, b) => result.data[b].length - result.data[a].length
      );

      setStatusTypes(activityKeys);
    } catch (error) {
      console.error("Error fetching project status:", error);
    }
  };

  const fetchRunners = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/runnerOptions?mode=[ID],[FullName]`
      );
      setHandlers(result.data);
    } catch (error) {
      console.error("Error fetching project status:", error);
    }
  };

  const fetchLostReasons = async () => {
    try {
      const result = await axios.get(
        `${API_URL}/api/bid/opportunity/lost/reason`
      );
      setLostReasons(result.data.data.lostReasons);
    } catch (error) {
      console.error("Error fetching project status:", error);
    }
  };

  const fetchBillPayers = async () => {
    setBillPayerLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/bill/payer/${jobno}`
      );
      // const data = {
      //   data: response.data.data,
      //   totalPromoted: 222,
      //   totalQuoted: 22,
      // };
      setBillPayerData(response.data.data);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    } finally {
      setBillPayerLoading(false);
    }
  };

  const fetchPhaseList = async () => {
    setPhaseListLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/phases/${jobno}`
      );
      const data = {
        data: response.data.data.phases,
        totalPromoted: response.data.data.totalPromoted,
        totalQuoted: response.data.data.totalQuoted,
      };
      setPhaseListData(data);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    } finally {
      setPhaseListLoading(false);
    }
  };

  const fetchpInvoiceList = async () => {
    setpInvoiceListLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/invoice/project/planned/invoice/${jobno}`
      );
      const data = {
        data: response.data.data.invoices,
        totalPlannedAmount: response.data.data.totalPlannedAmount,
        totalInvoicedAmount: response.data.data.totalInvoicedAmount,
      };
      setpInvoiceListData(data);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    } finally {
      setpInvoiceListLoading(false);
    }
  };

  useEffect(() => {
    fetchDataRecords();
    fetchTimesheetData();
    fetchLogBook();
    fetchFilters();
    fetchRunners();
    fetchLostReasons();
    fetchPhaseList();
    fetchpInvoiceList();
    fetchBillPayers();
  }, []);

  useEffect(() => {
    if (jobData && jobData.status) {
      let formattedStatus;
      if (jobData.status === "ON HOLD") {
        formattedStatus = formatStatus(jobData.status);
      } else if (jobData.status !== null) {
        formattedStatus = titleCase(jobData.status);
      }
      setStatus(formattedStatus);
    }
  }, [jobData]);

  const handleUpdateClick = async (DataTitle, selectedValues) => {
    if (DataTitle == "Update Follow Up Date") {
      if (handlePostBtnClick() == "Invalid") {
        return;
      }
    }

    if (
      DataTitle == "Invoice Request" &&
      !selectedValues.hasOwnProperty("Allowed")
    ) {
      if (!isNaN(parseFloat(selectedValues["Invoice Amount"]))) {
        selectedValues["Allowed"] = false;
        setInvoicedData([DataTitle, selectedValues]);
      } else {
        selectedValues["Allowed"] = true;
      }
    }

    setLoading(true);
    // console.log('DataTitle, selectedValues: ', DataTitle, selectedValues);
    try {
      const response = await fetch(
        `${API_URL}/api/projects/updateProjectInfo?projectNumber=${jobno}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            UserEmail: account.username,
            SectionName: DataTitle,
            UpdatedValues: selectedValues,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        console.log(data.message);
        if (DataTitle != "Update Follow Up Date") setApiResponse(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      console.error("Error in Project Update API:", error);
    } finally {
      setLoading(false);
      fetchDataRecords();
      fetchTimesheetData();
      fetchLogBook();
      fetchPhaseList();
      if (DataTitle != "Update Follow Up Date") setIsResponseShown(true);
    }
  };

  const handleEdit = (data) => () => {
    if (data.DataEditTitle == "Invoice Request") {
      setEditType("SUBMIT");
    } else {
      setEditType("UPDATE");
    }
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const time = now.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const date = now.toLocaleDateString("en-GB");
    return `Last updated ${time} on ${date} (Excluding FB, Stage & Next Issue)`;
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("..");
    }
  };

  const formatDate = (dateString) => {
    const dateParts = dateString.split("/");
    const year = dateParts[2];
    const month = parseInt(dateParts[1], 10);
    const day = dateParts[0];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const date = new Date(year, month - 1, day);
    const formattedDate = `${daysOfWeek[date.getDay()]} ${day} ${months[month - 1]
      } ${year}`;

    return formattedDate;
  };

  // const [status, setStatus] = useState(status_type_options[0]);
  const [status, setStatus] = useState(null);
  const [activity, setActivity] = useState("");
  const [comment, setComment] = useState("");
  const [activityOptions, setActivityOptions] = useState(
    statusActivityMap[status] || []
  );

  useEffect(() => {
    if (status) {
      const formattedStatus =
        status === "ON HOLD" ? formatStatus(status) : titleCase(status);
      handleStatusChange(formattedStatus);
    }
  }, [status]);

  useEffect(() => {
    fetchTimesheetData();
  }, [jobno, selectedValue]);

  // define onValueChange functions
  const handleStatusChange = useCallback(
    (value) => {
      setStatus(value);
      // Check if statusActivityMap[value] is not undefined before setting activityOptions
      if (statusActivityMap[value]) {
        setActivityOptions(statusActivityMap[value]);
      } else {
        setActivityOptions([]); // If statusActivityMap[value] is undefined, set activityOptions to an empty array
      }
    },
    [statusActivityMap]
  ); // pass statusActivityMap as a dependency

  const handleActivityChange = (value) => {
    setActivity(value);
  };

  // handle comment change
  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  //handle Post Btn Click
  const handlePostBtnClick = async () => {
    if (comment.trim() === "") {
      // if comment is empty, add 'invalid' class and don't open modal
      document.querySelector(".CommentInput").classList.add("invalid");
      setTimeout(() => {
        commentInput.classList.remove("invalid");
      }, pulsatingTime);
      return "Invalid";
    } else {
      // if comment is not empty, remove 'invalid' class if it exists
      document.querySelector(".CommentInput").classList.remove("invalid");

      // directly handle confirmation without opening the modal
      handleConfirmationClick();
    }
  };

  const handleValueChange = (newValue) => {
    setSelectedValue(newValue);
  };

  // handle Confirmation click
  const handleConfirmationClick = async () => {
    setLoading(true);

    try {
      const CommentLog = {
        jobno,
        status,
        activity,
        comment,
        email: account.username,
      };

      const response = await fetch(
        `${API_URL}/api/projects/submitProjectComment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(CommentLog),
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Clear form fields after successful submission
        setStatus("");
        setActivity("");
        setComment("");

        // Set the appropriate success message
        setApiResponse(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setApiResponse("Error occurred");
    } finally {
      setLoading(false);
      fetchDataRecords();
      fetchTimesheetData();
      fetchLogBook();
      setIsResponseShown(true);
    }
  };

  const handleSubmitPopUp = async () => {
    handlePostBtnClick();
    fetchDataRecords();
    fetchTimesheetData();
    fetchLogBook();
  };

  const handleMapOpen = (address) => {
    const query = encodeURIComponent(address);
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`;
    window.open(url, "_blank");
  };

  const handleAddphase = () => {
    setSelectedPhase({});
    setIsUpdatingPhase(false);
    setPhaseModal(true);
  };

  const handlePhaseRowEdit = (row, phaseID, index) => {
    const selectedPhase = phaseListData.data.find(
      (row) => row.phaseID == phaseID
    );
    setSelectedPhase(selectedPhase);
    setIsUpdatingPhase(true);
    setPhaseModal(true);
  };

  const handleAddPInvoice = () => {
    setSelectedPInvoice({});
    setIsUpdatingPInvoice(false);
    setpInvoiceModal(true);
  };

  const handlePInvoiceRowEdit = (row, invoiceID, index) => {
    console.log(row, invoiceID, index, "row, invoiceID, index");
    const selectedInvoice = pInvoiceListData.data.find(
      (row) => row.invoiceID == invoiceID
    );
    setSelectedPInvoice(selectedInvoice);
    setIsUpdatingPInvoice(true);
    setpInvoiceModal(true);
  };

  const handlePInvoiceRowDelete = (rowIndex) => {
    dispatch(deleteBillingPlan(rowIndex, fetchpInvoiceList));
  };

  const handleAddBillPayerModal = () => {
    setAddBillPayerModal(true);
  };

  const handleRaiseInvoice = () => {
    navigate(`/raise-invoice?jobNumber=${jobno}&status=start`);
  };

  return (
    <div className='projectProfileContent'>
      <div className='ProjectBaseInfo'>
        <div className='BackBtn' onClick={handleBack}>
          <img src={BackBtn} alt='Back' />
        </div>
        <div className='ProjectAddress'>
          {jobData ? `${jobData.job_no} - ${jobData.address}` : "Loading..."}
          <img
            className='pinIcon'
            onClick={() => handleMapOpen(jobData.address)}
            src={MapPin}
            alt="MapPinAddress"
          />
          {editable && jobData && <img className='editIcon Address' onClick={handleEdit(addressEditInfo)} src={EditIcon} alt="EditIconAddress" />}
        </div>
        <div className='ProjectArchitect'>
          {jobData && jobData.architect ? (
            jobData.architect
            // <Link to={`/clients/clientProfile#${jobData.architect_id}`} className='no-style-link'>
            //   {jobData.architect}
            // </Link>
          ) : "Architect not found"}
        </div>
      </div>
      <div className='ProjectTags'>

        {/* Project Tags */}
        {jobData && jobData.project_tag && jobData.project_tag.length > 0 && (
          <ul>
            {/* Remove duplicates in project_tag array before rendering */}
            {[... new Set(jobData.project_tag)].map(tag => (
              <li className='Tag' key={tag}>{tag}</li>
            ))}
          </ul>
        )}

        <div className='LeftAlignment'>

          {/* Project Folder Link */}
          {jobData && (
            <ul>
              <li className={`ProjectFolderBtn ${jobData && jobData.folder_link ? '' : 'disabled'}`}
                onClick={() => {
                  if (jobData && jobData.folder_link) {
                    window.open(jobData.folder_link, '_blank');
                  }
                }}
              >
                <img className='folderIcon' src={FolderIcon} alt='FolderIcon' />
              </li>
            </ul>
          )}




          {/* Status Bubble */}
          {!loading && jobData && jobData.status ? (
            <li className={`${jobData.status === 'LIVE' ? 'LiveBubble' : jobData.status === 'COMPLETE' ? 'CompleteBubble' : jobData.status === 'ON HOLD' ? 'OnHoldBubble' : jobData.status === 'OPP' ? 'OppBubble' : 'DormantBubble'} StatusTag`}>
              {jobData.status}
            </li>
          ) : (
            <li className='PlaceHolderBubble' />
          )}
        </div>
      </div>

      <div className="ProjectProfileScrollableArea">
        <div className='ProjectInformation' style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div className='ProjectDetails' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>

            <div className='ProjectStageInfo' style={{ width: '350px', padding: '0px' }}>
              {editable && jobData && <img className='editIcon ProjectInfo' onClick={handleEdit(runnerTeamEditInfo)} src={EditIcon} alt="EditIcon" />}

              <div className='HorBlock1'>
                <div className="FieldContainer">
                  <div className='Team FieldTitle'>Team:</div>
                  <span className='InfoText'>{jobData && jobData.team ? jobData.team : '--'}</span>
                </div>
                <div className="FieldContainer">
                  <div className='Runner FieldTitle'>Runner:</div>
                  <span className='InfoText'>{jobData && jobData.runner ? jobData.runner : '--'}</span>
                </div>
              </div>

              <div className='HorBlock3' style={{ width: '60%' }}>
                <div className="FieldContainer">
                  <div className='Stage FieldTitle'>Stage:</div>
                  <span className='InfoText'>
                    {jobData && jobData.stage
                      ? jobData.stage === 2 || jobData.stage === 3
                        ? 'Preliminary'
                        : jobData.stage === 4
                          ? 'Tender'
                          : '--'
                      : '--'}
                  </span>
                </div>
                <div className="FieldContainer">
                  <div className='NextIssueDate FieldTitle'>Next Issue:</div>
                  <span className='InfoText'>{jobData && jobData.next_issue ? formatDate(jobData.next_issue) : '--'}</span>
                </div>
              </div>
            </div>

            <div className='ProjectStats' style={{ paddingRight: '30px' }}>
              {editable && jobData && <img className='editIcon ProjectStat' onClick={handleEdit(statsEditInfo)} src={EditIcon} alt="EditIcon" />}

              <div className='HorBlock1' style={{ width: '46%' }}>
                <div className="FieldContainer">
                  <div className='JobFee FieldTitle'> {jobData && ['LOST OPP', 'OPP'].includes(jobData.status) ? 'Quoted Fee:' : 'Job Fee:'} </div>
                  <span className='InfoText'>{jobData && ['LOST OPP', 'OPP'].includes(jobData.status) && jobData.quoted
                    ? `£${jobData.quoted.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                    : jobData
                      ? `£${jobData.fee.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                      : ''
                  }
                  </span>
                </div>
                <div className="FieldContainer">
                  <div className='JobType FieldTitle'>Job Type: </div>
                  <span className='InfoText'>{jobData && jobData.job_type ? jobData.job_type : '--'}</span>
                </div>
              </div>

              <div className='HorBlock2' style={{ width: '38%' }}>
                {/* <div className="FieldContainer">
                  <div className='Drainage FieldTitle'>Drainage:</div>
                  <span className='InfoText'>{jobData && jobData.drainage ? jobData.drainage : '--'}</span>
                </div>
                <div className="FieldContainer">
                  <div className='TempWorks FieldTitle'>Temp. Works:</div>
                  <span className='InfoText'>{jobData && jobData.temp_works ? jobData.temp_works : '--'}</span>
                </div> */}
              </div>

              <div className='HorBlock3' style={{ width: '26%' }}>
                <div className="FieldContainer">
                  <div className='pFB FieldTitle'>pFB:</div>
                  <span className='InfoText'>
                    {jobData && jobData.pFB !== 199 ? Math.round(jobData.pFB * 100) + '%' : '--'}
                  </span>
                </div>
                <div className="FieldContainer">
                  <div className='FB FieldTitle'>FB:</div>
                  <span className='InfoText'>{jobData && jobData.FB ? Math.round(jobData.FB * 100) + '%' : '--'}</span>
                </div>
              </div>
            </div>

            <div className='ProjectInvoiced'>
              <div className='HorBlock3'>
                <div className="FieldContainer">
                  <div className='invplan FieldTitle'>Rem. Plan:</div>
                  <span className='InfoText'>
                    <span style={{ color: jobData && jobData.rem_planned > 0 ? '#D32030' : 'inherit' }}>
                      {jobData && jobData.rem_planned ? `£${jobData.rem_planned.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '£0'}
                    </span>
                  </span>
                </div>
                <div className="FieldContainer">
                  <div className='inv FieldTitle'>Rem. Inv:</div>
                  <span className='InfoText'>
                    {jobData && jobData.rem_inv ? `£${jobData.rem_inv.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : '£0'}
                  </span>
                </div>
              </div>

              <div className='ProjectInvoicedContainer' style={{ padding: '10px 0px 30px 0px' }}>
                <div className="FieldContainer" >
                  <div className='Invoiced FieldTitle' style={{ textAlign: 'left' }}>Invoiced:</div>
                  <div style={{ width: '100%', marginLeft: '5px' }}>
                    <ProgressBar
                      currentValue={parseFloat(jobData && jobData.invoice ? jobData.invoice : 0)}
                      maxValue={parseFloat(jobData && jobData.fee ? jobData.fee : 0)} // Check if jobData.pFB exists

                      displayMode={'percentage'}
                      variant={'blue'}
                    />
                  </div>
                </div>

                <div className='FieldContainer' style={{ marginTop: '15px' }}>
                  <div
                    className={`ReqInv LongBtn ${!jobData ? 'disabled' : ''}`}
                    onClick={!jobData ? null : handleEdit(ReqInvInfo)}
                  >
                    INVOICE REQUEST
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='ProjectBtns'>
            <div className='ProjectBtnsContainer' style={{ padding: '17.5px 0px 10px 0px' }}>
              <div className="FieldContainer" >
                <div className='AddWorks LongBtn' onClick={() => window.open('https://forms.office.com/r/pNbt9F4BdP', '_blank')}>
                  ADD WORKS
                </div>
              </div>

              <div className='FieldContainer' style={{ marginTop: '20px' }}>
                <div className='Triage LongBtn' onClick={() => window.open('https://outlook.office365.com/owa/calendar/Triage@blueengineering.co.uk/bookings/', '_blank')}>
                  BOOK TRIAGE
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="SubTitle"> Log Book </div>

        <div className="CommentsArea">
          <div className="CommentsSection">
            <p className="Status">
              Status:{" "}
              <span className="Dropdown">
                <Dropdown
                  options={status_type_options}
                  selectedValue={
                    jobData
                      ? jobData.status === "On-hold"
                        ? formatStatus(status)
                        : titleCase(status)
                      : ""
                  }
                  onValueChange={handleStatusChange}
                />
              </span>
            </p>

            <p className="Activity">
              Activity:{" "}
              <span className="Dropdown">
                <Dropdown
                  options={activityOptions}
                  selectedValue={activity}
                  onValueChange={handleActivityChange} // update selected activity when a new one is chosen
                />
              </span>
            </p>

            <p className="Comment">
              Comment:
              <textarea
                className="CommentInput"
                placeholder={comment ? "" : "Comment..."}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => {
                  if (!e.target.value) e.target.placeholder = "Comment...";
                }}
                wrap="hard"
                value={comment}
                onChange={handleCommentChange}
              />
            </p>

            <p className="Post">
              {status === "On-hold" ? (
                <button
                  className={`PostBtn ${!jobData ? "disabled" : ""}`}
                  onClick={!jobData ? null : handleEdit(FollowUpEditInfo)}
                >
                  POST
                </button>
              ) : status === "Opp" && activity === "Opp Update" ? (
                <button
                  className={`PostBtn ${!jobData ? "disabled" : ""}`}
                  onClick={!jobData ? null : () => setFollowUpModal(true)} // Open modal if status is "Opp" and activity is "Opp Update"
                >
                  POST
                </button>
              ) : status === "Opp" && activity === "Appointed" ? (
                <button
                  className={`PostBtn ${!jobData ? "disabled" : ""}`}
                  onClick={!jobData ? null : () => setPromoteModal(true)} // Open modal if status is "Opp" and activity is "Appointed"
                >
                  POST
                </button>
              ) : status === "Opp" && activity === "Lost" ? (
                <button
                  className={`PostBtn ${!jobData ? "disabled" : ""}`}
                  onClick={!jobData ? null : () => setLostOppModal(true)} // Open modal if status is "Opp" and activity is "Lost"
                >
                  POST
                </button>
              ) : (
                <button
                  className={`PostBtn ${!jobData ? "disabled" : ""}`}
                  onClick={!jobData ? null : handlePostBtnClick}
                >
                  POST
                </button>
              )}
            </p>

            {/* <Modal 
              isOpen={modalIsOpen} 
              onRequestClose={closeModal}
              style={customStyles}
            /> */}

            <FollowUpPopUp
              isVisible={followUpModal}
              jobNumber={jobno}
              onSubmit={handleSubmitPopUp}
              onClose={() => setFollowUpModal(false)}
            />
            <AppointedPopUp
              isVisible={promoteModal}
              jobNumber={jobno}
              runners={handlers.map((runner) => ({
                value: runner.id,
                label: runner.name,
              }))}
              onSubmit={handleSubmitPopUp}
              onClose={() => setPromoteModal(false)}
            />
            <LostOppPopUp
              isVisible={lostOppModal}
              jobNumber={jobno}
              reasons={lostReasons}
              onSubmit={handleSubmitPopUp}
              onClose={() => setLostOppModal(false)}
            />

            {displayEditDiv && (
              <EditDiv
                data={currentData}
                handleUpdateClick={handleUpdateClick}
                setDisplayEditDiv={setDisplayEditDiv}
                actionType={editType}
                required={
                  currentData.DataEditTitle === "Invoice Request" ? false : true
                }
              />
            )}

            {loading && (
              <div className="ProjectProfileOverlay">
                <div style={{ borderRadius: "150px", minWidth: "160px" }}>
                  <CircularProgress size={65} />
                </div>
              </div>
            )}

            {isResponseShown && (
              <div className="ProjectProfileOverlay">
                <div className="ProjectProfileResponseBox">
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: "PT Sans",
                      textAlign: "center",
                      margin: "20px",
                      marginLeft: "25px",
                      marginRight: "25px",
                    }}
                    dangerouslySetInnerHTML={{ __html: apiResponse }}
                  />

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {apiResponse?.includes("Warning!") ? (
                      <>
                        <div
                          className="ProjectProfileButton"
                          onClick={() => {
                            setIsResponseShown(false);
                            InvoicedData[1]["Allowed"] = true;
                            handleUpdateClick(InvoicedData[0], InvoicedData[1]);
                          }}
                        >
                          CONFIRM
                        </div>
                        <div
                          className="ProjectProfileButton"
                          onClick={() => setIsResponseShown(false)}
                        >
                          CANCEL
                        </div>
                      </>
                    ) : (
                      <div
                        className="ProjectProfileButton"
                        onClick={() => setIsResponseShown(false)}
                      >
                        OK
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="Logbook" className="LogBook">
          <div className="LogBookTable">
            <table>
              <tbody>
                {isLogBookLoading
                  ? Array(8)
                    .fill()
                    .map((_, index) => (
                      <tr className="LogRow" key={index}>
                        <td
                          className="LogStatus"
                          style={{ marginLeft: "15px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={108}
                            height={25}
                          />
                        </td>
                        <td
                          className="LogActivity"
                          style={{ marginLeft: "-220px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={120}
                            height={20}
                          />
                        </td>
                        <td
                          className="LogComments wide"
                          style={{ marginLeft: "-130px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={500}
                            height={20}
                          />
                        </td>
                        <td
                          className="LogDescription"
                          style={{ marginLeft: "-30px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={300}
                            height={20}
                          />
                        </td>
                      </tr>
                    ))
                  : logBook &&
                  logBook.map((log) => (
                    <tr className="LogRow" key={log.id}>
                      <td
                        className={`${log.status.toUpperCase() === "LIVE"
                          ? "LiveBubble"
                          : log.status.toUpperCase() === "COMPLETE"
                            ? "CompleteBubble"
                            : log.status.toUpperCase() === "ON-HOLD"
                              ? "OnHoldBubble"
                              : log.status.toUpperCase() === "OPP"
                                ? "OppBubble"
                                : log.status.toUpperCase() === "INVOICE"
                                  ? "InvBubble"
                                  : "DormantBubble"
                          } LogStatus`}
                      >
                        {log.status.toUpperCase()}
                      </td>
                      <td className="LogActivity wide">
                        {log.activity_type}
                      </td>
                      <td className="LogComments wide">{log.comments}</td>
                      <td className="LogDescription">{log.description}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="LastUpdated">{lastUpdated}</div>

        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <div className="SubTitle" style={{ marginBottom: "5px" }}>
                {" "}
                Phases{" "}
              </div>
              {
                editable &&
                <div
                  className="addBillPayer LongBtn SubTitle"
                  style={{ width: '100px' }}
                  onClick={handleAddphase}
                >
                  ADD PHASE
                </div>
              }
            </Stack>

            <div
              style={{
                marginBottom: "20px",
              }}
            >
              {jobData && !phaseListLoading ? (
                <PhaseTable
                  jsonData={phaseListData}
                  headerMap={headerMapPhase}
                  handleRowEdit={handlePhaseRowEdit}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100% - 20%)",
                  }}
                >
                  <CircularProgress size={65} />
                </Box>
              )}
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <div className="SubTitle" style={{ marginBottom: "5px" }}>
                {" "}
                Planned/Invoiced{" "}
              </div>
              <div style={{ display: "flex" }}>
                {
                  editable &&
                  <React.Fragment>
                    <div
                      className="addBillPayer LongBtn SubTitle"
                      style={{ marginRight: "15px" }}
                      onClick={handleRaiseInvoice}
                    >
                      RAISED
                    </div>

                    <div
                      className="addBillPayer LongBtn SubTitle"
                      style={{ width: '100px' }}
                      onClick={handleAddPInvoice}
                    >
                      ADD PLANNED
                    </div>
                  </React.Fragment>
                }
              </div>
            </Stack>

            <div
              style={{
                marginBottom: "20px",
              }}
            >
              {pInvoiceListData && !pInvoiceListLoading ? (
                <PlannedInvoicedTable
                  jsonData={pInvoiceListData}
                  headerMap={headerPlannedInvoiced}
                  handleRowEdit={handlePInvoiceRowEdit}
                  handleRowDelete={handlePInvoiceRowDelete}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100% - 20%)",
                  }}
                >
                  <CircularProgress size={65} />
                </Box>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <div className="SubTitle"> Timesheet Views </div>
            <div style={{ marginBlock: "20px" }}>
              <HorizontalButtons
                options={[
                  "SUMMARY - PHASE",
                  "SUMMARY - PERSON",
                  "INDIVIDUAL",
                  "PENDING",
                ]}
                selectedValue={selectedValue} // State to track selected value
                onValueChange={handleValueChange} // Function to handle value change
              />
            </div>
            <div
              style={{
                height: "400px",
                maxHeight: "400px",
                marginBottom: "24px",
              }}
            >
              {jobData && !timesheetLoading ? (
                <Table data={timesheetData} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100% - 20%)",
                  }}
                >
                  <CircularProgress size={65} />
                </Box>
              )}
            </div>
          </Grid>

          <Grid item md={7} xs={12}>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: "60px" }}
            >
              <div className="SubTitle" style={{ marginBottom: "5px" }}>
                {" "}
                Bill Payer{" "}
              </div>
              <div
                className="addBillPayer LongBtn SubTitle"
                style={{ width: '100px' }}
                onClick={handleAddBillPayerModal}
              >
                ADD BILL PAYER
              </div>
            </Stack>
            {billPayerData && !billPayerLoading ? (
              <BillPayerTable
                rowD={billPayerData}
                headerMap={headerBillPayer}
                // handleRowEdit={handlePInvoiceRowEdit}
                jobNumber={jobno}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "calc(100% - 20%)",
                }}
              >
                <CircularProgress size={65} />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>

      {
        phaseModal && (
          <PhasePopUp
            isEdit={isUpdatingPhase}
            isVisible={phaseModal}
            onClose={() => setPhaseModal(false)}
            onRefresh={fetchPhaseList}
            jobNumber={jobno}
            selectedPhase={selectedPhase}
          />
        )
      }

      {
        pInvoiceModal && (
          <PlannedInvoicedPopUp
            isEdit={isUpdatingPInvoice}
            isVisible={pInvoiceModal}
            onClose={() => setpInvoiceModal(false)}
            onRefresh={fetchpInvoiceList}
            jobNumber={jobno}
            selectedPInvocied={selectedPInvocied}
          />
        )
      }

      {
        billPayerModal && (
          <AddBillPayerPopUp
            isVisible={billPayerModal}
            onClose={() => setAddBillPayerModal(false)}
            jobNumber={jobno}
            onRefresh={fetchBillPayers}
          />
        )
      }
    </div >
  );
}

export default ProjectProfile;
const headerMapPhase = {
  phaseID: "",
  phaseName: "Phase Name",
  phaseAmount: "Phase Amt",
  invoicedAmount: "Inv Amt",
  status: "Status",
  phaseDate: "Promoted Date",
  comment: "Comment",
};

const headerPlannedInvoiced = {
  invoiceID: "",
  plannedDate: "Planned Date",
  plannedAmount: "Planned Amt",
  invoicedAmount: "Invoiced Amt",
  invoiceNo: "Invoice No.",
  comment: "Comment",
  // planID2: "",
};

const headerBillPayer = {
  default: "Default",
  name: "Name",
  email: "Email",
  address: "Address",
  // billPayerID: "",
  // contactNo: "Contact No",
};
