import React, { useState } from 'react';
import styles from './TwoButtonsInput.module.css';

const TwoButtonsInput = (props) => {
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const hexCode = props.hexCode;

  const handleFirstButtonClick = () => {
    setSelectedButton('first');
    setSelectedValue(props.FirstBtn);
    props.onValueChange(props.FirstBtn);
    setInputValue('');
  };

  const handleSecondButtonClick = () => {
    setSelectedButton('second');
    setSelectedValue(props.SecondBtn);
    props.onValueChange(props.SecondBtn);
    setInputValue('');
  };

  const handleIRButtonClick = () => {
    setSelectedButton('ir');
    setSelectedValue('IR');
    props.onValueChange('IR');
    setInputValue('');
  };

  const handleInputChange = (event) => {
    setSelectedButton(null);
    setSelectedValue(event.target.value);
    props.onValueChange(event.target.value);
    setInputValue(event.target.value);
  };

  const handleInputClick = () => {
    setSelectedButton(null);
  };

  const buttonStyle = (isSelected) => ({
    backgroundColor: hexCode,
    boxShadow: isSelected ? '0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset' : 'none',
  });

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        className={styles.button}
        style={buttonStyle(selectedButton === 'first')}
        onClick={handleFirstButtonClick}
      >
        {props.FirstBtn}
      </div>
      <div
        className={styles.button}
        style={{
          ...buttonStyle(selectedButton === 'second'),
          marginLeft: '10px',
          width: '35px',
        }}
        onClick={handleSecondButtonClick}
      >
        {props.SecondBtn}
      </div>
      <div
        className={styles.button}
        style={{
          ...buttonStyle(selectedButton === 'ir'),
          marginLeft: '10px',
          width: '35px',
        }}
        onClick={handleIRButtonClick}
      >
        IR
      </div>
      <input
        className={styles.input}
        type='number'
        value={inputValue}
        placeholder={props.OtherPlaceHolder}
        onChange={handleInputChange}
        onClick={handleInputClick}
      />
    </div>
  );
};

export default TwoButtonsInput;