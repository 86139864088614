import React from 'react';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import textfieldStyle from "../../GlobalStyles/styles";
import * as Yup from 'yup';
import styles from './InvoiceNumPopUp.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';

const InvoiceNumPopUp = ({ data, isVisible, onClose, onSubmit }) => {

    // Form validation schema using Yup
    const validationSchema = Yup.object().shape({
        numberValue: Yup.number()
            .required('A value is required')
            .min(1, 'Value must be greater than or equal to 1')
            .typeError('Please enter a valid number'),
    });

    const formik = useFormik({
        initialValues: {
            numberValue: '', // Initial value for the text field
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values.numberValue);
            onClose();
        },
    });

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                <form onSubmit={formik.handleSubmit}>
                    <p className={styles.text}>Please provide a value for the next invoice number. The number displayed is the next number to be filled:
                    </p>
                    <div className={styles.inputSection}>
                        <TextField
                            value={formik.values.numberValue || String(Number(data+1))}
                            sx={textfieldStyle}
                            type="number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name="numberValue"
                            error={Boolean(formik.touched.numberValue && formik.errors.numberValue)}
                            helperText={formik.touched.numberValue && formik.errors.numberValue}
                            fullWidth
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <div style={{ width: '74px', height: '25px' }}>
                            <button type="submit" className={buttonStyles.YesButtonComponentStyling}>
                                SUBMIT
                            </button>
                        </div>
                        <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                            <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InvoiceNumPopUp;
