import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";
import CommentBox from "../CommentBox/CommentBox";

// Dropdown mode
const Dropdown = ({
  options,
  initialSelectedValue = [],
  onValueChange,
  options_width = '100%',
  PopUp = false,
  disabled = false,
  mode = 'single',
  showOnlyOptions = false,
  searchable = false,
  onAddNew = null,
  placeholder = "Select...",
  errorState = false
}) => {
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [isOpen, setIsOpen] = useState(showOnlyOptions);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dropdownRef.current.style.zIndex = 6;
    } else {
      dropdownRef.current.style.zIndex = 5;
    }
  }, [isOpen]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getFilteredOptions = () => {
    if (searchable && searchTerm) {
      return options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return options;
  };

  const filteredOptions = getFilteredOptions();

  // Add new function
  const handleAddNewOption = () => {
    if (onAddNew) {
      onAddNew(searchTerm);
      setSearchTerm('');
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectOption = (option) => {
    let newSelectedValue;

    if (mode === 'multi') {
      if (Array.isArray(selectedValue) && selectedValue.includes(option)) {
        newSelectedValue = selectedValue.filter(val => val !== option);
      } else {
        newSelectedValue = [...selectedValue, option];
      }
      setSelectedValue(newSelectedValue);
      if (onValueChange) {
        onValueChange(newSelectedValue);
      }
    } else {
      setSelectedValue(option);
      setSearchTerm(option); // Set searchTerm to the selected option
      if (onValueChange) {
        onValueChange(option);
      }
      setTimeout(() => {
        setIsOpen(false);
      }, 0);
    }
  };

  const handleClickOutside = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`${styles.dropdown} ${disabled ? styles.dropdownDisabled : ''} ${showOnlyOptions ? styles.dropdownAbsolute : ''}`} ref={dropdownRef}>
      {!showOnlyOptions && (
        <div className={`${styles.dropdownSelected} ${errorState ? styles.errorState : ""
          } `} onClick={toggleDropdown} >
          {searchable ? (
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={placeholder} // Use placeholder prop here
              className={styles.invisibleInput}
              disabled={disabled}
            />
          ) : (
            <div className={`${PopUp && mode !== 'multi' ? styles.noOverflow : styles.dropdownSelectedText} ${mode === 'multi' ? styles.multiOverflow : ''}`}>
              {
                Array.isArray(selectedValue) ? (
                  selectedValue.length > 0 ? (
                    selectedValue
                      .filter(val => val.trim() !== '') // Filter out empty values
                      .slice(0, 1) // Get only the first value
                      .concat(selectedValue.length > 1 ? '...' : '') // Add ellipsis if there are additional values
                      .join('') // Join the first value and ellipsis
                  ) : (
                    <span className={styles.placeholderColor}>{placeholder}</span> // Apply placeholder styling
                  )
                ) : (
                  selectedValue || <span className={styles.placeholderColor}>{placeholder}</span> // Apply placeholder styling
                )
              }
            </div>
          )}
        </div>
      )}
      {(isOpen || showOnlyOptions) && !disabled && (
        <div className={`${styles.dropdownOptions} ${showOnlyOptions ? styles.dropdownOptionsAbsolute : ''}`} style={{ width: options_width }}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                className={styles.dropdownOption}
                onMouseDown={() => selectOption(option)}
              >
                {mode === 'multi' && (
                  <div className={Array.isArray(selectedValue) && selectedValue.includes(option) ? styles.selectedSquare : styles.unselectedSquare}></div>
                )}
                <span className={mode === 'multi' ? styles.multiTextShift : ''}>{option}</span>
              </div>
            ))
          ) : (
            <div className={styles.dropdownOption} onMouseDown={handleAddNewOption}>
              Add New: "{searchTerm}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
