import React from "react";
import { useMsal } from "@azure/msal-react";
import LogOutIconfrom from '../../assets/LogOut.svg';
import './Auth.css'

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
      <div onClick={() => handleLogout("redirect")} className='LogOut'>
        <img src={LogOutIconfrom} className='LogOutIcon'/>
        <div className='logoutText'>Log Out</div>
      </div>
  );
};

