import React from 'react';
import styles from './ProgressBar.module.css';

function ProgressBar({ 
  maxValue, 
  currentValue, 
  className = '', 
  displayMode='value', 
  variant='default', 
  reverseColor=false, 
  greenThreshold=70, 
  yellowThreshold=100, 
  orangeThreshold=120, 
  redThreshold=150 
}) {
  let percentage = 0; // Default value
  let colorPercentage = 0;

  // Define the blueThreshold values only if the variant is 'blue'
  const blueThreshold = variant === 'blue' ? [20, 40, 60, 80] : null;

  if (maxValue > 0 && currentValue > 0) { 
    percentage = (currentValue / maxValue) * 100;
    colorPercentage = percentage > 100 ? 100 : percentage;
  }

  const colorClass = maxValue > 0 && currentValue > 0 ? (
    variant === 'blue'
      ? (percentage < blueThreshold[0] ? styles.blueExtraLight
      : percentage < blueThreshold[1] ? styles.blueLight 
      : percentage < blueThreshold[2] ? styles.blueMedium 
      : percentage < blueThreshold[3] ? styles.blueDark 
      : styles.blueDarker)
      : reverseColor
        ? (percentage < redThreshold ? styles.red 
        : percentage < orangeThreshold ? styles.orange 
        : percentage < yellowThreshold ? styles.yellow 
        : styles.green)
        : (percentage < greenThreshold ? styles.green 
        : percentage < yellowThreshold ? styles.yellow 
        : percentage < orangeThreshold ? styles.orange 
        : styles.red)
    ) : ""; 

  const displayValue = displayMode === 'percentage'
  ? `${Math.round(percentage)}%` 
  : displayMode === 'value'
    ? `${currentValue}`
    : displayMode === 'currency'
    ? `£${Number(currentValue).toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`
    : displayMode === 'valuePercent'
    ? `${Math.round(currentValue * 100)}%`
    : '';                        

  return (
    <div className={`${styles.progressBar} ${className}`}>
      <div className={`${styles.progressBar__colorFill} ${colorClass}`} 
          style={{ width: `${colorPercentage}%` }} 
      />
      <span className={styles.progressBar__text}>
        {displayValue}
      </span>
    </div>
  );
}

export default ProgressBar;
