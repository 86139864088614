// Opportunities.js
import React, { useState, useEffect } from "react";
import styles from "./DashboardOpportunities.module.css";
import buttonStyle from "../../../GlobalStyles/button.module.css";
import {
  Card, 
  CardContent,
  Grid,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import jsonData from "./data.json";
import UploadModal from "../../../Components/UploadPopUp/UploadPopUp";
import TaskCard from '../../../Components/TaskCard/TaskCard';
import { useMsal } from "@azure/msal-react";

import { useDispatch, useSelector } from "../../../redux/store";
import {
  getTasks,
  getAssignees,
  startLoading,
  uploadDoc,
} from "../../../redux/slices/dashboard";
import ApiResponseDisplay from "../../../Components/ApiResponseDisplay/ApiResponseDisplay";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function DashboardOpportunities() {
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = accounts[0];

  const [showUploadModal, setshowUploadModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [comment, setComment] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [selectedFileDetails, setSelectedFileDetails] = useState({});
  const { tasks, isAssigneeLoading, isLoading, error } = useSelector((state) => state.dashboard);
  const [uploadList, setUploadList] = useState([]);
  const [feePropList, setFeePropList] = useState([]);
  const [isaToDoList, setIsaToDoList] = useState([]);
  const [isaToSendList, setIsaToSendList] = useState([]);
  const [fbpsList, setFbpsList] = useState([]);


  useEffect(() => {
    dispatch(getTasks());
    dispatch(getAssignees());

  }, []);

  useEffect(() => {

    if (error && error?.response?.data !== null) {
      setIsResponseShown(true);
    }

  }, [error]);

  useEffect(() => {
    const uploadsdata = tasks.filter((task) => task.deadlineType == "upload");
    const feepropdata = tasks.filter((task) => task.deadlineType == "fee_proposal");
    const isatododata = tasks.filter((task) => task.deadlineType == "isa_todo");
    const isatosenddata = tasks.filter((task) => task.deadlineType == "isa_to_sent");
    const fbpsdata = tasks.filter((task) => task.deadlineType == "fbps");

    setUploadList(uploadsdata);
    setFeePropList(feepropdata);
    setIsaToDoList(isatododata);
    setIsaToSendList(isatosenddata);
    setFbpsList(fbpsdata);
  }, [tasks]);

  const setupUploadModal = (task, fileDetails) => {
    setSelectedTask(task);
    setSelectedFileDetails(fileDetails); // Save the details of the selected file
    setshowUploadModal(true);
  };

  const closeModal = () => {
    setSelectedTask({});
    setshowUploadModal(false);
  };

  const handleUpload = (files) => {
    // Here, you might want to update the state or perform some action with the files
    setUploadedFiles(files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(`File name: ${file.name}`);
        console.log(`Content bytes:`, e.target.result);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const handleSubmit = () => {
    console.log("Submitted Files: ", uploadedFiles);
    console.log("Comment: ", comment);
    console.log("task: ", selectedTask);
    dispatch(startLoading());

    uploadedFiles.map((file) => {
      console.log(file)
      const data = dispatch(uploadDoc(file, selectedTask.projectNumber, selectedTask.address, selectedTask.deadlineID,
        selectedTask.deadlineType, selectedFileDetails.uploadID, selectedFileDetails.uploadType, account.username, comment));
      console.log(data);
    });

    closeModal();

    // Optionally reset state or close modal after submission
    // setComment("");
    // setUploadedFiles([]);
    // setshowUploadModal(false);
  };





  // Initial
  return (
    <div className={styles.opportunitiesContainer}>
      <div className={styles.pageTitle}>Opportunities</div>

      {isLoading && isAssigneeLoading && (
        <div className="ProfileHolidayOverlay">
          <div
            className="ProfileHolidayResponseBox"
            style={{ borderRadius: "150px", minWidth: "160px" }}
          >
            <CircularProgress size={65} />
          </div>
        </div>
      )}

      {!isLoading && !isAssigneeLoading && (
        <Grid
          container
          wrap="nowrap"
          sx={{ flexDirection: { xs: "column", md: "row" }, mt: 5 }}
          spacing={2}
        >
          <Grid item xs={12 / 5}>
            <div className={styles.oppHead}>Uploads</div>
            <div className={styles.scrollableColumn}>
              {
              uploadList.length > 0 ?
              uploadList?.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  setupUploadModal={setupUploadModal}
                  buttonStyle={buttonStyle}
                />
              ))
              : <Card className={styles.Card}>
                  <CardContent className={styles.CardContent}>
                    <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className={styles.cardTitle}
                          style={{ flex: 1, wordWrap: "break-word" }}
                      >
                          No Uploads
                      </Typography>
                  </CardContent>
                </Card>
                }
            </div>
          </Grid>
          <Grid item xs={12 / 5}>
            <div className={styles.oppHead}>Fee Proposals</div>
            <div className={styles.scrollableColumn}>
              {
              feePropList.length > 0 ?
              feePropList?.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  setupUploadModal={setupUploadModal}
                  buttonStyle={buttonStyle}
                />
              ))
              : <Card className={styles.Card}>
                  <CardContent className={styles.CardContent}>
                    <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className={styles.cardTitle}
                          style={{ flex: 1, wordWrap: "break-word" }}
                      >
                          No Fee Proposals
                      </Typography>
                  </CardContent>
                </Card>
              }
            </div>
          </Grid>
          <Grid item xs={12 / 5}>
            <div className={styles.oppHead}>ISAs | To Do</div>
            <div className={styles.scrollableColumn}>
              {
              isaToDoList.length > 0 ?
              isaToDoList?.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  setupUploadModal={setupUploadModal}
                  buttonStyle={buttonStyle}
                />
              ))
              
              : <Card className={styles.Card}>
                  <CardContent className={styles.CardContent}>
                    <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className={styles.cardTitle}
                          style={{ flex: 1, wordWrap: "break-word" }}
                      >
                          No Outstanding ISAs ToDo
                      </Typography>
                  </CardContent>
                </Card>
                }
            </div>
          </Grid>
          <Grid item xs={12 / 5}>
            <div className={styles.oppHead}> ISAs | To Send</div>
            <div className={styles.scrollableColumn}>
              {
              isaToSendList.length > 0 ?
              isaToSendList?.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  setupUploadModal={setupUploadModal}
                  buttonStyle={buttonStyle}
                />
              )) 
              : <Card className={styles.Card}>
                  <CardContent className={styles.CardContent}>
                    <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className={styles.cardTitle}
                          style={{ flex: 1, wordWrap: "break-word" }}
                      >
                          No ISAs To Be Sent
                      </Typography>
                  </CardContent>
                </Card>}
            </div>
          </Grid>

          <Grid item xs={12 / 5}>
            <div className={styles.oppHead}> FBPs</div>
            <div className={styles.scrollableColumn}>
              {
              fbpsList.length > 0 ? 
              fbpsList?.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  setupUploadModal={setupUploadModal}
                  buttonStyle={buttonStyle}
                />
              ))
              : <Card className={styles.Card}>
                  <CardContent className={styles.CardContent}>
                    <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className={styles.cardTitle}
                          style={{ flex: 1, wordWrap: "break-word" }}
                      >
                          No Outstanding FBPs
                      </Typography>
                  </CardContent>
                </Card>
                }
            </div>
          </Grid>
        </Grid>
      )}
      <UploadModal
        show={showUploadModal}
        onClose={() => closeModal()}
        onUpload={handleUpload}
        onCommentChange={setComment}
        onSubmit={handleSubmit}
        comment={comment}
      />
      {isResponseShown && (
        <ApiResponseDisplay apiResponse={error?.response?.data} setIsResponseShown={setIsResponseShown} />
      )}
    </div>
  );
}

export default DashboardOpportunities;
