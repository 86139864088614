import React, { useState } from 'react';
import Modal from 'react-modal';
import ButtonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';
import styles from './Holiday.module.css';

function BookedLeaveTable({ rows, handleApproval, handleRejection }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null); // new state for currentId
  const [comment, setComment] = useState('');
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  }

  const openModal = (id) => {
    setCurrentId(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleApproveClick = (id) => {
    handleApproval(id);
    setModalIsOpen(false);
    };

  const handleRejectClick = (id) => {
    handleRejection(id, comment);
    setModalIsOpen(false);
  };

  const handleClick = (id) => {
    if (text == 'reject') {
      handleRejectClick(id);
    } else {
      handleApproveClick(id);
    }
  }
  
  const customStyles = {
    overlay: {
      zIndex: 10000,
      backgroundColor: 'transparent', 
    },
    content: {
      maxHeight: '90%', // adjust as needed
      maxWidth: '90%', // adjust as needed
      minHeight: text === 'reject' ? '165px' : '100px', // adjust as needed
      minWidth: '200px', // adjust as needed
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eaf2f9',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      overflow: 'auto' // add scroll bar if content exceeds maxHeight or maxWidth
    },
  };
  

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Person</th>
            <th>Dates</th>
            <th>Used</th>
            <th>Left</th>
            <th>Type</th>
            <th>Length</th>
            <th>Approval</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id} style={{fontSize: '12px'}}>
              <td style={{fontSize: '12px'}}>{row.status}</td>
              <td style={{fontSize: '12px'}}>{row.dates}</td>
              <td style={{fontSize: '12px'}}>{row.used}</td>
              <td style={{fontSize: '12px'}}>{row.left}</td>
              <td style={{fontSize: '12px'}}>{row.type}</td>
              <td style={{fontSize: '12px'}}>{row.length}</td>
              <td style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', fontSize: '12px'}}>
                <div style={{width: '36px', height: '25px'}}>
                  <button className={ButtonStyles.YesButtonComponentStyling} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {openModal(row.id); setText('approve')}}>
                    YES
                  </button>
                </div>
                <div style={{width: '36px', height: '25px', marginLeft: '10px'}}>
                  <button className={ButtonStyles.NoButtonComponentStyling} style={{fontSize: '12px'}} onClick={() => {openModal(row.id); setText('reject')}}>
                    NO
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <div style={{margin: '24px 24px 0px 24px', fontSize: '14px', fontFamily: 'PT Sans', textAlign: 'center'}}>Are you sure you want to {text} this request?</div>
        <div style={{ height: text === 'reject' ? '15px' : '0px' }}></div>
        { text == 'reject' && (
        <textarea 
          class = {isFocused ? styles.bookingLeaveComment : styles.nonBookingLeaveComment}
          value={comment} 
          onChange={(e) => setComment(e.target.value)} 
          placeholder={isFocused ? '' : 'Comment...'}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />)}
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
          <div style={{width: '36px', height: '25px'}}>
            <button className={ButtonStyles.YesButtonComponentStyling} style={{marginRight: '10px', marginBottom: '24px', marginTop: '19px'}} onClick={() => handleClick(currentId)}>YES</button>
          </div>
          <div style={{width: '36px', height: '25px', marginLeft: '10px'}}>
            <button className={ButtonStyles.NoButtonComponentStyling} style={{marginBottom: '24px', marginTop: '19px'}} onClick={closeModal}>NO</button>
          </div>
        </div>
        </div>
      </Modal>
    </div>
  );
}

export default BookedLeaveTable;