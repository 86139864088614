export function titleCase(input) {
  if (input === null) {
    return ''; // or any default value you prefer
  }
  
  return input.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function formatStatus(status) {
  if (status === 'ON HOLD') {
    return 'On-hold';
  }
  else if (status === 'IN HOUSE') {
    return 'In-house';
  }
  return status;
}