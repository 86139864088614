import React, { useEffect, useState, useRef } from 'react';
import styles from './style.module.css';

function CommentBox({ initialData, onValueChange, invalid = false, liningColour = '', locked = false, placeholder = '', integerOnly = false }) {
  const [comment, setComment] = useState(initialData || "");
  const [isInvalid, setIsInvalid] = useState(invalid);
  const [isFocused, setIsFocused] = useState(false);
  const [color, setColor] = useState(liningColour);
  const commentRef = useRef(null);

  const handleTextChange = (e) => {
    // If integerOnly is true, only allow integer input
    if (integerOnly) {
      const intValue = parseInt(e.target.value, 10);
      // If the parsed value is a number, update the state, else set it to empty string
      setComment(!isNaN(intValue) ? intValue.toString() : '');
    } else {
      setComment(e.target.value);
    }

    if (onValueChange) {
      onValueChange(e.target.value);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    setIsInvalid(invalid);
    if (invalid) {
      const timerId = setTimeout(() => {
        setIsInvalid(false);
        console.log(isInvalid)
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [invalid]);

  const commentClass = `${styles.Comment} ${isInvalid ? styles.invalidInput : ''}`;

  return integerOnly ? (
    <input
      ref={commentRef}
      type="text" // Using "text" type to avoid spinner on number inputs
      inputMode="numeric" // Ensures mobile keyboards show numeric input
      pattern="[0-9]*" // Allows only numbers
      className={commentClass}
      id='FormComment'
      placeholder={placeholder}
      readOnly={locked}
      value={comment}
      onChange={handleTextChange}
      style={{ borderColor: liningColour, height: '25px' }}
    />
  ) : (
    <textarea
      ref={commentRef}
      className={commentClass}
      id='FormComment'
      placeholder={placeholder}
      readOnly={locked}
      value={comment}
      onChange={handleTextChange}
      style={{ borderColor: liningColour }}
    />
  );
}

export default CommentBox;
