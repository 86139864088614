import React, { useState, useEffect } from 'react';
import './Training.css';
import Dropdown from "../../../Components/DropDownSelect/Dropdown";
import CompanyCalander from "../../../Components/ResourceCalander/ResourceCalander";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import Table from '../../../Components/Table/TableV2';
import '../../../Components/YesNoButtonsCSS/YesNoButtons.css';

function Training() {
    const { accounts } = useMsal();
    const account = accounts[0];
    const API_URL = process.env.REACT_APP_API_URL;
    const [reload, setReload] = useState(0);
    const [apiResponse, setApiResponse] = useState(null);
    const [isResponseShown, setIsResponseShown] = useState(false);
    const [loading, setLoading] = useState(true);

    const [fetchedData, setFetchedData] = useState([]);

    const fetchData = async () => {
        setLoading(true);

        try {
            const response = await axios.get(`${API_URL}/api/Training/pageData?userEmail=${account.username}`);

            const data = response.data;
            setFetchedData(data);

        } catch (error) {
            console.error(error);
            throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [reload]);

    return (
        <>
            {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center' }}>
                    <CircularProgress size={65} />
                </Box>

            ) : (
                <div className='ProfileMainContent'>
                    <div className='TrainingManagement'>
                        <div className='trainingTimeComment'>
                            <div className='title' style={{width:'100%'}}>Timesheet Comments Overview</div>
                            <div className='TimesheetTable' style={{width:'100%', height: '70vh', marginTop: '20px'}}>
                                <Table data={fetchedData} />
                            </div>
                        </div>
                        <div className='Disclaimer'>Summary of Comments from giving training and reeciving training timesheet entries</div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Training;