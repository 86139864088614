import React, { useState, useEffect } from 'react';
import styles from './EndOfYearAdjustment.module.css';
import BookedLeaveTable from './BookedLeaveTable.js';
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';
import Table from '../../../../Components/Table/TableV2.js'
import { Outlet } from 'react-router-dom';

function EndOfYearAdjustment() {
  const [reload, setReload] = useState(0);
  const [EndOfYearAdjustmentRows, setEndOfYearAdjustmentRows] = useState([]); 
  const { accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const account = accounts[0]; 
  const [apiResponse, setApiResponse] = useState(null);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true)
    const fetchRequestedLeaveList = fetch(`${API_URL}/api/AdminHoliday/RequestedLeaveList?ApproversEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })

    const fetchCalendarData = fetch(`${API_URL}/api/CalendarData`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    });

    const fetchHolidayOverview = fetch(`${API_URL}/api/Admin/Leave/EndOfYearAdjustmentRows`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchRequestedLeaveList, fetchCalendarData, fetchHolidayOverview])
    .then(responses => Promise.all(responses.map(response => response.json())))
    .then(([fetchedRequestedLeaveList, fetchedCalendarData, fetchHolidayOverview]) => {
        setEndOfYearAdjustmentRows(fetchHolidayOverview);
        setIsDataFetched(true);
    });

    setLoading(false)
  }, [reload]);

  return (
    <>
      {isDataFetched ? (
        <div className={styles.mainContent}>
          <div className={styles.content}>
            <div className={styles.title}>End of Year Adjustment</div>
            <div className={styles.button}>SUBMIT</div>
          </div>
          <Table data={EndOfYearAdjustmentRows}/>
          
          
          { loading ? // Here is the code for displaying error/success messages
              <div className={styles.ProfileHolidayOverlay}>
                <CircularProgress size={65} />
              </div>
              : 
              isResponseShown && (
                <div className={styles.ProfileHolidayOverlay}>
                  <div className={styles.ProfileHolidayResponseBox}>
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: 'PT Sans',
                      textAlign: 'center',
                      margin: '20px',
                      marginLeft: '25px',
                      marginRight: '25px'
                    }}
                  
                    dangerouslySetInnerHTML={{ __html: apiResponse }}
                    >
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className={styles.ProfileHolidayButton}
                      onClick={() => setIsResponseShown(false)}
                    >
                      OK
                    </button>
                  </div>
                  </div>
                </div>
                )}
        </div>
      ) : (
        <Box sx={{display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center'}}>
          <CircularProgress size={65} />
        </Box>
      )}
    </>
  );
}

export default EndOfYearAdjustment;