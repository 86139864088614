import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from './ResizableTh';
import { colors } from '@mui/material';
import styles from './EmpInfo.module.css';
import { Link } from 'react-router-dom';

function EmpInfoListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 40, // default width for Ref
    1: 80, // default width for First Name
    2: 80, // default width for Last Name
    3: 60, // default width for Level
    4: 100, // default width for Job Title
    5: 125, // default width for Line Manager
    6: 125, // default width for Team
    7: 125, // default width Fee Age
    8: 150, // default width for Remaining holiday
    9: 80, // default width for Status
  });
  
  const navigate = useNavigate();
  
  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      // Calculate the current total width of the columns
      const totalWidth = Object.values(prev).reduce((a, b) => a + b, 0);
  
      // Calculate the width of the table
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;
  
      // Only update the width if it's different from the current width and it doesn't cause the total width to exceed the table width
      if (prev[index] !== width && totalWidth - prev[index] + width <= tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        return newWidths;
      }
  
      // If the width is the same or it causes the total width to exceed the table width, shrink other columns
      if (totalWidth - prev[index] + width > tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
  
        // Calculate the excess width
        const excessWidth = totalWidth - prev[index] + width - tableWidth;
  
        // Distribute the excess width to other columns
        const otherColumns = Object.keys(newWidths).filter((key) => key !== index);
        let remainingExcessWidth = excessWidth;
  
        // Determine whether to shrink the columns to the left or right
        const shrinkLeft = index > 0 && index < otherColumns.length;
  
        // Sort the other columns in the direction of shrinking
        const sortedColumns = shrinkLeft ? otherColumns.sort((a, b) => a - b) : otherColumns.sort((a, b) => b - a);
  
        for (const column of sortedColumns) {
          if (newWidths[column] > 50 + remainingExcessWidth / otherColumns.length) { // Assuming 50 is the minimum width for each column
            newWidths[column] -= remainingExcessWidth / otherColumns.length;
            remainingExcessWidth -= remainingExcessWidth / otherColumns.length;
          } else {
            remainingExcessWidth -= newWidths[column] - 50;
            newWidths[column] = 50;
          }
        }
  
        return newWidths;
      }
  
      // If the width is the same, return the previous state to avoid unnecessary re-renders
      return prev;
    });
  }, []);
  

  return (
    <div ref={tableRef}>
      <table>
        <thead>
          <tr>
            <ResizableTh onResize={(width) => setColumnWidth(0, width)} index={0}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('Ref')}>
                ID
                {props.sortColumn === 'Ref' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(1, width)} index={1}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('FirstName')}>
               First Name
                {props.sortColumn === 'FirstName' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(2, width)} index={2}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('LastName')}>
                Last Name
                {props.sortColumn === 'LastName' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(3, width)} index={3}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('Level')}>
                Level
                {props.sortColumn === 'Level' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(4, width)} index={4}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('JobTitle')}>
                Job Title
                {props.sortColumn === 'JobTitle' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(5, width)} index={5}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('LineManager')}>
                Line Manager
                {props.sortColumn === 'LineManager' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(6, width)} index={6}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('Team')}>
                Team
                {props.sortColumn === 'Team' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(7, width)}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('Age')}>
                Age
                {props.sortColumn === 'Age' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(8, width)}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('RemainingHoliday')}>
              Remaining Holiday
                {props.sortColumn === 'RemainingHoliday' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
            <ResizableTh onResize={(width) => setColumnWidth(9, width)} index={7}>
              <span style={{textAlign: 'center'}} onClick={() => props.onSort('Status')}>
              Status
                {props.sortColumn === 'Status' && (
                  props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                )}
              </span>
            </ResizableTh>
          </tr>
        </thead>
        <tbody>
          {props.loading ? (
            // Render skeleton rows
            [...Array(50)].map((_, index) => (
              <tr key={index}>
                {[...Array(10)].map((_, cellIndex) => (
                  <td key={cellIndex}>
                    <div className={styles.skeleton} style={{ height: '13px'}}></div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            props.rows.map((row) => (
              <tr onClick={() => navigate(`Profile#${row.Ref}`)} key={row.jobno}>
                {['Ref', 'FirstName', 'LastName', 'Level', 'JobTitle','LineManager','Team','Age', 'RemainingHoliday', 'Status'].map((fieldName, index) => {
                  let content = row[fieldName];
                  let cellContent;

                  if (fieldName === 'Status') {
                    console.log(row.Status === "CURRENT" ? 'yes':'no');
                    cellContent = (
                      <div className={
                        row.Status === "LEAVER" ? styles.StatusLeaver : 
                        row.Status === "CURRENT" ? styles.StatusCurrent :
                        styles.StatusDormant
                      }>
                        {content}
                      </div>
                    );
                  } else {
                    cellContent = content;
                  }

                  if (fieldName === 'Status') {
                    console.log(cellContent);
                  }

                  return (
                    <td key={fieldName} style={{padding: '0px'}}>
                      <Link to={`Profile#${row.Ref}`} style={linkStyle}>
                        <div className={styles.tdContent} style={{margin: '0px', minWidth: '50px', maxWidth: '35vw', textAlign: 'center', padding: '8px 8px 8px 8px'}}>{cellContent}</div>
                      </Link>
                    </td>
                  );
                })}
              </tr>
            ))                        
          )}
        </tbody>
    </table>
  </div>
  );
}

export default EmpInfoListTable;