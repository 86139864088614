import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";

const Dropdown = ({ options, selectedValue, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option) => {
    onValueChange(option);
    setTimeout(() => {
      setIsOpen(false);
    }, 0);
  };
  
  const handleClickOutside = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    // Ensures the selectedValue passed in props is the initial selected option
    if (options.includes(selectedValue)) {
      onValueChange(selectedValue);
    } else if (options.length > 0) {
      // Select the first option if the selectedValue is not in options
      onValueChange(options[0]);
    }
  }, [options, selectedValue, onValueChange]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.dropdownSelected} onClick={toggleDropdown}>
        {selectedValue}
      </div>
      {isOpen && (
        <div className={styles.dropdownOptions}>
          {options.map((option, index) => (
            <div
              key={index}
              className={styles.dropdownOption}
              onMouseDown={() => selectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
