import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// Redux

// ----------------------------------------------------------------------

let initialState = {
  selectedRow: null
};

export const rowSlice = createSlice({
  name: "rowhighlight",
  initialState,
  reducers: {

    // set filter values in redux
    setSelectedRow(state, action) {
      state.selectedRow = action.payload;
    },

  },

});

export const {
  setSelectedRow,
} = rowSlice.actions;

// Reducer

export default rowSlice.reducer;