import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

// Example usage
const formattedDate = formatDate("Mon Mar 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)");
console.log(formattedDate); // Outputs: "04-03-2024"

// ----------------------------------------------------------------------

let initialState = {
  searchTerm: "",
  inputValue: "",
  statusFilter: "Open",
  sortColumn: "",
  sortDirection: "asc",
  currentPage: 1,
  scrollPos: 0,
  LastStatus: "Open",
  opportunities: { 'rows': [] },
  configuration: {},
  error: '',
  client: {},
  newClient: {},
  billPayer: {},
  runners: {},
  isConfigLoading: false,
  isNewOppLoading: false,
  isOppListLoading: false,
  isBillPayerLoading: false,
  isClientLoading: false,
};

export const opportunitiesSlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {

    // set filter values in redux
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setinputValue(state, action) {
      state.inputValue = action.payload;
    },

    setStatusFilter(state, action) {
      state.statusFilter = action.payload;
    },

    setSortDirection(state, action) {
      state.sortDirection = action.payload;
    },

    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },

    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },

    setScrollPos(state, action) {
      state.scrollPos = action.payload;
    },

    setLastStatus(state, action) {
      state.LastStatus = action.payload;
    },

    setConfiguration(state, action) {
      state.configuration = action.payload;
      state.isNewOppLoading = false;
      state.isConfigLoading = false;
    },

    setBillPayer(state, action) {
      state.billPayer = action.payload;
      state.isBillPayerLoading = false;
    },

    setClient(state, action) {
      state.client = action.payload;
      state.isClientLoading = false;
    },

    setNewClient(state, action) {
      state.newClient = action.payload;
    },

    setRunners(state, action) {
      state.runners = action.payload;
    },

    setOppList(state, action) {
      state.opportunities = action.payload;
      state.isNewOppLoading = false;
      state.isOppListLoading = false;
    },

    startConfigurationLoading(state) {
      state.isConfigLoading = true;
    },

    startNewOppLoading(state) {
      state.isNewOppLoading = true;
    },

    startBillPayerLoading(state) {
      state.isBillPayerLoading = true;
    },

    startClientLoading(state) {
      state.isClientLoading = true;
    },

    startOppListLoading(state) {
      state.isOppListLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isConfigLoading = false;
      state.error = action.payload;
    },

  },

});

export const {
  setinputValue,
  setCurrentPage,
  setLastStatus,
  setSortColumn,
  setSortDirection,
  setSearchTerm,
  setStatusFilter,
  hasError,
} = opportunitiesSlice.actions;

export function getConfiguration() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(opportunitiesSlice.actions.startConfigurationLoading());
    try {
      const response = await axios.get(`${API_URL}/api/bid/opportunity/configuration`);
      dispatch(opportunitiesSlice.actions.setConfiguration(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function getClient(clientName, clientType) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(opportunitiesSlice.actions.startClientLoading());
    try {
      const response = await axios.get(`${API_URL}/api/bid/opportunity/client?clientName=${clientName}&clientType=${clientType}`);
      const formattedClients = response.data.data.map(client => ({
        value: client.id,
        label: client.name
      }));
      dispatch(opportunitiesSlice.actions.setClient(formattedClients));
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function getBillPayer(clientName, clientType) {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(opportunitiesSlice.actions.startBillPayerLoading());
    try {
      const response = await axios.get(`${API_URL}/api/bid/opportunity/client?clientName=${clientName}&clientType=${clientType}`);
      const formattedBillPayer = response.data.data.map(billPayer => ({
        value: billPayer.id,
        label: billPayer.name
      }));
      dispatch(opportunitiesSlice.actions.setBillPayer(formattedBillPayer));
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function getRunners() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(opportunitiesSlice.actions.startBillPayerLoading());
    try {
      const response = await axios.get(`${API_URL}/api/runnerOptions?mode=[ID],[FullName]`);
      const formattedRunners = response.data
        .map(runner => ({
          value: runner.id,
          label: runner.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Sorting by label alphabetically
      dispatch(opportunitiesSlice.actions.setRunners(formattedRunners));
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function getOpportunities(
  statusFilter = 'open',
  page = 1,
  rowsPerPage = 25,
  searchTerm = '',
  sortColumn = '',
  sortDirection = 'asc'
) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async (dispatch) => {
    dispatch(opportunitiesSlice.actions.startOppListLoading());
    try {
      // Construct the query string
      const queryParams = new URLSearchParams({
        statusFilter,
        page,
        rowsPerPage,
        searchTerm,
        sortColumn,
        sortDirection
      }).toString();

      const response = await axios.get(`${API_URL}/api/bid/opportunity/all?${queryParams}`);
      dispatch(opportunitiesSlice.actions.setOppList(response.data));
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function createOpportunity(formData) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async () => {
    try {
      dispatch(opportunitiesSlice.actions.startNewOppLoading());
      const response = await axios.post(`${API_URL}/api/bid/opportunity`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );

      dispatch(getOpportunities());
      return response;
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error.response.data.message));
    }
  };
}

export function createClient(formData) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async () => {
    try {
      const response = await axios.post(`${API_URL}/api/bid/opportunity/client`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      dispatch(opportunitiesSlice.actions.setNewClient(response.data.data))
    } catch (error) {
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function promoteJob(jobNumber, comment, fee, runnerID, runnerName, updatedBy) {
  const API_URL = process.env.REACT_APP_API_URL;

  const formData = {
    jobNumber: Number(jobNumber),
    comment,
    fee: Number(fee),
    runnerID: Number(runnerID),
    runnerName,
    updatedBy
  }

  // const formData = new FormData();
  // formData.append("jobNumber", Number(jobNumber));
  // formData.append("comment", comment);
  // formData.append("fee", Number(fee));
  // formData.append("runnerID", Number(runnerID));
  // formData.append("runnerName", runnerName);
  // formData.append("updatedBy", updatedBy);

  // console.log(formData);

  return async () => {
    try {
      const response = await axios.post(`${API_URL}/api/bid/opportunity/appointed`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );

      return response;
    } catch (error) {
      console.log(error)
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function deadenJob(jobNumber, reason, updatedBy) {
  const API_URL = process.env.REACT_APP_API_URL;

  const formData = new FormData();
  formData.append("jobNumber", Number(jobNumber));
  formData.append("reason", reason);
  formData.append("updatedBy", updatedBy);


  return async () => {
    try {
      const response = await axios.post(`${API_URL}/api/bid/opportunity/lost/reason`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );

      return response;
    } catch (error) {
      console.log(error)
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export function updateFollowUpDate(jobNumber, followUpDate, updatedBy) {
  const API_URL = process.env.REACT_APP_API_URL;

  const formData = new FormData();
  formData.append("jobNumber", Number(jobNumber));
  formData.append("followUpDate", formatDate(followUpDate));
  formData.append("updatedBy", updatedBy);

  return async () => {
    try {
      const response = await axios.post(`${API_URL}/api/bid/opportunity/add/follow/up/date`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );

      return response;
    } catch (error) {
      console.log(error)
      console.error(error);
      dispatch(opportunitiesSlice.actions.hasError(error));
    }
  };
}

export default opportunitiesSlice.reducer;