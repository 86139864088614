import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import DropDown from "../../Components/DropDownBox/DropdownV2";
import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import styles from "../../Components/FeePropFeePopUp/FeePropFeePopUp.module.css";
import buttonStyles from "../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import { useDispatch, useSelector } from "../../redux/store";
import DatePicker from "../../Components/DatePicker/DatePicker";
import BlueLogo from "../../assets/BlueLogo.png";
import { useFormik } from "formik";
import { Form, FormikProvider } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import moment from "moment";
const API_URL = process.env.REACT_APP_API_URL;
const PlannedInvoicedPopUp = ({
  isEdit,
  isVisible,
  onClose,
  taskData,
  onRefresh,
  jobNumber,
  selectedPInvocied,
}) => {
  console.log(selectedPInvocied, "selectedPInvocied");
  const { accounts } = useMsal();
  const account = accounts[0];
  const [loading, setLoading] = useState(false);
  const initialValues = {
    plannedDate: moment(selectedPInvocied.plannedDate).format("DD/MM/YYYY") || moment().format("DD/MM/YYYY"),
    plannedAmount: selectedPInvocied.plannedAmount || "",
    comment: selectedPInvocied.comment,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PInvoicedSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      if (!isEdit) {
        const body = {
          jobNumber: jobNumber,
          comment: values.comment,
          updatedBy: account.username,
          plannedDate: moment(values.plannedDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          plannedAmount: values.plannedAmount,
        };
        try {
          const response = await axios.post(
            `${API_URL}/api/invoice/project/invoice`,
            body
          );
          if (response.data.success) {
            onRefresh();
            onClose();
            resetForm();
          }
        } catch (error) {
          console.error(error);
          throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
          setLoading(false);
        }
      }
      if (isEdit) {
        const body = {
          invoiceID: selectedPInvocied.invoiceID,
          jobNumber: jobNumber,
          plannedDate: moment(values.plannedDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          oldPlannedDate: moment(selectedPInvocied.plannedDate).format(
            "YYYY-MM-DD"
          ),
          plannedAmount: values.plannedAmount,
          oldPlannedAmount: selectedPInvocied.plannedAmount,
          comment: values.comment,
          updatedBy: account.username,
        };
        try {
          const response = await axios.put(
            `${API_URL}/api/invoice/project/invoice`,
            body
          );
          if (response.data.success) {
            onRefresh();
            onClose();
            resetForm();
          }
        } catch (error) {
          console.error(error);
          throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  if (!isVisible) return null;

  const handleInputChange = (value) => {
    formik.setFieldValue("phaseStatus", value);
  };

  const { getFieldProps, touched, errors, handleSubmit } = formik;
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "25px",
            justifyContent: "center",
          }}
        >
          <div className={styles.MomentLogo}>MOMENT</div>
        </Box>
        {loading && (
          <div className="ProfileHolidayOverlay">
            <div
              className="ProfileHolidayResponseBox"
              style={{ borderRadius: "150px", minWidth: "160px" }}
            >
              <CircularProgress size={65} />
            </div>
          </div>
        )}
        <FormikProvider value={formik} onSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Planned Date: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <DatePicker
                  // dataName={Reviewee}
                  initialDate={formik.values.plannedDate}
                  onDatesChange={(newValue) =>
                    formik.setFieldValue(
                      "plannedDate",
                      moment(
                        newValue.startDate,
                        "YYYY-MM-DDTHH:mm:ss.000Z"
                      ).format("DD/MM/yyyy")
                    )
                  }
                  visableIcon={true}
                />
                {/* <TextField
                  disabled={isEdit}
                  fullWidth
                  value={formik.values.phaseName}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("phaseName", e?.target?.value)
                  }
                  placeholder="Phase"
                  sx={textfieldStyle}
                  error={Boolean(touched.phaseName && errors.phaseName)}
                /> */}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Planned Amt: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.plannedAmount}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("plannedAmount", e?.target?.value)
                  }
                  placeholder="Amount"
                  sx={textfieldStyle}
                  error={Boolean(touched.plannedAmount && errors.plannedAmount)}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Comment: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.comment}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("comment", e?.target?.value)
                  }
                  rows={2}
                  placeholder="Comment"
                  sx={textfieldStyle}
                  //   textareaStyle={textfieldStyle}
                  inputProps={{ style: { margin: 0 } }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <div style={{ width: "74px", height: "25px" }}>
                <button
                  className={buttonStyles.YesButtonComponentStyling}
                  type="submit"
                  //   onClick={handleSubmit}
                >
                  {!isEdit ? "Add" : "Update"}
                </button>
              </div>
              <div
                style={{ width: "74px", height: "25px", marginLeft: "10px" }}
              >
                <button
                  className={buttonStyles.NoButtonComponentStyling}
                  onClick={onClose}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "25px",
            justifyContent: "center",
          }}
        >
          <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
        </Box>
      </div>
    </div>
  );
};

export default PlannedInvoicedPopUp;

const statusOptions = [
  {
    value: "PROMOTED",
    label: "Promoted",
  },
  {
    value: "QUOTED",
    label: "Quoted",
  },
];

const PInvoicedSchema = Yup.object().shape({
  // plannedDate: Yup.string().required(),
  plannedAmount: Yup.string().required(),
});
function titleCase(string) {
  if (string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
  return 0;
}
