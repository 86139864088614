import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.css';
import MoreOptionsImg from '../../assets/MoreOptions.svg';

// define a new component
const MoreOptions = ({ onEdit, onDelete, onArchive }) => {
  const [displayMoreOptions, setDisplayMoreOptions] = useState(false);
  const containerRef = useRef(null);

  // Calculate margin-top
  const marginTop = [onEdit, onDelete, onArchive].filter(Boolean).length === 0 ? '30px' : `${23 * [onEdit, onDelete, onArchive].filter(Boolean).length}px`;

  const handleMoreOptions = () => {
    setDisplayMoreOptions(prevDisplayMoreOptions => !prevDisplayMoreOptions);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplayMoreOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <td className={styles.tdImage} ref={containerRef} onClick={handleMoreOptions}>
      <img src={MoreOptionsImg} className={styles.MoreOptions} alt='MoreOptions' />
      {displayMoreOptions && 
      <div className={styles.moreOptionsDiv} >
        {onEdit && <div onClick={onEdit} className={styles.moreOptionsDivEdit}>Edit</div>}
        {onDelete && <div onClick={onDelete} className={styles.moreOptionsDivDelete}>Delete</div>}
        {onArchive && <div onClick={onArchive} className={styles.moreOptionsDivArchive}>Archive</div>}
      </div>
      }
    </td>
  );
};

export default MoreOptions;