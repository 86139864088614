import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import stylies from './Reviews.module.css';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Table from "../../../Components/Table/TableV2";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ClickableTitle from '../../../Components/ClickableTitle/ClickableTitle.js';
import RevieweeForm from '../../../Components/ReviewForms/RevieweeForm';
import ColleagueForm from '../../../Components/ReviewForms/ColleagueForm';
import List from '../../../Components/List/List.js';
import BackBtn from '../../../assets/BackBtn.svg';
import { useNavigate } from 'react-router-dom';
import TickBox from '../../../Components/TickBox/TickBox';
import CommentBox from '../../../Components/CommentBox/CommentBoxV3';
import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';

const Reviews = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { accounts } = useMsal();
    const account = accounts[0]; 
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [viewReview, setViewReview] = useState(false);
    const [displayProfileForm, setDisplayProfileForm] = useState(false);
    const [displayColleagueForm, setDisplayColleagueForm] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [leaveType, setLeaveType] = useState("Cough, Cold, Flu");
    const [NameOfSickEmployee, setNameOfSickEmployee] = useState("");
    const [LengthOfLeave, setLengthOfLeave] = useState("Morning");
    const [comment, setComment] = useState("");
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isFormDataFetched, setIsFormDataFetched] = useState(false);
    const [data, setData]= useState(null);
    const [formData, setFormData] = useState(null);
    const [currentData, setCurrentData] = useState('11/07/2022');
    const [leaveDates, setLeaveDates] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ]);
  
    const dataName = 'dataName'
    const handleFocus = () => {
      setIsFocused(true);
    }
    const handleBlur = () => {
      if (comment === '') {
        setIsFocused(false);
      }
    }
    const [selectedValues, setSelectedValues] = useState({});
    const updateSelectedValue = (dataName) => (newValue) => {
      console.log(`Updating ${dataName} with ${newValue}`); // add this line
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [dataName]: newValue
      }));
    };
  
    const Length_of_Leave_options = [
      "Morning",
      "Afternoon"
    ]
  
    const leave_type_options = [
      "Cough, Cold, Flu",
      "Digestive/Stomach",
      "Eye Infection",
      "Headache, Migraine",
      "Non-Work Related Injury",
      "Work Related Injury",
      "Ongoing Health Issue",
      "Psychiatric, Stress",
      "Other",
    ];
    
    const handleSubmit = () => {
      setComment('')
    }
  
    useEffect(() => {
      setIsDataFetched(false);
      const fetchData = fetch(`${API_URL}/api/Profile/Reviews/PageData?userEmail=${encodeURIComponent(account.username)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      });
    
      Promise.all([fetchData])
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(([fetchedData]) => {
            setData(fetchedData)
            console.log('fetchedData: ', fetchedData)
            setIsDataFetched(true);
          });
    }, [reload]);
  
    const [formID, setFormID] = useState("");
  
    // Then when the UPDATE button is clicked, you send selectedValues to the backend
    const handleFormSubmit = async (DataTitle, selectedValues, action, information) => {
      setIsDataFetched(false);
      setDisplayProfileForm(false);
      setDisplayColleagueForm(false);
      console.log('DataTitle, selectedValues', DataTitle, selectedValues)
        try {
          const response = await fetch(`${API_URL}/api/Profile/Reviews/FormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              UserEmail: account.username, 
              Action: action,
              Data: information,
            }),
        })
        if (response.ok) {
          setReload(reload + 1);
        } 
        } catch (error) {
          console.error("Error in handleCancelationReload:", error);
        }
    };

    const SecretFormSubmit = async (DataTitle, selectedValues, action, information) => {
      console.log('DataTitle, selectedValues', DataTitle, selectedValues)
        try {
          const response = await fetch(`${API_URL}/api/Profile/Reviews/FormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              UserEmail: account.username, 
              Action: action,
              Data: information,
            }),
        })
        } catch (error) {
          console.error("Error in handleCancelationReload:", error);
        }
    };
  
    // useEffect(() => {
    //   // Create an inner async function
    //   const fetchData = async () => {
    //     let formID = 22;
    //     // 'Colleague Feedback For'
    //     // 'Employee Annual Review'
    //     let DataTitle = 'Colleague Feedback For';
    //     let email = 'james@blueengineering.co.uk';
    //     let data = {'Comment1': 'Tom is stable is his work and understands the principles behind the engineering.', 'Comment2': "Tom still struggles with the non-engineering parts of his role. I would like to discuss how this can be improved. A recent example is Harriet's End.  Could he have sorted all of this out without discussion with me? If not, what is the blocker, if so, then why get me so involved.\nHow is Tom training himself on the teaching and trainings of others? ", 'Comment3': 'All good. No concerns here.', 'Comment4': 'No comments.', 'Comment5': 'I think this is fine. I think Tom has improved recently (or Tom and Suzy are working better together). ', 'Comment6': 'He has not finished the Chartership application, I consider this to be in significant delay. ', 'Comment7': 'Tom is proactive with his work, and always works towards a solution. ', 'Comment8': "I'd like him to be more proactive in non-project specific work. He could raise more topics for the TDG and be even more vocal. I want Tom driving the new design developments at Blue. ", 'Comment9': 'Tom is very capable of communicating internally.', 'Comment10': 'Tom should practice his communication externally, I think the confidence drops and that reduces the quality of his comms. ', 'Comment11': 'I feel Tom is aligned to our culture and helps provide stability to others to help them act according to the values. ', 'Comment12': 'No comments.', 'Comment13': "I think it might be worth reviewing the Job role. I'm not sure if teaching is considered 'extra' here.  I'm rolling it all up into the previous comments, so therefore I have no comments here.", 'Comment14': 'N/A'};
    //     let action = "SUBMIT";
    //     let information = data;

    //     try {
    //       const response = await fetch(`${API_URL}/api/Profile/Reviews/FormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //           UserEmail: email, 
    //           Action: action,
    //           Data: information,
    //         }),
    //       });
    
    //       // Do something with the response, if needed
    //     } catch (error) {
    //       console.error("Error in handleCancelationReload:", error);
    //     }
    //   };
    //   fetchData();
    // }, [loading]);
    
    const handleFromRequestClick = (id, task) => {
      setLoading(true)
      
      console.log('task: ', task)
      setFormID(id)
      if (task == 'Employee Review Form') {
        console.log('Im in! Employee Review Form')
        const fetchData = fetch(`${API_URL}/api/Profile/Reviews/FormData?FormType=${encodeURIComponent('Employee Review Form')}&FormID=${encodeURIComponent(id)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
        });
      
        Promise.all([fetchData])
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(([fetchedData]) => {
            setFormData(fetchedData)
            setLoading(false)
            setDisplayProfileForm(true)
          }
        );
      } else if (task.includes('Colleague Feedback For')) {
        console.log('Im in! College Feedback For')
        const fetchData = fetch(`${API_URL}/api/Profile/Reviews/FormData?FormType=${encodeURIComponent('Colleague Feedback For')}&FormID=${encodeURIComponent(id)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
        });
      
        Promise.all([fetchData])
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(([fetchedData]) => {
            setFormData(fetchedData)
            setLoading(false)
            setDisplayColleagueForm(true)
          }
        );
      }
    }

    

    const [Comment1, setComment1] = useState('');
    const [Comment1Colour, setComment1Colour] = useState('');
    const [Comment2, setComment2] = useState('');
    const [Comment2Colour, setComment2Colour] = useState('');
    const [Comment3, setComment3] = useState('');
    const [Comment3Colour, setComment3Colour] = useState('');
    const [PatternYesTick, setPatternYesTick] = useState(false);
    const [PatternNoTick, setPatternNoTick] = useState(false);
    const [ViewFromData, setViewFromData] = useState("");
    const handleViewFromClick = (id, task) => {
      setIsDataFetched(false);
      
      console.log('task: ', task)

      if (task == 'Employee Review Form') {
        console.log('Im in! Employee Review Form')
      } else if (task.includes('College Feedback For')) {
        console.log('Im in! College Feedback For')
      }

      setFormID(id)
      const fetchData = fetch(`${API_URL}/api/Profile/Reviews/ViewFormData?FormID=${encodeURIComponent(id)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      });
      
      Promise.all([fetchData])
        .then(responses => Promise.all(responses.map(response => response.json())))
        .then(([fetchedData]) => {
          setViewFromData(fetchedData)
          console.log('HERE!!!!!!!! :', ViewFromData.FirstBox)
          setViewReview(true);
          if (fetchedData.reviewee_form_data.Q13 == 'YES') {
            setPatternYesTick(true)
          }
          if (fetchedData.reviewee_form_data.Q13 == 'NO') {
            setPatternNoTick(true)
          }
        }
      );
    }
  
  useEffect(() => {
    console.log('loading: ', loading)
  }, [loading]);

  const headerData = {
    headers: ['Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
  };

  const OpenSicknessTableRef = useRef(null);
  const DocumentTableRef = useRef(null);

  const [OpenSicknessMaxTableWidth, setOpenSicknessMaxTableWidth] = useState(0);
  const [DocumentTableMaxWidth, setDocumentMaxTableWidth] = useState(0);

  useEffect(() => {
    if (OpenSicknessTableRef.current) {
      setOpenSicknessMaxTableWidth(OpenSicknessTableRef.current.offsetWidth);
    }
  }, [OpenSicknessTableRef.current]);

  useEffect(() => {
    if (DocumentTableRef.current) {
      setDocumentMaxTableWidth(DocumentTableRef.current.offsetWidth);
    }
  }, [DocumentTableRef.current]);










  function QuestionAndAnswer({ number, question, answer }) {
    const { GrayNum, QandA, GrayQuestion, BlackAnswer } = stylies;
  
    return (
      <div className={stylies.NumAndQandA}>
        <div className={GrayNum}>
          {number}
        </div>
        <div className={QandA}>
          <div className={GrayQuestion}>
            {question}
          </div>
          <div className={BlackAnswer}>
            {answer}
          </div>
        </div>
      </div>
    );
  }

  const navigate = useNavigate();
  const handleBack = () => {
    window.location.reload();
  };
  
  const overviewPerson = {
    DataEditTitle: 'Overview',
    DataEdit: [
      { editType: 'NonEditable', dataName: 'Title', data: 'title'},
      { editType: 'NonEditable', dataName: 'Employee Name', data: 'Nathan Brightman' },
      { editType: 'NonEditable', dataName: 'Type', data: 'employee_type'},
    ]
  };

  let content;

  if (isDataFetched) {
    content = (
      <div className={stylies.mainContent}>
        <div className={stylies.TopSection}>
          <div className={stylies.OpenSicknessSection}>
            <div className={stylies.title}>Review Forms & Feedback</div>

            <div className={stylies.OpenSicknessTable} ref={OpenSicknessTableRef}>
              <Table data={data?.ReviewFormsAndFeedbackProfileData} indexPassed={true} handleButtonClick={handleFromRequestClick} Resizable={false} MaxWidthTable={OpenSicknessMaxTableWidth}/>
            </div>
          </div>

          <div className={stylies.OpenSicknessSection}>
            <div className={stylies.title}>Current KPIs</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              {
              data?.KPIs !== "" && data?.KPIs !== null ? 
              data?.KPIs
              :   
              <div className={stylies.tdNoInfo}>No current KPIs.</div>
                
            }
            </div>
          </div>
        </div>

        <div className={stylies.ReviewLogSection}>
          <div className={stylies.title}>Review Log</div>

          <div className={stylies.ReviewLogTable} ref={OpenSicknessTableRef}>
            <Table data={data?.ReviewLog} indexPassed={true} handleButtonClick={handleViewFromClick} Resizable={false} MaxWidthTable={OpenSicknessMaxTableWidth}/>
          </div>
        </div>

        {loading ? // Here is the code for displaying error/success messages
        ( 
          <div className={stylies.LoadOverlay}>
            <Box sx={{ display: 'flex'}}>
              <CircularProgress size={65} />
            </Box>
          </div> 
        ) : displayProfileForm ? (
          displayProfileForm && <RevieweeForm data={formData} handleFormSubmit={handleFormSubmit} secretFormSubmit={SecretFormSubmit} setDisplayProfileForm={setDisplayProfileForm} actionType={'SUBMIT'} />
        ) : displayColleagueForm ? (
          displayColleagueForm && <ColleagueForm data={formData} handleFormSubmit={handleFormSubmit} secretFormSubmit={SecretFormSubmit} setDisplayProfileForm={setDisplayColleagueForm} actionType={'SUBMIT'} />
        ) : null
        }
        
      </div>
    );
  } else if (viewReview) {
    content = (
      <div className={stylies.mainContent}>
        <div className={stylies.RowFlexSection} style={{justifyContent: 'space-between'}}>
          <div className='BackBtn' onClick={handleBack}>
            <img src={BackBtn} alt='Back' />
          </div>
        </div>

        <div className={stylies.title}>Annual Performace Review</div>
        <div className={stylies.ThreeSmallBoxesSection}>
          <List data={ViewFromData?.FirstBox} editable={false} />
          <List data={ViewFromData?.SecondBox} editable={false} />
          <List data={ViewFromData?.ThirdBox} editable={false} />
        </div>

        <div className={stylies.KPIsActionsNotes} style={{marginBottom: '25px'}}>
          <div className={stylies.KPIsActionsNotesBoxes}>
            <div className={stylies.title}>KPIs</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              {ViewFromData?.KPIs}
            </div>
          </div>
          <div className={stylies.KPIsActionsNotesBoxes}>
            <div className={stylies.title}>Actions</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              {ViewFromData?.Actions}
            </div>
          </div>
          <div className={stylies.KPIsActionsNotesBoxes} style={{marginRight: '0px'}}>
            <div className={stylies.title}>Notes</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              {ViewFromData?.Notes}
            </div>
          </div>
        </div>

        <ClickableTitle titleName='Employee Review Form'  
          section= {
            <div className={stylies.infoSector}>
              <div className={stylies.QuestionSection}>
                How are you doing?
              </div>

              <QuestionAndAnswer         
                number="1."
                question="What targets were you set in your last review? Write your targets in full and comment either how you have met them, or what you need to do to meet them."
                answer={ViewFromData?.reviewee_form_data.Q1}
              />

              <QuestionAndAnswer         
                number="2."
                question="What have you been up to since your last review? Have your responsibilities changed? Which skills have you developed/consolidated?"
                answer={ViewFromData?.reviewee_form_data.Q2}
              />

              <QuestionAndAnswer         
                number="3."
                question="How have you found the difficulty of the work you have been undertaking? Would you like to change it?"
                answer={ViewFromData?.reviewee_form_data.Q3}
              />

              <QuestionAndAnswer         
                number="4."
                question="Do you have an Internal Project, if so what is it? How is it going? What is the next milestone?"
                answer={ViewFromData?.reviewee_form_data.Q4}
              />
              
              <div className={stylies.QuestionSection}>
                How are you progressing?
              </div>

              <QuestionAndAnswer         
                number="5."
                question="What are you aiming to achieve in the next 3 months? What do you need to do/learn in order to achieve it?"
                answer={ViewFromData?.reviewee_form_data.Q5}
              />

              <QuestionAndAnswer         
                number="6."
                question="What can Blue do more of to help you progress your career? What would you like to learn next?"
                answer={ViewFromData?.reviewee_form_data.Q6}
              />

              <QuestionAndAnswer         
                number="7."
                question="How is your IPD/Uni/College course going? When are you taking your next professional qualification? If you are not at Uni, how are you progressing your skills?"
                answer={ViewFromData?.reviewee_form_data.Q7}
              />
                            
              <div className={stylies.QuestionSection}>
                How are we doing? 
              </div>

              <QuestionAndAnswer         
                number="8."
                question="How do you feel you are managed? Is it too loose, micromanaged, not enough clarity, target too hard etc."
                answer={ViewFromData?.reviewee_form_data.Q8}
              />

              <QuestionAndAnswer         
                number="9."
                question="How do you find the communication within your team? What do you like? What would you change?"
                answer={ViewFromData?.reviewee_form_data.Q9}
              />

              <QuestionAndAnswer         
                number="10."
                question="How do you find the communication within Blue? Do you know about the direction of the company? Are roll outs of new process and systems clear and understandable?"
                answer={ViewFromData?.reviewee_form_data.Q10}
              />

              <div className={stylies.QuestionSection}>
                Level
              </div>

              <div className={stylies.NumAndQandA}>
                <div className={stylies.GrayNum}>
                  {"11."}
                </div>
                <div className={stylies.QandA}>
                  <div className={stylies.GrayQuestion}>
                    {"Would you like your level reviewed? Why?"}
                  </div>
                  <div className={stylies.YesNoTicks} style={{marginBottom: '10px'}}>
                    <TickBox type={PatternYesTick} usable={false} />
                    <div className={stylies.GrayNum} style={{marginLeft: '10px', marginRight: '10px', width: '20px'}}>Yes</div>
                    <TickBox type={PatternNoTick} usable={false} />
                    <div className={stylies.GrayNum} style={{marginLeft: '10px', marginRight: '10px', width: '20px'}}>No</div>
                  </div>
                  <div className={stylies.BlackAnswer}>
                    {ViewFromData?.reviewee_form_data.Q11}
                  </div>
                </div>
              </div>

              <QuestionAndAnswer         
                number="12."
                question="Is there anything else you would like to discuss?"
                answer={ViewFromData?.reviewee_form_data.Q12}
              />
            </div>
          }
        />

        <ClickableTitle titleName='Colleague Feedback Form'  
          section= {
            <div className={stylies.infoSector}>
              <div className={stylies.QuestionSection}>
                Job Knowledge and Ability
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q1.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q2.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>



              <div className={stylies.QuestionSection}>
                Quality of Work
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q3.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q4.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Timekeeping & Deadlines
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q5.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q6.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Attitude to Work
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q7.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q8.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Interaction with Others
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q9.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q10.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
              Culture & Values
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q11.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q12.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={stylies.QuestionSection}>
              Extra Responsibilities Within Blue/Team
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q13.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q14.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  } else {
    content = (
      <Box sx={{display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center'}}>
        <CircularProgress size={65}/>
      </Box>
    );
  }
  
  return content;
};

export default Reviews;