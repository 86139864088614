import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import Checkbox from "@mui/material/Checkbox";
import MoreOptionsV2 from "../../Components/MoreOptions/MoreOptionsV2.js";
import { TextField, Select, MenuItem } from "@mui/material";
import DropDownV2 from "../../Components/DropDownBox/DropdownV2";
import textfieldStyle from "../../GlobalStyles/styles";
import "./invoice.css";
import { useSelector, useDispatch } from "../../redux/store";

const HourlyRateTable = ({
  jsonData,
  headerMap = {},
  clickableRowAdd = false,
  handleRowEdit,
  setRowData,
  rowData,
  disabled = false,
}) => {
  const { isSubmitting, raiseInvoiceHourlyRates } = useSelector(
    (state) => state.raiseInvoice
  );
  const [selectAll, setSelectAll] = useState(false);
  // const [rowData, setRowData] = useState([]);
  useEffect(() => {
    if (raiseInvoiceHourlyRates?.length > 0) {
      const data = raiseInvoiceHourlyRates;
      setRowData(data);
    }
  }, [raiseInvoiceHourlyRates]);

  const resolvedHeaderMap = headerMap;
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  const handleRowUpdate = (key, comingRow, value) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        if (item.hourlyRateID === comingRow.hourlyRateID) {
          return { ...item, [key]: value };
        } else {
          return item;
        }
      })
    );
  };

  const handleSelectAll = (selectAll) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        return { ...item, isSelected: selectAll };
      })
    );
    setSelectAll(selectAll);
  };

  const calculateGross = (row) => {
    return row.invAmount
      ? row.invAmount * (row.vat / 100) + parseFloat(row.invAmount)
      : 0;
  };

  const calculateQuantity = () => {
    return rowData
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return parseFloat(acc) + parseFloat(cur.invoicedQuantity);
      }, 0);
  };

  const calculateRowNetTotal = (row) => {
    return parseFloat(row.invoicedQuantity) * parseFloat(row.chargeRate);
  };

  const calculateNetTotal = () => {
    return rowData
      .filter((data) => data.isSelected)
      .reduce((acc, cur) => {
        return acc + calculateRowNetTotal(cur);
      }, 0);
  };

  const rowClasses = `${styles.tableRow} ${
    clickableRowAdd ? styles.rowHoverHighlight : ""
  }`;

  return (
    <div
      className={styles.tableContainer}
      style={{
        maxHeight: "300px",
        height: "300px",
        overflowY: "auto",
        overflowX: "hidden",
        background: "inherit",
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";

              if (index === 0) {
                return (
                  <th className={styles.th} colspan="1">
                    <Checkbox
                    disabled={disabled}
                      checked={selectAll}
                      style={{ pl: 20 }}
                      onChange={(e) => handleSelectAll(!selectAll)}
                    />
                  </th>
                );
              }
              if (
                index == 2 ||
                index == 4 ||
                index == 3 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                      //   onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rowData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              style={{ background: "white" }}
            >
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "3%" }}
                className={styles.td}
              >
                <div>
                  <Checkbox
                   disabled={disabled}
                    checked={row.isSelected}
                    style={{ pl: 20 }}
                    onChange={(e) =>
                      handleRowUpdate("isSelected", row, !row.isSelected)
                    }
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "7%" }}
                className={styles.td}
              >
                <div>{row.fullName}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "2%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.entryDate}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "20%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  <TextField
                   disabled={disabled}
                    value={row.invoicedQuantity ? row.invoicedQuantity : ""}
                    type="number"
                    onChange={(e) =>
                      handleRowUpdate("invoicedQuantity", row, e.target.value)
                    }
                    placeholder=" Inv Amount"
                    sx={textfieldStyle}
                    error={Boolean(isSubmitting && !row.invoicedQuantity)}
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "8%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.chargeRate}</div>
              </td>

              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "14%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>
                  {calculateRowNetTotal(row) ? calculateRowNetTotal(row) : 0}
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "50%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.comment}</div>
              </td>
            </tr>
          ))}

          <tr className={styles.tableBottom} style={{ background: "#EAF2F9" }}>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div className={styles.RightAlign} style={{ marginTop: "10px" }}>
                Total:
              </div>
            </td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {calculateQuantity()}
              </div>
            </td>
            <td className={styles.td}></td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {calculateNetTotal()}
              </div>
            </td>

            <td className={styles.td}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HourlyRateTable;
