import React, {useState } from 'react';
import styles from './ClickableTitle.module.css';


function ClickableTitle({titleName, section}) {
  const [isSectionVisible, setSectionVisible] = useState(false);

  const toggleSection = () => {
    setSectionVisible(!isSectionVisible);
  }
  return (
    <div>
      <div className={styles.titles} onClick={toggleSection}>
        <div className={styles.positionArrow} style={{marginTop: isSectionVisible ? '17px' : '19px'}}>
          <div className={styles.arrow} style={{transform: isSectionVisible ? 'translateY(-50%) rotate(45deg)' : 'translateY(-50%) rotate(-45deg)'}}></div>
        </div>
        {titleName}
      </div>
      { isSectionVisible && section}
    </div>
  );
}

export default ClickableTitle;