import styles from './HorizontalButtons.module.css';

function HorizontalButtons({ options, selectedValue, onValueChange }) {
  return (
    <>
      {options.map(option => (
        <button
          key={option}
          className={`${styles.btn} ${selectedValue === option ? styles.selected : ''}`}
          onClick={() => onValueChange(option)}
        >
          {option}
        </button>
      ))}
    </>
  );
}

export default HorizontalButtons;