import React from 'react';
import styles from './Topbar.module.css';
import { NavLink, useLocation } from 'react-router-dom';

function TopbarItem({ val }) {
  const isDisabled = val.disabled;
  const isLine = val.isline
  const location = useLocation();

  return (
    <li>
      <NavLink
        to={isDisabled ? '#' : val.link}
        className={`${styles.row} ${isDisabled ? styles.disabled : ''} ${
          !isDisabled && location.pathname.startsWith(val.link) ? styles.ClickedOn : ''
        }`}
      >
        <div id="title" className={`${isDisabled ? styles.disabled : ''}`}>
          {val.title}
        </div>
      </NavLink>
    </li>
  );
}


function Topbar({TopbarData}) {
  return (
    <div className={styles.Topbar}>
      <div className={styles.TopbarInner}>
        <div className={styles.ButtonsWrapper}>
          <ul className={styles.TopbarList}>
            {TopbarData.map((val, key) => (
              <TopbarItem key={key} val={val} />
            ))}
          </ul>
          <div className={styles.line}></div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;

