import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';

// Redux
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

// Example usage
const formattedDate = formatDate("Mon Mar 04 2024 00:00:00 GMT+0000 (Greenwich Mean Time)");
console.log(formattedDate); // Outputs: "04-03-2024"

// ----------------------------------------------------------------------

let initialState = {
  configuration: {},
  isConfigLoading: false,

  showSettingsSuccess: false,
  error: '',
};

export const settingsSlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {

    setConfiguration(state, action) {
      state.configuration = action.payload;
      state.isNewOppLoading = false;
      state.isConfigLoading = false;
    },

    startConfigurationLoading(state) {
      state.isConfigLoading = true;
    },

    showSettingsSuccess(state, action) {
      state.showSettingsSuccess = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isConfigLoading = false;
      state.error = action.payload;
    },

  },

});

export const {
  setinputValue,
  setCurrentPage,
  setLastStatus,
  setSortColumn,
  setSortDirection,
  setSearchTerm,
  setStatusFilter,
  hasError,
} = settingsSlice.actions;

export function getConfiguration() {
  const API_URL = process.env.REACT_APP_API_URL;
  return async () => {
    dispatch(settingsSlice.actions.startConfigurationLoading());
    try {
      const response = await axios.get(`${API_URL}/api/settings/stdtemplate/configuration`);
      dispatch(settingsSlice.actions.setConfiguration(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(settingsSlice.actions.hasError(error));
    }
  };
}

export function updateSTDTemplateResponsibilities(formData) {
  const API_URL = process.env.REACT_APP_API_URL;

  return async () => {
    try {
      const response = await axios.post(`${API_URL}/api/settings/stdtemplate/update`,
        formData, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      dispatch(settingsSlice.actions.showSettingsSuccess(true));
      dispatch(getConfiguration());

      return response;
    } catch (error) {
      console.error(error);
      dispatch(settingsSlice.actions.hasError(error.response.data.message));
      dispatch(settingsSlice.actions.showSettingsSuccess(false));
    }
  };
}

export default settingsSlice.reducer;