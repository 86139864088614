import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import Checkbox from "@mui/material/Checkbox";
import MoreOptionsV2 from "../../Components/MoreOptions/MoreOptionsV2.js";
import { TextField, Select, MenuItem } from "@mui/material";
import DropDownV2 from "../../Components/DropDownBox/DropdownV2";
import textfieldStyle from "../../GlobalStyles/styles";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import "./invoice.css";
import { useSelector, useDispatch } from "../../redux/store";

const AssignedBillingTable = ({
  jsonData,
  headerMap = {},
  clickableRowAdd = false,
  handleRowEdit,
  setRowData,
  rowData,
  disabled=false,
}) => {
  const { isSubmitting, raiseInvoiceAssignedBilling } = useSelector(
    (state) => state.raiseInvoice
  );
  const [selectAll, setSelectAll] = useState(false);
  // const [rowData, setRowData] = useState([]);
  useEffect(() => {

    if (raiseInvoiceAssignedBilling.length > 0) {
      const data = raiseInvoiceAssignedBilling
      setRowData(data);
    }
  }, [raiseInvoiceAssignedBilling]);

  const resolvedHeaderMap = headerMap;
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  const handleRowUpdate = (key, comingRow, value) => {
    const updatedRows = [...rowData];
    setRowData(
      updatedRows.map((item) => {
        if (item.plannedInvoiceID === comingRow.plannedInvoiceID) {
          return { ...item, [key]: value };
        } else {
          return { ...item, [key]: false };;
        }
      })
    );
  };

  const rowClasses = `${styles.tableRow} ${clickableRowAdd ? styles.rowHoverHighlight : ""
    }`;

  return (
    <div
      className={styles.tableContainer}
      style={{
        maxHeight: "300px",
        height: "300px",
        overflowY: "auto",
        overflowX: "hidden",
        background: "inherit",
      }}
    >
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";

              if (index === 0) {
                return <th className={styles.th} colspan="1"></th>;
              }
              if (
                index == 2 ||
                index == 4 ||
                index == 3 ||
                index == 4 ||
                index == 5 ||
                index == 6 ||
                index == 7
              ) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                    //   onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rowData?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              style={{ background: "white" }}
            >
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "3%" }}
                className={styles.td}
              >
                <div>
                  <Checkbox
                   disabled={disabled}
                    checked={row.isSelected}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleOutlineIcon />}
                    style={{ pl: 20 }}
                    onChange={(e) =>
                      handleRowUpdate("isSelected", row, !row.isSelected)
                    }
                  />
                </div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "7%" }}
                className={styles.td}
              >
                <div>{row.plannedDate}</div>
              </td>

              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "14%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>£{row.plannedAmount}</div>
              </td>
              <td
                // key={cellIndex}
                style={{ fontFamily: "'PT Sans', sans-serif", width: "50%" }}
                className={styles.td}
              >
                <div className={styles.CenterContent}>{row.comment}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssignedBillingTable;
