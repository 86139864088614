import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from '../ResizableTh';
import { Link } from 'react-router-dom';
import Tick from "../../assets/Tick.svg";
import { useSelector, useDispatch } from '../../redux/store';
import { setSelectedRow } from '../../redux/slices/rowhighlight';

function InvListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 40, // Job No.
    1: 120, // Project Address
    2: 120, // Bill Payer
    3: 50, // Team
    4: 50, // Status
    5: 40, // Type
    6: 50, // Plan Amt
    7: 70, // Plan Date
    8: 50, // Inv. Amt
    9: 70, // Inv. Date
    10: 70, // Due Date
    11: 60, // Inv. No.
    12: 50, // Paid
    13: 120, // Comment
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const selectedRow = useSelector((state) => state.rowhighlight.selectedRow);

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      const totalWidth = Object.values(prev).reduce((a, b) => a + b, 0);
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;

      if (prev[index] !== width && totalWidth - prev[index] + width <= tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        return newWidths;
      }

      if (totalWidth - prev[index] + width > tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        const excessWidth = totalWidth - prev[index] + width - tableWidth;
        const otherColumns = Object.keys(newWidths).filter((key) => key !== index);
        let remainingExcessWidth = excessWidth;

        const shrinkLeft = index > 0 && index < otherColumns.length;
        const sortedColumns = shrinkLeft ? otherColumns.sort((a, b) => a - b) : otherColumns.sort((a, b) => b - a);

        for (const column of sortedColumns) {
          if (newWidths[column] > 50 + remainingExcessWidth / otherColumns.length) {
            newWidths[column] -= remainingExcessWidth / otherColumns.length;
            remainingExcessWidth -= remainingExcessWidth / otherColumns.length;
          } else {
            remainingExcessWidth -= newWidths[column] - 50;
            newWidths[column] = 50;
          }
        }

        return newWidths;
      }

      return prev;
    });
  }, []);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem('scrollPos');
    if (scrollPos && tableRef.current) {
      tableRef.current.scrollTop = parseFloat(scrollPos);
    }
    console.log(props.rows);
  }, []);

  const handleRowClick = (row) => {
    if (row.jobno && row.invno && row.status) {
      navigate(`../${"raise-invoice"}?jobNumber=${row.jobno
        }&invoiceNumber=${row.invno}&status=${row.status}`);
      // window.location.href = `${"raise-invoice"}?jobNumber=${
      //   row.jobno
      // }&invoiceNumber=${row.invno}&status=${row.status}`;
    }
  };

  const uniqueRows = React.useMemo(() => {
    const invNumSet = new Set();
    return props.rows.filter(row => {
      console.log(props.rows)
      if (invNumSet.has(row.id)) {
        return false;
      } else {
        invNumSet.add(row.id);
        return true;
      }
    });
  }, [props.rows]);

  return (
    <div className='ProjectListBox'>
      <div id="invTableContainer" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {['Job No', 'Project Address', 'Bill Payer', 'Team', 'Status', 'Type', 'Plan Amt', 'Plan Date', 'Inv Amt', 'Inv Date', 'Due Date', 'Inv No', 'Paid', 'Comment'].map((header, index) => (
                <ResizableTh onResize={(width) => setColumnWidth(index, width)} index={index} key={index}>
                  <span
                    onClick={props.loading ? null : () =>
                      props.handleSort(
                        header.toLowerCase().replace(' ', '').replace('.', '') // Continue using existing logic for other headers
                      )
                    }
                    className={props.loading ? 'disable-click' : ''}>
                    {header}
                    {props.sortColumn === (header.toLowerCase().replace(' ', '').replace('.', '')) && (
                      props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                    )}
                  </span>
                </ResizableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.loading ? (
              [...Array(50)].map((_, index) => (
                <tr key={index}>
                  {[...Array(14)].map((_, cellIndex) => (
                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] }}>
                      <div className="skeleton" style={{ height: '13px' }}></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              uniqueRows.map((row) => (
                <tr
                  key={row.id}
                  onMouseEnter={() => setHoveredRow(row.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onClick={() => {
                    handleRowClick(row);
                    dispatch(setSelectedRow(row.id));
                    if (tableRef.current) {
                      localStorage.setItem('scrollPos', tableRef.current.scrollTop);
                    }
                  }}
                  className={`
                  ${hoveredRow === row.id && selectedRow !== row.id ? 'row-hover' : ''}
                  ${selectedRow === row.id ? 'row-selected' : ''}
                `}
                >
                  <td style={{ width: columnWidths[0], maxWidth: '100px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '40px', textAlign: 'center' }}>{row.jobno}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[1], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[1]}px`, maxWidth: '200px' }}>{row.projectaddress}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[2], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[2]}px` }}>{row.billpayer}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[3], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[3]}`, maxWidth: '70px' }}>{row.team}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[4], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[4]}`, maxWidth: '70px' }}>{row.status.toUpperCase()}</div>
                    </Link>
                  </td>


                  <td style={{ width: columnWidths[5], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: columnWidths[5], maxWidth: '70px' }}>{row.type.toUpperCase()}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[6], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: columnWidths[6], maxWidth: '70px' }}>£{row.planamt}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[7], maxWidth: '100px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[7], maxWidth: '80px' }}>{row.plandate}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[8], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: columnWidths[8], maxWidth: '70px' }}>£{row.invamt}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[9], maxWidth: '80px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[9], maxWidth: '80px' }}>{row.invdate}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[10], maxWidth: '80px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '70px', width: columnWidths[10], maxWidth: '80px' }}>{row.duedate}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[11], maxWidth: '50px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '30px', width: columnWidths[11], maxWidth: '50px' }}>{row.invno}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[12], maxWidth: '40px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '30px', width: columnWidths[8], maxWidth: '40px' }}>{row.paid === true ? <img src={Tick} alt="Tick" /> : null}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[13], maxWidth: '700px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: columnWidths[13], maxWidth: '700px' }}>{row.comment}</div>
                    </Link>
                  </td>

                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvListTable;