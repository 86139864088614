import React, { useState, useRef } from 'react';
import './UploadPopUp.css';
import CommentBox from '../CommentBox/CommentBox';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';

import DownloadIcon from '../../assets/Download.svg';

const UploadModal = ({ show, onClose, onUpload, onCommentChange, onSubmit }) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = [...e.dataTransfer.files];
        setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
        // Update the parent component
        if (onUpload) {
            onUpload([...files, ...droppedFiles]);
        }
    };

    const handleChange = (e) => {
        const selectedFiles = [...e.target.files];
        setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        // Update the parent component
        if (onUpload) {
            onUpload([...files, ...selectedFiles]);
        }
    };

    const handleRemoveFile = (index) => {
        // Correctly use setFiles with a callback function that has access to the current state
        setFiles(currentFiles => {
            // Create a new array without the file at the given index
            const updatedFiles = currentFiles.filter((_, i) => i !== index);
            // Update the parent component
            if (onUpload) {
                onUpload(updatedFiles);
            }
            return updatedFiles;
        });
    };

    if (!show) {
        return null;
    }

    return (
        <div className="commentModal-overlay">
            <div className="commentModal" onClick={e => e.stopPropagation()}>
                <div className="commentModal-dropSection"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={handleButtonClick}>
                    {files.length > 0 ? (
                        <div className="file-list-container">
                            <ul>
                                {files.map((file, index) => (
                                    <li key={index} className='commentModal-txt'>
                                        {file.name}
                                        <span
                                            className="remove-file-icon"
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent opening the file upload dialogue
                                                handleRemoveFile(index);
                                            }}
                                        >
                                            x
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <>
                            <p className='commentModal-txt'>Drop Files Here</p>
                            <img src={DownloadIcon} alt="Download" />
                        </>
                    )}
                    <input
                        type="file"
                        multiple
                        onChange={handleChange}
                        style={{ display: 'none' }} // Hide the file input
                        ref={fileInputRef}
                    />
                </div>
                <div className="commentModal-commentSection">
                    <CommentBox placeholder='Comment...' onValueChange={(newComment) => onCommentChange(newComment)}></CommentBox>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '25px' }}>
                    <div style={{ width: '74px', height: '25px' }}>
                        <button className={buttonStyles.YesButtonComponentStyling}
                            onClick={() => {
                                onSubmit();
                                setFiles([]);
                            }}>
                            SUBMIT
                        </button>
                    </div>
                    <div style={{ width: '74px', height: '25px', marginLeft: '10px' }}>
                        <button className={buttonStyles.NoButtonComponentStyling} onClick={onClose}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadModal;
