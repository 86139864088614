import React from 'react';

function ResizableTh(props) {
  const { onResize, children } = props;
  const ref = React.useRef(null);

  const startResize = (event) => {
    document.documentElement.addEventListener('mousemove', doResize);
    document.documentElement.addEventListener('mouseup', stopResize);
  };

  const doResize = (event) => {
    const rect = ref.current.getBoundingClientRect();
    console.log(rect.left)
    console.log(rect.right)
    const newWidth = event.clientX - rect.left - ((rect.right - rect.left) / 5);
    if (newWidth > 0) {
      onResize(newWidth);
    }
  };

  const stopResize = () => {
    document.documentElement.removeEventListener('mousemove', doResize);
    document.documentElement.removeEventListener('mouseup', stopResize);
  };

  return (
    <th className="th" ref={ref} style={{ padding: '8px' }}>
      <div className="th-content" style={{ maxWidth: '100%' }}>
        {children}
      </div>
      <div className="resizer" onMouseDown={startResize}>
        <div className="resizerBoxcolor"></div>
      </div>
    </th>
  );
}

export default ResizableTh;
