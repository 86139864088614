import React from 'react';
import styles from './SliderSwitch.module.css';

function SliderSwitch(props) {
  const handleSwitchChange = (event) => {
    if (!props.disabled) {
      props.onToggle(event.target.checked);
    }
  };

  return (
    <label className={`${styles.SliderSwitch} ${props.disabled ? styles.disabled : ''}`}>
      <input type="checkbox" checked={props.checked} onChange={handleSwitchChange} disabled={props.disabled} />
      <span className={styles.Slider}></span>
    </label>
  );
}

export default SliderSwitch;
