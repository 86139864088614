import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProjectListTable from './EmpInfoListTable';
import ProjectListSearch from './EmpInfoListSearch';
import ProjectTeamFilter from './EmpInfoTeamFilter';
import ProjectStatusFilter from './EmpInfoStatusFilter';
import ProjectTablePagination from './EmpInfoTablePagination';
import { CircularProgress } from '@mui/material';
import styles from './EmpInfo.module.css';
import { Outlet, useLocation } from 'react-router-dom';
import SearchBar from '../../../Components/SearchBar/SearchBar';

function EmployeeInfo() {
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('CURRENT');
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);

  const [issueDateDbUpdate, setIssueDateDbUpdate] = useState([]);
  const [completedIssueDateDbUpdate, setCompletedIssueDateDbUpdate] = useState([]);

  useEffect(() => {
    fetchData(searchTerm, currentPage === 1);
  }, [searchTerm, teamFilter, statusFilter, sortColumn, sortDirection, currentPage, rowsPerPage]);

  const fetchData = async (
    searchTerm = '',
    resetPage = false
  ) => {
    setLoading(true);
    setPaginationLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/api/Admin/EmployeeInfo/EmployeeList?page=${currentPage}&rowsPerPage=${rowsPerPage}&searchTerm=${searchTerm}&teamFilter=${teamFilter}&statusFilter=${statusFilter}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
  
      const data = response.data;
      
      console.log(data)

      setAllRows(data.rows);
      setTotalRows(data.totalRows);

      if (resetPage) {
        setCurrentPage(1);
      }
    } catch (error) {
      console.error(error);
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } finally {
      setLoading(false);
      setPaginationLoading(false);
    }
  };
  
  const handleSearch = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSearchTerm(inputValue);
    setCurrentPage(1);
  };

  const handleTeamFilter = (team) => {
    setTeamFilter(team.toLowerCase());
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    let filteredRows = allRows.filter((row) =>
      Object.values(row).some((value) =>
        value ? value.toString().toLowerCase().includes(searchTerm.toLowerCase()) : false
      )
    ).filter((row) => 
      teamFilter === '' || (row.team && row.team.toLowerCase().includes(teamFilter.toLowerCase()))
    ).filter((row) =>
      statusFilter === '' || row.status === statusFilter
    );

    let sortedRows = [...filteredRows].sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    });

    setRows(sortedRows);
  }, [allRows, sortColumn, sortDirection, searchTerm, teamFilter, statusFilter]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      setCurrentPage(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      setCurrentPage(currentPage + 1);
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };
  
  
  const totalPages = totalRows > 0 ? Math.ceil(totalRows / rowsPerPage) : 0;
  const currentPageValue = totalRows > 0 ? currentPage : 0;

  // const data2 = [{client: "McAdam", comment: "Partner", fee: 'CURRENT', feeburn: '30 Years, 10 Months', id: 2, invoice: '12 Days', jobno: 27, nextdeadline: "Team Fields", projectaddress: "Suzy", promoted: "", runner: "James Nevin", status: "12", team: "Alpha 2"}]
  // const data = [{Ref: 27, FirstName: "Suzy", LastName: 'McAdam', Level: 12, JobTitle: 'Partner', LineManager: 'James Nevin', Team: "Team Fields", Age: '30 Years, 10 Months', RemainingHoliday: "12 Days", Status: "CURRENT"}]
  
  const location = useLocation();

  return (
    <div>            
      {location.pathname === "/Admin/EmployeeInfo" && (
        <div className={styles.projectContent}>
          <div className={styles.Projecttitle}>Employee List</div>
          <div className={styles.ProjectTableFilters}>
            <div className={styles.ProjectTeamFilter}>
              <label className={styles.FilterLabel} htmlFor='teamFilter' style={{ fontSize: '12px' }}>Team:</label>
              <ProjectTeamFilter id='teamFilter' onSelect={handleTeamFilter} disabled={loading} />
            </div>
            <div className={styles.ProjectStatusFilter}>
              <label className={styles.FilterLabel} htmlFor='statusFilter' style={{ fontSize: '12px' }}>Status:</label>
              <ProjectStatusFilter id='statusFilter' onSelect={handleStatusFilter} disabled={loading} />
            </div>
            <div className={styles.ProjectListSearch}>
              <SearchBar onChange={handleSearch} onKeyDown={handleKeyDown} disabled={loading} onClick={handleSearchSubmit} />
              {/* <button onClick={handleSearchSubmit} disabled={loading} className={loading ? 'disabled' : ''}>Search</button> */}
            </div>
          </div>

          {loading ? (
            <>

              <div className={styles.ProjectListBox}>
                <ProjectListTable
                  rows={[]} // Send empty rows while loading
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                  loading={loading} // Send loading status to ProjectListTable
                />
              </div>

              <div className={styles.ProjectPagination}>
                <ProjectTablePagination
                  currentPage={currentPageValue}
                  totalPages={totalPages}
                  onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
                  onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
                  rowsShown={allRows.length}
                  totalRows={totalRows}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            </>
          ) : (
            <>
              <div className={styles.ProjectListBox}>
                <ProjectListTable
                  rows={allRows}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  onSort={handleSort}
                  loading={loading} // Send loading status to ProjectListTable
                />
              </div>

              {allRows.length === 0 && <div className={styles.NoProjects}>THE PERSON YOU HAVE SEARCHED CANNOT BE FOUND</div>}
              
              <div className={styles.ProjectPagination}>
                <ProjectTablePagination
                  currentPage={currentPageValue}
                  totalPages={totalPages}
                  onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
                  onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
                  rowsShown={allRows.length}
                  totalRows={totalRows}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            </>
          )}
        </div>
      )}
      <Outlet />
    </div>
  );
}

export default EmployeeInfo;