import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import DropDown from "../../Components/DropDownBox/DropdownV2";
import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import styles from "../../Components/FeePropFeePopUp/FeePropFeePopUp.module.css";
import buttonStyles from "../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import { useDispatch, useSelector } from "../../redux/store";
import BlueLogo from "../../assets/BlueLogo.png";
import { useFormik } from "formik";
import { Form, FormikProvider } from "formik";
import * as Yup from "yup";
import textfieldStyle from "../../GlobalStyles/styles";
import { VariantType, useSnackbar } from "notistack";
const API_URL = process.env.REACT_APP_API_URL;
const PhasePopUp = ({
  isEdit,
  isVisible,
  onClose,
  taskData,
  onRefresh,
  jobNumber,
  selectedPhase,
}) => {
  const { accounts } = useMsal();
  const { enqueueSnackbar } = useSnackbar();
  const account = accounts[0];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  console.log(selectedPhase, "selectedPhase");
  const initialValues = {
    phaseName: selectedPhase.phaseName || "",
    phaseAmount: selectedPhase.phaseAmount || "",
    phaseStatus: {
      value: selectedPhase.status || "PROMOTED",
      label: selectedPhase.status || "PROMOTED",
    },
    comment: selectedPhase.comment,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PhaseSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      if (!isEdit) {
        const body = {
          jobNumber: jobNumber,
          phaseName: values.phaseName,
          status: values.phaseStatus.value,
          phaseAmount: values.phaseAmount,
          comment: values.comment,
          updatedBy: account.username,
        };
        try {
          const response = await axios.post(
            `${API_URL}/api/invoice/project/phase`,
            body
          );
          if (response.data.success) {
            enqueueSnackbar("Request completed successfully!", {
              variant: "success",
            });
            onRefresh();
            onClose();
            resetForm();
          }
        } catch (error) {
          console.error(error);
          throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
          setLoading(false);
        }
      }
      if (isEdit) {
        const body = {
          phaseID: selectedPhase.phaseID,
          oldStatus: selectedPhase.status,
          oldPhaseAmount: selectedPhase.phaseAmount,
          oldComment: selectedPhase.comment,
          jobNumber: jobNumber,
          phaseName: values.phaseName,
          status: values.phaseStatus.value,
          phaseAmount: values.phaseAmount,
          comment: values.comment,
          updatedBy: account.username,
        };
        try {
          const response = await axios.put(
            `${API_URL}/api/invoice/project/phase`,
            body
          );
          if (response.data.success) {
            enqueueSnackbar("Request completed successfully!", {
              variant: "success",
            });
            onRefresh();
            onClose();
            resetForm();
          }
        } catch (error) {
          console.error(error);
          throw new Error(`HTTP error! status: ${error.response.status}`);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  if (!isVisible) return null;

  const handleInputChange = (value) => {
    formik.setFieldValue("phaseStatus", value);
  };

  const { getFieldProps, touched, errors, handleSubmit } = formik;
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "25px",
            justifyContent: "center",
          }}
        >
          <div className={styles.MomentLogo}>MOMENT</div>
        </Box>
        {loading && (
          <div className="ProfileHolidayOverlay">
            <div
              className="ProfileHolidayResponseBox"
              style={{ borderRadius: "150px", minWidth: "160px" }}
            >
              <CircularProgress size={65} />
            </div>
          </div>
        )}
        <FormikProvider value={formik} onSubmit={handleSubmit}>
          <Form autoComplete="off" noValidate>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Phase Name: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  disabled={isEdit}
                  fullWidth
                  value={formik.values.phaseName}
                  type="text"
                  onChange={(e) =>
                    formik.setFieldValue("phaseName", e?.target?.value)
                  }
                  placeholder="Phase"
                  sx={textfieldStyle}
                  error={Boolean(touched.phaseName && errors.phaseName)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Phase Amount: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  value={formik.values.phaseAmount}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("phaseAmount", e?.target?.value)
                  }
                  placeholder="Amount"
                  sx={textfieldStyle}
                  error={Boolean(touched.phaseAmount && errors.phaseAmount)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Status: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1.5 }}>
                <DropDown
                  options={statusOptions}
                  initialSelectedValue={{
                    value: selectedPhase.status || "PROMOTED",
                    label: titleCase(selectedPhase.status) || "Promoted",
                  }}
                  //   placeholder={"Status"}
                  onValueChange={(newValue) => handleInputChange(newValue)}
                  PopUp={true}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                <p className={styles.text}>Comment: </p>
              </Grid>
              <Grid item xs={8} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  value={formik.values.comment}
                  type="number"
                  onChange={(e) =>
                    formik.setFieldValue("comment", e?.target?.value)
                  }
                  rows={2}
                  placeholder="Comment"
                  sx={textfieldStyle}
                  //   textareaStyle={textfieldStyle}
                  inputProps={{ style: { margin: 0 } }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <div style={{ width: "74px", height: "25px" }}>
                <button
                  className={buttonStyles.YesButtonComponentStyling}
                  type="submit"
                  //   onClick={handleSubmit}
                >
                  {!isEdit ? "Add" : "Update"}
                </button>
              </div>
              <div
                style={{ width: "74px", height: "25px", marginLeft: "10px" }}
              >
                <button
                  className={buttonStyles.NoButtonComponentStyling}
                  onClick={onClose}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "25px",
            justifyContent: "center",
          }}
        >
          <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo" />
        </Box>
      </div>
    </div>
  );
};

export default PhasePopUp;

const statusOptions = [
  {
    value: "PROMOTED",
    label: "Promoted",
  },
  {
    value: "QUOTED",
    label: "Quoted",
  },
];

const PhaseSchema = Yup.object().shape({
  phaseAmount: Yup.string().required(),
  phaseName: Yup.string().required(),
});
function titleCase(string) {
  if (string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
  return 0;
}
