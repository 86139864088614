import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
// import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import styles from './SuppliersProfile.module.css';
import "./comment.css";
import BackBtn from '../../../assets/BackBtn.svg';
import Dropdown from '../../../Components/DropDownSelect/Dropdown';
import EditDiv from '../../../Components/EditDiv/EditDiv';
import AdminContext from '../../../Context/adminContext';
import { useMsal } from "@azure/msal-react";
import EditIcon from '../../../assets/Edit.svg';
import MapPin from '../../../assets/MapPin.svg';
import FolderIcon from '../../../assets/folder.svg';
import Skeleton from '@mui/material/Skeleton';
import { Box, CircularProgress } from '@mui/material';
import '../../../Components/YesNoButtonsCSS/YesNoButtons.css';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import Table from '../../../Components/Table/TableV2';
import InvoiceLogbook from '../../../Components/InvoiceLogbook/InvoiceLogbook';


import { useSelector, useDispatch } from "../../../redux/store";

import {
  getLogBook
} from "../../../redux/slices/invoice";

function SuppliersProfile() {

  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const account = accounts[0];
  const navigate = useNavigate();
  const supplierID = parseInt(window.location.hash.substring(1));
  const API_URL = process.env.REACT_APP_API_URL;
  const [jobData, setJobData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const [currentData, setCurrentData] = useState(null);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [text, setText] = useState('');
  const [displayEditDiv, setDisplayEditDiv] = useState(false);
  const [supplierEditInfo, setSupplierEdit] = useState([]);
  const [proProjectListData, setProProjectListData] = useState([]);
  const [BPProjectListData, setBPProjectListData] = useState([]);
  const [editType, setEditType] = useState('UPDATE');

  const editable = useContext(AdminContext);
  // (VAULTLOGBOOK)
  // const [activity, setActivity] = useState("");
  // const [comment, setComment] = useState("");
  // const [activityOptions, setActivityOptions] = useState(
  //   ['Update', 'Feedback', 'Google Review', 'Bad Debtor']
  // );

  // (VAULTLOGBOOK)
  // const commentInput = document.querySelector(".CommentInput");
  // const [logBook, AssignLogBook] = useState([]);
  // const [isLogBookLoading, setIsLogBookLoading] = useState(true);
  // const pulsatingTime = 500; // 5 seconds in milliseconds

  const fetchDataRecords = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/suppliers/supplierProfile/supplierInfo?supplierID=${supplierID}`);
      setJobData(result.data);
      setSupplierEdit(result.data.UpdateSupplierInfo);
    } catch (error) {
      console.error('Error fetching project data:', error);
    }
  };

  useEffect(() => {
    fetchDataRecords();
    // fetchLogBook(); // (VAULTLOGBOOK)
    // dispatch(getLogBook()); // (VAULTLOGBOOK)
  }, []);

  const handleUpdateClick = async (DataTitle, selectedValues) => {
    // console.log('DataTitle, selectedValues', DataTitle, selectedValues)
    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/api/suppliers/UpdatingInformation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Action: editType,
          SectionName: DataTitle,
          UpdatedValues: selectedValues,
          email: account.username,
          RowID: supplierID,
        }),
      })

      if (response.ok) {
        const data = await response.json();
        setApiResponse(data.message);
      } else {
        setApiResponse('Error updating supplier (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
      setLoading(false);
      fetchDataRecords();
    }
  };

  // (VAULTLOGBOOK)
  // const fetchLogBook = async () => {
  //   try {
  //     const result = await axios.get(
  //       `${API_URL}/api/clients/clientProfile/clientLogBook?clientID=${supplierID}`
  //     );
  //     AssignLogBook(result.data);
  //     setIsLogBookLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching project logbook:", error);
  //     setIsLogBookLoading(false);
  //   }
  // };

  const handleEdit = (data) => () => {
    setEditType('UPDATE')
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('..');
    }
  };

  // handle activitity change (VAULTLOGBOOK)
  // const handleActivityChange = (value) => {
  //   setActivity(value);
  // };

  // handle comment change (VAULTLOGBOOK)
  // const handleCommentChange = (e) => {
  //   setComment(e.target.value);
  // };

  // handle Confirmation click (VAULTLOGBOOK)
  // const handleConfirmationClick = async () => {
  //   setLoading(true);

  //   try {
  //     const CommentLog = {
  //       supplierID,
  //       activity,
  //       comment,
  //       email: account.username,
  //     };

  //     const response = await fetch(
  //       `${API_URL}/api/clients/submitClientLogbook`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(CommentLog),
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();

  //       // Clear form fields after successful submission
  //       setActivity("");
  //       setComment("");

  //       // Set the appropriate success message
  //       setApiResponse(data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setApiResponse("Error occurred");
  //   } finally {
  //     setLoading(false);
  //     fetchDataRecords();
  //     fetchLogBook();
  //     setIsResponseShown(true);
  //   }
  // };

  //handle Post Btn Click (VAULTLOGBOOK)
  // const handlePostBtnClick = async () => {
  //   if (comment.trim() === "") {
  //     // if comment is empty, add 'invalid' class and don't open modal
  //     document.querySelector(".CommentInput").classList.add("invalid");
  //     setTimeout(() => {
  //       commentInput.classList.remove("invalid");
  //     }, pulsatingTime);
  //     return "Invalid";
  //   } else {
  //     // if comment is not empty, remove 'invalid' class if it exists
  //     document.querySelector(".CommentInput").classList.remove("invalid");

  //     // directly handle confirmation without opening the modal
  //     handleConfirmationClick();
  //   }
  // };

  return (
    <div className={styles.SuppliersProfileContent}>
      <div className={styles.SupplierBaseInfo}>
        <div className={styles.BackBtn} onClick={handleBack}>
          <img src={BackBtn} alt='Back' />
        </div>
        <div className={styles.SupplierName}>
          {jobData ? `${jobData.company}` : "Loading..."}
          {editable && jobData &&
            <img
              className={`${styles.editIcon} ${styles.Name}`}
              onClick={handleEdit(supplierEditInfo)}
              src={EditIcon}
              alt="EditIconName"
            />
          }
        </div>
        <div className={styles.SupplierServices}>
          {jobData ? `${jobData.services}` : "--"}
        </div>
        <div className={styles.ProjectTags}>
          <li className={styles.PlaceHolderBubble} />

          <div className={styles.LeftAlignment}>
            {/* Status Bubble */}
            {/* {jobData && (
              <React.Fragment>
                {jobData.status && (
                  <li className={styles.StatusBubble}>{jobData.status}</li>
                )}
              </React.Fragment>
            )} */}
          </div>
        </div>
      </div>

      <div className={styles.SuppliersProfileScrollableArea}>
        <div className={styles.ProjectInformation} style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div className={styles.ProjectDetails} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '1' }}>

            <div className={styles.ProjectStageInfo}>
              <div className={styles.HorBlock1}>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.PhoneNo} ${styles.FieldTitle}`}>Phone No:</div>
                  <span className={styles.InfoText}>{jobData && jobData.phone ? jobData.phone : '--'}</span>
                </div>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.Location} ${styles.FieldTitle}`} style={{ marginLeft: '10.5px' }}>Website:</div>
                  <span className={styles.InfoText}>{jobData && jobData.website ? jobData.website : '--'}</span>
                </div>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.Email} ${styles.FieldTitle}`} style={{ marginLeft: '25px' }}>Email:</div>
                  <span className={styles.InfoText}>{jobData && jobData.email ? jobData.email : '--'}</span>
                </div>
              </div>
            </div>

            <div className={styles.ProjectStats}>
              <div className={styles.HorBlock1} style={{ width: '46%' }}>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.JobFee} ${styles.FieldTitle}`}> Main Contact: </div>
                  <span className={styles.InfoText}>{jobData && jobData.name ? jobData.name : '--'}</span>
                </div>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.JobType} ${styles.FieldTitle}`} style={{ marginLeft: '23px' }}>Comment: </div>
                  <span className={styles.InfoText}>{jobData && jobData.comment ? jobData.comment : '--'}</span>
                </div>
                <div className={styles.FieldContainer}>
                  <div className={`${styles.JobType} ${styles.FieldTitle}`} style={{ marginLeft: '25px' }}>Preferred: </div>
                  <span className={styles.InfoText}>
                    {jobData && jobData.preferred ? jobData.preferred : '--'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {jobData ? (
          <>
            {/* {jobData.professional && (
              <>
                <div className={styles.SubTitle}>Professional Project List</div>
                <div style={{ marginBottom: '24px' }}>
                  <Table data={proProjectListData} clickableRowAdd={'../projects/ProjectProfile'} />
                </div>
              </>
            )}
            {jobData.home_owner && (
              <>
                <div className={styles.SubTitle}>Home Owner Project List</div>
                <div style={{ marginBottom: '24px' }}>
                  <Table data={BPProjectListData} clickableRowAdd={'../projects/ProjectProfile'} />
                </div>
              </>
            )} */}
          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100% - 20%)' }}>
            <CircularProgress size={65} />
          </Box>
        )}

        {/* <div className={styles.SubTitle}> Log Book </div>

        <div className={styles.CommentsArea}>
          <div className={styles.CommentsSection}>

            <p className={styles.Activity}>
              Activity:{" "}
              <span className={styles.Dropdown}>
                <Dropdown
                  options={activityOptions}
                  selectedValue={activity}
                  onValueChange={handleActivityChange} // update selected activity when a new one is chosen
                />
              </span>
            </p>

            <p className={styles.Comment}>
              Comment:
              <textarea
                className="CommentInput" //{styles.CommentInput}
                placeholder={comment ? "" : "Comment..."}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => {
                  if (!e.target.value) e.target.placeholder = "Comment...";
                }}
                wrap="hard"
                value={comment}
                onChange={handleCommentChange}
              />
            </p>

            <p className={styles.Post}>
              <button
                className={`${styles.PostBtn} ${!jobData ? `${styles.disabled}` : ""}`}
                onClick={!jobData ? null : handlePostBtnClick}
              >
                POST
              </button>
            </p>
          </div>
        </div>

        <div id="Logbook" className={styles.LogBook}>
          <div className={styles.LogBookTable}>
            <table>
              <tbody>
                {isLogBookLoading
                  ? Array(8)
                    .fill()
                    .map((_, index) => (
                      <tr className={styles.LogRow} key={index}>
                        <td
                          className={styles.LogStatus}
                          style={{ marginLeft: "15px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={108}
                            height={25}
                          />
                        </td>
                        <td
                          className={`${styles.LogActivity}`}
                          style={{ marginLeft: "-220px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={120}
                            height={20}
                          />
                        </td>
                        <td
                          className={`${styles.LogComments} ${styles.wide}`}
                          style={{ marginLeft: "-130px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={500}
                            height={20}
                          />
                        </td>
                        <td
                          className={styles.LogDescription}
                          style={{ marginLeft: "30px" }}
                        >
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={300}
                            height={20}
                          />
                        </td>
                      </tr>
                    ))
                  : logBook &&
                  logBook.map((log) => (
                    <tr className={styles.LogRow} key={log.id}>
                      <td
                        className={`${styles.ActiveBubble} ${styles.LogStatus}`}
                      >
                        ACTIVE
                      </td>
                      <td className={`${styles.LogActivity} ${styles.wide}`}>
                        {log.activity_type}
                      </td>
                      <td className={`${styles.LogComments} ${styles.wide}`}>{log.comments}</td>
                      <td className={`${styles.LogDescription}`}>{log.description}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div> */}

        {displayEditDiv && <EditDiv
          data={currentData}
          handleUpdateClick={handleUpdateClick}
          setDisplayEditDiv={setDisplayEditDiv}
          actionType={editType}
        />}

        {loading && (
          <div className={styles.ProjectProfileOverlay}>
            <div style={{ borderRadius: "150px", minWidth: "160px" }}>
              <CircularProgress size={65} />
            </div>
          </div>
        )}

        {isResponseShown && (
          <div className={styles.ProjectProfileOverlay}>
            <div className={styles.ProjectProfileResponseBox}>
              <div
                style={{
                  fontSize: 16,
                  fontFamily: "PT Sans",
                  textAlign: "center",
                  margin: "20px",
                  marginLeft: "25px",
                  marginRight: "25px",
                }}
                dangerouslySetInnerHTML={{ __html: apiResponse }}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className={styles.ProjectProfileButton}
                  onClick={() => setIsResponseShown(false)}
                >
                  OK
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}

export default SuppliersProfile;