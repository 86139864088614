import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import axios from 'axios';


// Redux

// ----------------------------------------------------------------------

let initialState = {
    tasks: [],
    assignees: [],
    isAssigneeLoading: false,
    isLoading: false,
    error: null,
};

export const slice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        // set filter values in redux
        settaskdata(state, action) {
            state.tasks = action.payload;
            state.isLoading = false;
        },

        getAssignees(state, action) {
            state.assignees = action.payload.assignees;
            state.isAssigneeLoading = false;
        },

        startAssigneeLoading(state) {
            state.isAssigneeLoading = true;
        },

        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isAssigneeLoading = false;
            state.error = action.payload;
        },

    },

});

export const {
    startLoading
} = slice.actions

export default slice.reducer;

export function getTasks() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${API_URL}/api/bid/opportunity/task/all`);
            dispatch(slice.actions.settaskdata(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAssignees() {
    const API_URL = process.env.REACT_APP_API_URL;
    return async () => {
        dispatch(slice.actions.startAssigneeLoading());
        try {
            const response = await axios.get(`${API_URL}/api/bid/opportunity/assignee`);
            dispatch(slice.actions.getAssignees(response.data.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function handleSubmitFee(modalData) {
    const API_URL = process.env.REACT_APP_API_URL;
    console.log('Data submitted from the modal:', modalData);

    return async () => {
        try {
            // Sending API
            const response = await axios.post(`${API_URL}/api/bid/opportunity/submit/fee`,
                modalData);

            // Handle the response, if successful
            console.log(response);
        } catch (error) {
            // Handle any errors that occur during the fetch
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
};

export function uploadDoc(file, jobNumber, address, deadlineID, deadlineType, uploadID, uploadType, uploadedBy, comment) {
    const API_URL = process.env.REACT_APP_API_URL;

    console.log(file)

    const formData = {
        file,
        jobNumber: Number(jobNumber),
        address,
        deadlineID: Number(deadlineID),
        deadlineType,
        uploadID: Number(uploadID),
        uploadType,
        uploadedBy,
        comment
    }
    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("jobNumber", Number(jobNumber));
    // formData.append("address", address);
    // formData.append("deadlineID", Number(deadlineID));
    // formData.append("deadlineType", deadlineType);
    // formData.append("uploadID", Number(uploadID));
    // formData.append("uploadType", uploadType);
    // formData.append("uploadedBy", uploadedBy);
    // formData.append("comment", comment);

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${API_URL}/api/bid/opportunity/task/upload`,
                formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
            );

            dispatch(getTasks());

            return response;
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changeDeadline(jobNumber, deadlineID, deadlineType, newDeadlineDate, updatedBy) {
    const API_URL = process.env.REACT_APP_API_URL;

    const formData = new FormData();
    formData.append("jobNumber", Number(jobNumber));
    formData.append("deadlineID", Number(deadlineID));
    formData.append("deadlineType", deadlineType);
    formData.append("newDeadlineDate", newDeadlineDate);
    formData.append("updatedBy", updatedBy);

    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/bid/opportunity/task/change/deadline`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );

            dispatch(getTasks());

            return response;
        } catch (error) {
            console.log(error)
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function completeTask(jobNumber, deadlineID, deadlineType, updatedBy) {
    const API_URL = process.env.REACT_APP_API_URL;

    const formData = new FormData();
    formData.append("jobNumber", Number(jobNumber));
    formData.append("deadlineID", Number(deadlineID));
    formData.append("deadlineType", deadlineType);
    formData.append("updatedBy", updatedBy);

    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/bid/opportunity/task/complete`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );

            dispatch(getTasks());

            return response;
        } catch (error) {
            console.log(error)
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function changeAssignee(jobNumber, deadlineID, deadlineType, oldAssigneeID, newAssigneeID, newAssigneeName, updatedBy) {
    const API_URL = process.env.REACT_APP_API_URL;

    const formData = new FormData();
    formData.append("jobNumber", Number(jobNumber));
    formData.append("deadlineID", Number(deadlineID));
    formData.append("deadlineType", deadlineType);
    formData.append("oldAssigneeID", oldAssigneeID);
    formData.append("newAssigneeID", newAssigneeID);
    formData.append("newAssigneeName", newAssigneeName);
    formData.append("updatedBy", updatedBy);

    return async () => {
        try {
            const response = await axios.post(`${API_URL}/api/bid/opportunity/task/change/assignee`,
                formData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }
            );

            dispatch(getTasks());

            return response;
        } catch (error) {
            console.log(error)
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}