// Approvals.js
import React, { useEffect, useState, useContext } from 'react';
import styles from './Admin.module.css';
import { Outlet } from 'react-router-dom';
import Topbar from '../../Components/Topbar/Topbar';
import { useMsal } from "@azure/msal-react";
import AdminContext from '../../Context/adminContext';

// TopbarData.js
export const initialTopbarData = [
  {
    title: 'Leave',
    link: '/Admin/Leave',
    disabled: false,
  },
  {
    title: 'Sickness',
    link: '/Admin/Sickness',
    disabled: false,
  },
  {
    title: 'Onboarding',
    link: '/Admin/Onboarding',
    disabled: true,
  },
  {
    title: 'Off boarding',
    link: '/Admin/Offboarding',
    disabled: true,
  },
  {
    title: 'Reviews',
    link: '/Admin/Reviews',
    disabled: false,
  },
  {
    title: 'Employee Info',
    link: '/Admin/EmployeeInfo',
    disabled: false,
  },
  {
    title: 'Settings',
    link: '/Admin/Settings',
    disabled: false,
  },
  // Add more top bar items
];

function Admin() {
  const [TopbarData, setTopbarData] = useState(initialTopbarData);

  const { instance, accounts } = useMsal();
  const API_URL = process.env.REACT_APP_API_URL;
  const account = accounts[0];
  const isAdmin = useContext(AdminContext);
  const [reviewer, setReviewer] = useState(null);
  useEffect(() => {
    // Replace 'account' and 'API_URL' with the actual variables or values
    if (account) {
      fetch(`${API_URL}/api/checkIfReviewer?email=${account.username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => setReviewer(data.message));
    }
  }, [account]);

  useEffect(() => {
    if (!isAdmin && reviewer) {
      const updatedTopbarData = initialTopbarData.map(item =>
        item.title === 'Reviews' ? item : { ...item, disabled: true }
      );
      setTopbarData(updatedTopbarData);
    }
  }, [isAdmin, reviewer]);

  return (
    <div className={styles.profileContainer}>
      <Topbar TopbarData={TopbarData} />
      <Outlet />
    </div>
  );
}

export default Admin;
