import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.css";
import MoreOptionsImg from "../../assets/MoreOptions.svg";

// define a new component
const MoreOptionsV2 = ({ canEdit, onEdit, onDelete, onArchive }) => {
  const [displayMoreOptions, setDisplayMoreOptions] = useState(false);
  const containerRef = useRef(null);


  const handleMoreOptions = () => {
    setDisplayMoreOptions((prevDisplayMoreOptions) => !prevDisplayMoreOptions);
  };


  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setDisplayMoreOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  return (
    <td
      className={styles.tdImage}
      ref={containerRef}
      onClick={handleMoreOptions}
    >
      <img
        src={MoreOptionsImg}
        className={styles.MoreOptions}
        alt="MoreOptions"
      />
      {displayMoreOptions && (
        <div className={styles.moreOptionsDiv}>
          {canEdit && (
            <div onClick={onEdit} className={styles.moreOptionsDivEdit}>
              Edit
            </div>
          )}
          {onDelete && (
            <div onClick={onDelete} className={styles.moreOptionsDivDelete}>
              Delete
            </div>
          )}
          {onArchive && (
            <div onClick={onArchive} className={styles.moreOptionsDivArchive}>
              Archive
            </div>
          )}
        </div>
      )}
    </td>
  );
};

export default MoreOptionsV2;
