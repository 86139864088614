import React, { useState } from 'react';
import EditIcon from '../../assets/Edit.svg';
import styles from './Table.module.css'
import MoreOptionsButton from '../MoreOptions/MoreOptions.js'

function Table({data, editable=false, handleRowEdit=false, handleRowDelete=false, EditData=false}) {

  return (
    <div className={styles.infoSector}>
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            {data.headers.map((header, index) => {
              if (data.rows.length === 0 && index == 0) {
              } else if (editable === false && index == 0) {
              } else {
                return(<th key={index} className={styles.th}>{header}</th>)
              }
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {
            data.rows.length === 0 ? // check for missing employee information
              (
                <tr>
                  <td colSpan={7} className={styles.tdNoInfo}>No information on record.</td>
                </tr>
              )
            :
            data.rows.map((row, index) => {
            const SQLid = row[row.length - 1];
            return (
              <tr key={SQLid} className={styles.tableRow}>
                {row.map((cell, cellIndex) => {
                  if (cellIndex != (row.length - 1)) {
                    if (cell === 'Options' && cellIndex === 0) {
                      console.log(cell === 'Options')
                      console.log(cellIndex === 0)
                      console.log(editable)
                    }
                    if (cell === 'Options' && cellIndex === 0 && editable) {
                      return (
                        <MoreOptionsButton 
                          key={cellIndex} 
                          onEdit={handleRowEdit(data, SQLid, index)} 
                          onDelete={handleRowDelete(data, SQLid)} 
                        />
                      );
                    } else if (cell === 'Edit' && cellIndex === 0 && editable) {
                      return (
                        <td key={cellIndex} className={styles.tdImage} onClick={handleRowEdit(EditData, SQLid, index)}>
                          <img className={styles.trEditIcon} src={EditIcon} alt="EditIcon"/>
                        </td>
                      );
                    } else if (cell === 'Edit' && cellIndex === 0) {
                    } else if (cell === 'Options' && cellIndex === 0) {
                    } else {
                      return (<td key={cellIndex} className={styles.td}>{cell}</td>);
                    }
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  </div>
  );
}

export default Table;