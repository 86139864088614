import React, { useState, useEffect } from "react";
import styles from "../../Components/EditDiv/EditDiv.module.css";
import buttonStyles from "../../Components/YesNoButtonsCSS/YesNoButtons.module.css";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/store";
import DropDownV2 from "../../Components/DropDownBox/DropdownV2";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import * as Yup from "yup";

const ResponsibilitiesForm = ({ onSubmit, onClose }) => {
    const dispatch = useDispatch();
    const { configuration } = useSelector((state) => state.settings);

    const [individuals, setIndividuals] = useState([]);
    const [responsibilities, setResponsibilities] = useState([]);

    // Populate individuals and responsibilities from configuration
    useEffect(() => {
        if (configuration) {
            const formattedIndividuals = configuration.individuals.map((person) => ({
                value: person.id,
                label: person.name,
            }));
            setIndividuals(formattedIndividuals);

            // Set responsibilities from configuration.responsibilities
            const formattedResponsibilities = configuration.responsibilities || [];
            setResponsibilities(formattedResponsibilities);
        }
    }, [configuration]);

    // Generate initial values from configuration.responsibilities
    const initialValues = responsibilities.reduce((acc, item) => {
        acc[item.task_name] = item.name || ""; // Set initial value to name or an empty string if name is undefined
        return acc;
    }, {});

    const handleFormClick = (e) => {
        e.stopPropagation(); // Stops the click event from bubbling up to the parent div
    };

    const formik = useFormik({
        initialValues: initialValues,
        // validationSchema: ResponsibilitiesSchema,
        onSubmit: async (values) => {
            onSubmit(values);
            onClose();
            console.log(values);
        },
    });
    const { errors, touched, handleSubmit } = formik;

    return (
        <div className={styles.backGround} style={{ width: "100%" }}>
            <div className={styles.form}  style={{ width: "500px" }} onClick={handleFormClick}>
                <div className={styles.momentTitle}>MOMENT</div>
                <div className={styles.sectionTitle}>{`Update Responsibilities`}</div>

                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate style={{ width: "100%" }}>
                        {responsibilities.map((responsibility, index) => (
                            <Grid container sx={{ width: "100%" }} key={index}>
                                <Grid item xs={4} sx={{ pr: 3 }}>
                                    <p className={styles.dataTitleV2}>{responsibility.task_name}:</p>
                                </Grid>
                                <Grid item xs={8}>
                                    <DropDownV2
                                        options={individuals}
                                        initialSelectedValue={{ 'id': 0, 'label': responsibility.name }}  // Directly use formik value
                                        placeholder={`Select Individual for ${responsibility.task_name}`}
                                        onValueChange={(newValue) => {
                                            formik.setFieldValue(responsibility.task_name, newValue.value);
                                        }}
                                        errorState={Boolean(touched[responsibility.task_name] && errors[responsibility.task_name])}
                                    />
                                    {/* Display error message if any */}
                                    {touched[responsibility.task_name] && errors[responsibility.task_name] && (
                                        <p className={styles.errorText}>{errors[responsibility.task_name]}</p>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                        <div className={styles.updateAndCancel}>
                            <div className={styles.updateButton} onClick={handleSubmit}>
                                <div className={buttonStyles.YesButtonComponentStyling}>SUBMIT</div>
                            </div>
                            <div className={styles.cancelButton} onClick={onClose}>
                                <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
                            </div>
                        </div>
                    </Form>
                </FormikProvider>
            </div>
        </div>
    );
};

export default ResponsibilitiesForm;
