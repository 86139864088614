import React from 'react';
import { useNavigate } from 'react-router-dom';
import ResizableTh from '../ResizableTh';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from '../../redux/store';
import { setSelectedRow } from '../../redux/slices/rowhighlight';

function ClientListTable(props) {
  const [columnWidths, setColumnWidths] = React.useState({
    0: 10, // ID
    1: 150, // Name
    2: 80, // Type
    3: 50, // Tags
    4: 80, // Runner
    5: 120, // Email
    6: 50, // Comment Job Num
    7: 150, // Comment Job Address
    8: 300, // Comment
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const linkStyle = {
    color: 'inherit',
    textDecoration: 'none',
  };

  const tableRef = React.useRef(); // Create a ref for the table
  const [hoveredRow, setHoveredRow] = React.useState(null);
  const selectedRow = useSelector((state) => state.rowhighlight.selectedRow);

  const setColumnWidth = React.useCallback((index, width) => {
    setColumnWidths((prev) => {
      const totalWidth = Object.values(prev).reduce((a, b) => a + b, 0);
      const tableWidth = tableRef.current ? tableRef.current.offsetWidth : 0;

      if (prev[index] !== width && totalWidth - prev[index] + width <= tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        return newWidths;
      }

      if (totalWidth - prev[index] + width > tableWidth) {
        const newWidths = { ...prev };
        newWidths[index] = width;
        const excessWidth = totalWidth - prev[index] + width - tableWidth;
        const otherColumns = Object.keys(newWidths).filter((key) => key !== index);
        let remainingExcessWidth = excessWidth;

        const shrinkLeft = index > 0 && index < otherColumns.length;
        const sortedColumns = shrinkLeft ? otherColumns.sort((a, b) => a - b) : otherColumns.sort((a, b) => b - a);

        for (const column of sortedColumns) {
          if (newWidths[column] > 50 + remainingExcessWidth / otherColumns.length) {
            newWidths[column] -= remainingExcessWidth / otherColumns.length;
            remainingExcessWidth -= remainingExcessWidth / otherColumns.length;
          } else {
            remainingExcessWidth -= newWidths[column] - 50;
            newWidths[column] = 50;
          }
        }

        return newWidths;
      }

      return prev;
    });
  }, []);

  React.useEffect(() => {
    const scrollPos = localStorage.getItem('scrollPos');
    if (scrollPos && tableRef.current) {
      tableRef.current.scrollTop = parseFloat(scrollPos);
    }
    console.log(props.rows);
  }, []);

  const handleRowClick = (row) => {
    navigate(`ClientProfile#${row.id}`);
  };

  const uniqueRows = React.useMemo(() => {
    const invNumSet = new Set();
    return props.rows.filter(row => {
      console.log(props.rows)
      if (invNumSet.has(row.id)) {
        return false;
      } else {
        invNumSet.add(row.id);
        return true;
      }
    });
  }, [props.rows]);

  return (
    <div className='ProjectListBox'>
      <div id="clientTableContainer" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {['ID', 'Name', 'Type', 'Tags', 'Runner', 'Email', 'Job Num', 'Job Address', 'Comment'].map((header, index) => (
                <ResizableTh onResize={(width) => setColumnWidth(index, width)} index={index} key={index}>
                  <span
                    onClick={props.loading ? null : () =>
                      props.handleSort(
                        header.toLowerCase().replace(' ', '').replace('.', '') // Continue using existing logic for other headers
                      )
                    }
                    className={props.loading ? 'disable-click' : ''}>
                    {header}
                    {props.sortColumn === (header.toLowerCase().replace(' ', '').replace('.', '')) && (
                      props.sortDirection === 'asc' ? ' ▲' : ' ▼'
                    )}
                  </span>
                </ResizableTh>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.loading ? (
              [...Array(50)].map((_, index) => (
                <tr key={index}>
                  {[...Array(9)].map((_, cellIndex) => (
                    <td key={cellIndex} style={{ width: columnWidths[cellIndex] }}>
                      <div className="skeleton" style={{ height: '13px' }}></div>
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              uniqueRows.map((row) => (
                <tr
                  key={row.id}
                  onMouseEnter={() => setHoveredRow(row.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                  onClick={() => {
                    handleRowClick(row);
                    dispatch(setSelectedRow(row.id));
                    if (tableRef.current) {
                      localStorage.setItem('scrollPos', tableRef.current.scrollTop);
                    }
                  }}
                  className={`
                  ${hoveredRow === row.id && selectedRow !== row.id ? 'row-hover' : ''}
                  ${selectedRow === row.id ? 'row-selected' : ''}
                `}
                >
                  <td style={{ width: columnWidths[0], maxWidth: '20px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '10px', textAlign: 'center' }}>{row.id}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[1], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '120px', width: `${columnWidths[1]}px`, maxWidth: '200px' }}>{row.name}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[2], maxWidth: '80px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '60px', width: `${columnWidths[2]}px` }}>{row.type}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[3], maxWidth: '70px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ display: 'flex', minWidth: '20px', justifyContent: 'center' }}>
                        {row.tags && Array.isArray(row.tags) && row.tags.length > 0 ? (
                          <React.Fragment>
                            {row.tags.map((tag) => {
                              // Check if tag is a year
                              const isYear = /^19\d{2}$|^20\d{2}$/.test(tag);
                              let abbreviateTag;
                              if (isYear) {
                                // Abbreviate years to the last two digits
                                abbreviateTag = `'${tag.substring(2)}`;
                              } else {
                                // Split tag into words and abbreviate
                                const words = tag.split(' ');
                                if (words.length > 1) {
                                  // For multiple words, take the first letter of the first two words
                                  abbreviateTag = words.slice(0, 2).map(word => word[0].toUpperCase()).join('');
                                } else {
                                  // If there's only one word, use the first two letters, or the entire word if it's shorter
                                  abbreviateTag = tag.length > 1 ? tag.substring(0, 2).toUpperCase() : tag.toUpperCase();
                                }
                              }

                              return (
                                <div className="TagContainer">
                                  <div className="Tag" key={tag} style={{ marginRight: '3px', display: 'flex' }}>
                                    {abbreviateTag}
                                  </div>
                                </div>
                              );
                            })}
                          </React.Fragment>
                        ) : (
                          <div style={{ height: '20px' }}></div>
                        )}
                      </div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[4], maxWidth: '120px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '80px', width: columnWidths[4], maxWidth: '1200px' }}>{row.runner}</div>
                    </Link>
                  </td>


                  <td style={{ width: columnWidths[5], maxWidth: '250px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '100px', width: columnWidths[5], maxWidth: '250px' }}>{row.email}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[6], maxWidth: '60px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '50px', width: `${columnWidths[6]}px`, maxWidth: '60px' }}>{row.jobnum ? row.jobnum : ''}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[7], maxWidth: '200px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '100px', width: `${columnWidths[7]}px`, maxWidth: '200px' }}>{row.jobaddress}</div>
                    </Link>
                  </td>

                  <td style={{ width: columnWidths[8], maxWidth: '700px' }}>
                    <Link to={() => handleRowClick(row)} style={linkStyle}>
                      <div className="td-content" style={{ minWidth: '100px', width: `${columnWidths[8]}px`, maxWidth: '700px' }}>{row.comment}</div>
                    </Link>
                  </td>

                  
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ClientListTable;