import React, { useState, useEffect, useRef } from 'react';
import { useMsal } from "@azure/msal-react";
import stylies from './Reviews.module.css';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import Dropdown from "../../../Components/DropDownBox/Dropdown";
import Table from "../../../Components/Table/TableV2";
import TableV1 from '../../../Components/Table/Table';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ClickableTitle from '../../../Components/ClickableTitle/ClickableTitle.js';
import RevieweeForm from '../../../Components/ReviewForms/RevieweeForm';
import ColleagueForm from '../../../Components/ReviewForms/ColleagueForm';
import List from '../../../Components/List/List.js';
import BackBtn from '../../../assets/BackBtn.svg';
import { useNavigate } from 'react-router-dom';
import TickBox from '../../../Components/TickBox/TickBox';
import CommentBox from '../../../Components/CommentBox/CommentBoxV3';
import buttonStyles from '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';
import StartReviewForm from '../../../Components/Forms/StartReviewForm';
import EditDiv from '../../../Components/EditDiv/EditDiv.js';

const Reviews = (props) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { accounts } = useMsal();
    const account = accounts[0]; 
    const [reload, setReload] = useState(0);
    const [loading, setLoading] = useState(false);
    const [viewReview, setViewReview] = useState(false);
    const [displayProfileForm, setDisplayProfileForm] = useState(false);
    const [displayColleagueForm, setDisplayColleagueForm] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [data, setData]= useState(null);
    const [displayEditDiv, setDisplayEditDiv] = useState(false);
    const [formData, setFormData] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [reviewScheduleData, setReviewScheduleData]= useState(null);
    const [rowIndex, setRowIndex] = useState(null);
    const [leaveDates, setLeaveDates] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      }
    ]);

    useEffect(() => {
      setIsDataFetched(false);
      const fetchData = fetch(`${API_URL}/api/Admin/Reviews/PageData?userEmail=${encodeURIComponent(account.username)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      });
    
      Promise.all([fetchData])
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(([fetchedData]) => {
            setData(fetchedData)
            console.log('fetchedData: ', fetchedData)
            setIsDataFetched(true);
            setReviewScheduleData(fetchedData?.StartReview)
          });
    }, [reload]);
  
    const [formID, setFormID] = useState("");
  
    // Then when the UPDATE button is clicked, you send selectedValues to the backend
    const handleFormSubmit = async (DataTitle, selectedValues, action, information) => {
      setIsDataFetched(false);
      setDisplayProfileForm(false);
      setDisplayColleagueForm(false);
      console.log('DataTitle, selectedValues', DataTitle, selectedValues)
        try {
          const response = await fetch(`${API_URL}/api/Profile/Reviews/FormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              UserEmail: account.username, 
              Action: action,
              Data: information,
            }),
        })
        if (response.ok) {
          setReload(reload + 1);
        } 
        } catch (error) {
          console.error("Error in handleCancelationReload:", error);
        }
      };
  
      const uploadDocument = async (docInfo) => {
          try {
            const response = await fetch(`${API_URL}/api/Profile/Sickness/DocUpload?LeaveID=${encodeURIComponent(formID)}`, {
              method: 'POST',
              body: docInfo
          })
          if (response.ok) {
            setReload(reload + 1);
          } 
          } catch (error) {
            console.error("Error in handleCancelationReload:", error);
          }
        };
  
      const handleReturnedClick = (id) => {
        console.log('handleReturnedClick id: ', id)
      }
  
      const handleFromRequestClick = (id, task) => {
        setLoading(true)
        
        console.log('task: ', task)
        setFormID(id)
        if (task == 'Employee Review Form') {
          console.log('Im in! Employee Review Form')
          const fetchData = fetch(`${API_URL}/api/Profile/Reviews/FormData?FormType=${encodeURIComponent('Employee Review Form')}&FormID=${encodeURIComponent(id)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          });
        
          Promise.all([fetchData])
            .then(responses => Promise.all(responses.map(response => response.json())))
            .then(([fetchedData]) => {
              setFormData(fetchedData)
              setLoading(false)
              setDisplayProfileForm(true)
            }
          );
        } else if (task.includes('College Feedback For')) {
          console.log('Im in! College Feedback For')
          const fetchData = fetch(`${API_URL}/api/Profile/Reviews/FormData?FormType=${encodeURIComponent('College Feedback For')}&FormID=${encodeURIComponent(id)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          });
        
          Promise.all([fetchData])
            .then(responses => Promise.all(responses.map(response => response.json())))
            .then(([fetchedData]) => {
              setFormData(fetchedData)
              setLoading(false)
              setDisplayColleagueForm(true)
            }
          );
        }
      }

      const [Comment1, setComment1] = useState('');
      const [Comment1Colour, setComment1Colour] = useState('');
      const [Comment2, setComment2] = useState('');
      const [Comment2Colour, setComment2Colour] = useState('');
      const [Comment3, setComment3] = useState('');
      const [Comment3Colour, setComment3Colour] = useState('');
      const [PatternYesTick, setPatternYesTick] = useState(false);
      const [PatternNoTick, setPatternNoTick] = useState(false);
      const [ViewFromData, setViewFromData] = useState("");
      const handleViewFromClick = (id, task) => {
        setIsDataFetched(false);
        
        console.log('task: ', task)

        if (task == 'Employee Review Form') {
          console.log('Im in! Employee Review Form')
        } else if (task.includes('College Feedback For')) {
          console.log('Im in! College Feedback For')
        }

        setFormID(id)
        const fetchData = fetch(`${API_URL}/api/Profile/Reviews/ViewFormData?FormID=${encodeURIComponent(id)}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
        });
        
        Promise.all([fetchData])
          .then(responses => Promise.all(responses.map(response => response.json())))
          .then(([fetchedData]) => {
            setViewFromData(fetchedData)

            if (fetchedData.reviewee_form_data.Q13 == 'YES') {
              setPatternYesTick(true)
            }
            if (fetchedData.reviewee_form_data.Q13 == 'NO') {
              setPatternNoTick(true)
            }
            setComment1(fetchedData.KPIs)
            setComment2(fetchedData.Actions)
            setComment3(fetchedData.Notes)

            setViewReview(true);
          }
        );
      }
  
  useEffect(() => {
    console.log('loading: ', loading)
  }, [loading]);

  const headerData = {
    headers: ['Column 1', 'Column 2', 'Column 3', 'Column 4', 'Column 5'],
  };

  const OpenSicknessTableRef = useRef(null);
  const DocumentTableRef = useRef(null);

  const [OpenSicknessMaxTableWidth, setOpenSicknessMaxTableWidth] = useState(0);
  const [DocumentTableMaxWidth, setDocumentMaxTableWidth] = useState(0);

  useEffect(() => {
    if (OpenSicknessTableRef.current) {
      setOpenSicknessMaxTableWidth(OpenSicknessTableRef.current.offsetWidth);
    }
  }, [OpenSicknessTableRef.current]);

  useEffect(() => {
    if (DocumentTableRef.current) {
      setDocumentMaxTableWidth(DocumentTableRef.current.offsetWidth);
    }
  }, [DocumentTableRef.current]);

  function QuestionAndAnswer({ number, question, answer }) {
    const { GrayNum, QandA, GrayQuestion, BlackAnswer } = stylies;
  
    return (
      <div className={stylies.NumAndQandA}>
        <div className={GrayNum}>
          {number}
        </div>
        <div className={QandA}>
          <div className={GrayQuestion}>
            {question}
          </div>
          <div className={BlackAnswer}>
            {answer}
          </div>
        </div>
      </div>
    );
  }

  const navigate = useNavigate();
  const handleBack = () => {
    window.location.reload();
  };



  const handleFormSubmitClick = () => {
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
    }
    FormSubmit('View Review Form', 'SUBMIT', information);
  };

  const FormSubmit = async (DataTitle, action, information) => {
    try {
      const response = await fetch(`${API_URL}/api/Admin/Reviews/ViewFormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          UserEmail: account.username, 
          Action: action,
          Data: information,
        }),
    })
    if (response.ok) {
      handleBack();
    } 
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    }
  };

  const secretFormSubmit = async (DataTitle, action, information) => {
      try {
        const response = await fetch(`${API_URL}/api/Admin/Reviews/ViewFormUpdate?FormID=${encodeURIComponent(formID)}&FormTitle=${encodeURIComponent(DataTitle)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            UserEmail: account.username, 
            Action: action,
            Data: information,
          }),
      })
      } catch (error) {
        console.error("Error in handleCancelationReload:", error);
      }
  };

  const handleSecretDraftClick = () => {
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
    }
    secretFormSubmit('View Review Form', 'DRAFT', information);
  };

  const handleDraftClick = () => {
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
    }
    secretFormSubmit('View Review Form', 'DRAFT', information);
    handleBack();
  };

  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      if (viewReview && (ViewFromData?.AssistReviewerEmail === account.username || account.username === 'devops@blueengineering.co.uk')) {
      handleSecretDraftClick();
      }
    }, 6000); // 60,000 milliseconds = 60 seconds

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [
    Comment1,
    Comment2,
    Comment3,
    viewReview
  ]);

  const [showStartReviewsForm, setShowStartReviewsForm]= useState(false);
  const [editType, setEditType] = useState('UPDATE');
  const [apiResponse, setApiResponse] = useState(null);
  const [isResponseShown, setIsResponseShown] = useState(false);

  const StartReviewSchedule = () => {
    setShowStartReviewsForm(true)
  };
  const handleUpdateClick = async (newData) => {
    setShowStartReviewsForm(false)
    setReviewScheduleData(newData)
    console.log('selectedValues newData: ', newData)
    try {
      const response = await fetch(`${API_URL}/api/Admin/Reviews/ReviewSchedule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: newData
        }),
      })

      if (response.ok) {
        const data = await response.json();
        setReload(reload + 1);
        setApiResponse(data.message);
      } else {
        setApiResponse('Error Creating Review Schedule (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in Review Schedule Post:", error);
    } finally {
      setIsResponseShown(true);
    }
  };
  
  const handleRowEdit = (data, SQLid, index) => () => {
    console.log('SQLid: ', index)
    // Clone the data object to avoid mutating original data
    let newData = { ...data };
    // Transform the DataEdit array
    newData.DataEdit = newData.DataEdit.map((item, i) => {
      return {
        ...item,
        dataName: newData.headers[i + 1], // Add 1 to i to skip the first item
        data: newData.rows[index][i + 1] // Same here
      };
    });
    setEditType('Update')
    setRowIndex(SQLid)
    handleEdit(newData)();
  };
    const handleEdit = (data) => () => {
    setCurrentData(data);
    setDisplayEditDiv(true);
  };

  const handleChaseClick = async (reviewID, name, feedbackFor, DueDate) => {
    setShowStartReviewsForm(false)
    try {
      const response = await fetch(`${API_URL}/api/Admin/Reviews/handleChaseClick`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          reviewID: reviewID,
          name: name,
          feedbackFor: feedbackFor,
          DueDate: DueDate
        }),
      })

      if (response.ok) {
        const data = await response.json();
        setReload(reload + 1);
        setApiResponse(data.message);
      } else {
        setApiResponse('Error Creating Review Schedule (backend ran into a logical issue, please let a DevOps engineer know)');
      }
    } catch (error) {
      console.error("Error in Supplier Info Update:", error);
    } finally {
      setIsResponseShown(true);
    }
  };

  let content;
  if (isDataFetched) {
    content = (
      <div className={stylies.mainContent}>
        <div className={stylies.TopSection}>
          <div className={stylies.CollumnFlexSection} style={{width: '100%', height: '100%'}}>

            <div className={stylies.StartReview}> 
              <div className={stylies.StartReviewButton} onClick={StartReviewSchedule}>START REVIEW</div>
            </div>

            <div className={stylies.RowFlexSection}>
              <div className={stylies.CollumnFlexSection} style={{width: '40%', height: '100%'}}>
                <div className={stylies.title}>Open Reviews</div>
                <div className={stylies.OpenSicknessTable} ref={OpenSicknessTableRef}>
                  <Table data={data?.OpenReviews.DisplayData} 
                  indexPassed={true}
                  editable={true} 
                  handleRowEdit={handleRowEdit} 
                  EditData={data?.OpenReviews.EditData}
                  handleButtonClick={handleViewFromClick}
                  /> 
                </div>
              </div>

              <div className={stylies.CollumnFlexSection} style={{width: '60%', height: '100%', margin: '0px'}}>
                <div className={stylies.title} style={{width: '60%'}}>Outstanding Documents</div>
                <div className={stylies.OpenSicknessTable} ref={OpenSicknessTableRef} style={{width: '100%'}}>
                  <Table data={data?.OutstandingDocuments} indexPassed={true} handleButtonClick={handleChaseClick} Resizable={false} MaxWidthTable={OpenSicknessMaxTableWidth}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {displayEditDiv && <EditDiv data={currentData} handleUpdateClick={handleUpdateClick} setDisplayEditDiv={setDisplayEditDiv} actionType={editType} />}

        {loading ? // Here is the code for displaying error/success messages
        ( 
          <div className={stylies.LoadOverlay}>
            <Box sx={{ display: 'flex'}}>
              <CircularProgress size={65} />
            </Box>
          </div> 
        ) : displayProfileForm ? (
          displayProfileForm && <RevieweeForm data={formData} handleFormSubmit={handleFormSubmit} uploadDocument={uploadDocument} setDisplayProfileForm={setDisplayProfileForm} actionType={'SUBMIT'} />
        ) : displayColleagueForm ? (
          displayColleagueForm && <ColleagueForm data={formData} handleFormSubmit={handleFormSubmit} uploadDocument={uploadDocument} setDisplayProfileForm={setDisplayColleagueForm} actionType={'SUBMIT'} />
        ) : null
        }

        {showStartReviewsForm && <StartReviewForm data={reviewScheduleData} handleUpdateClick={handleUpdateClick} setDisplayEditDiv={setShowStartReviewsForm} actionType={editType} />}
      
      </div>
    );
  } else if (viewReview) {
    content = (
      <div className={stylies.mainContent}>
        <div className={stylies.RowFlexSection} style={{justifyContent: 'space-between'}}>
          <div className='BackBtn' onClick={handleBack}>
            <img src={BackBtn} alt='Back' />
          </div>
          { (ViewFromData?.AssistReviewerEmail === account.username || account.username === 'devops@blueengineering.co.uk') ? (
            <div className={stylies.RowFlexSection} style={{justifyContent: 'center', alignItems: 'center', marginTop: '45px', marginLeft: '1000px'}}>
              <div className={stylies.DraftButton} onClick={handleDraftClick}>SAVE DRAFT</div>
              <div className={stylies.updateButton}>
                <div className={buttonStyles.YesButtonComponentStyling} style={{marginTop: '1px'}} onClick={handleFormSubmitClick}>SUBMIT</div>
              </div>
            </div>
          ) : null }

        </div>

        <div className={stylies.title}>Annual Performace Review</div>
        <div className={stylies.ThreeSmallBoxesSection}>
          <List data={ViewFromData?.FirstBox} editable={false} />
          <List data={ViewFromData?.SecondBox} editable={false} />
          <List data={ViewFromData?.ThirdBox} editable={false} />
        </div>

        <div className={stylies.KPIsActionsNotes} style={{marginBottom: '25px'}}>
          <div className={stylies.KPIsActionsNotesBoxes}>
            <div className={stylies.title}>KPIs</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              { (ViewFromData?.AssistReviewerEmail === account.username || account.username === 'devops@blueengineering.co.uk') ? (
                <CommentBox initialData={Comment1} onValueChange={setComment1} liningColour={Comment1Colour} />
              ) : null }
            </div>
          </div>
          <div className={stylies.KPIsActionsNotesBoxes}>
            <div className={stylies.title}>Actions</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
              { (ViewFromData?.AssistReviewerEmail === account.username || account.username === 'devops@blueengineering.co.uk') ? (
                <CommentBox initialData={Comment2} onValueChange={setComment2} liningColour={Comment2Colour} />
              ) : null }
            </div>
          </div>
          <div className={stylies.KPIsActionsNotesBoxes} style={{marginRight: '0px'}}>
            <div className={stylies.title}>Notes</div>
            <div className={stylies.CurrentKPIsBox} style={{ whiteSpace: 'pre-line' }}>
            { (ViewFromData?.AssistReviewerEmail === account.username || account.username === 'devops@blueengineering.co.uk') ? (
                <CommentBox initialData={Comment3} onValueChange={setComment3} liningColour={Comment3Colour} />
              ) : null }
            </div>
          </div>
        </div>

        <ClickableTitle titleName='Employee Review Form'  
          section= {
            <div className={stylies.infoSector}>
              <div className={stylies.QuestionSection}>
                How are you doing?
              </div>

              <QuestionAndAnswer         
                number="1."
                question="What targets were you set in your last review? Write your targets in full and comment either how you have met them, or what you need to do to meet them."
                answer={ViewFromData?.reviewee_form_data.Q1}
              />

              <QuestionAndAnswer         
                number="2."
                question="What have you been up to since your last review? Have your responsibilities changed? Which skills have you developed/consolidated?"
                answer={ViewFromData?.reviewee_form_data.Q2}
              />

              <QuestionAndAnswer         
                number="3."
                question="How have you found the difficulty of the work you have been undertaking? Would you like to change it?"
                answer={ViewFromData?.reviewee_form_data.Q3}
              />

              <QuestionAndAnswer         
                number="4."
                question="Do you have an Internal Project, if so what is it? How is it going? What is the next milestone?"
                answer={ViewFromData?.reviewee_form_data.Q4}
              />
              
              <div className={stylies.QuestionSection}>
                How are you progressing?
              </div>

              <QuestionAndAnswer         
                number="5."
                question="What are you aiming to achieve in the next 3 months? What do you need to do/learn in order to achieve it?"
                answer={ViewFromData?.reviewee_form_data.Q5}
              />

              <QuestionAndAnswer         
                number="6."
                question="What can Blue do more of to help you progress your career? What would you like to learn next?"
                answer={ViewFromData?.reviewee_form_data.Q6}
              />

              <QuestionAndAnswer         
                number="7."
                question="How is your IPD/Uni/College course going? When are you taking your next professional qualification? If you are not at Uni, how are you progressing your skills?"
                answer={ViewFromData?.reviewee_form_data.Q7}
              />
                            
              <div className={stylies.QuestionSection}>
                How are we doing? 
              </div>

              <QuestionAndAnswer         
                number="8."
                question="How do you feel you are managed? Is it too loose, micromanaged, not enough clarity, target too hard etc."
                answer={ViewFromData?.reviewee_form_data.Q8}
              />

              <QuestionAndAnswer         
                number="9."
                question="How do you find the communication within your team? What do you like? What would you change?"
                answer={ViewFromData?.reviewee_form_data.Q9}
              />

              <QuestionAndAnswer         
                number="10."
                question="How do you find the communication within Blue? Do you know about the direction of the company? Are roll outs of new process and systems clear and understandable?"
                answer={ViewFromData?.reviewee_form_data.Q10}
              />

              <div className={stylies.QuestionSection}>
                Level
              </div>

              <div className={stylies.NumAndQandA}>
                <div className={stylies.GrayNum}>
                  {"11."}
                </div>
                <div className={stylies.QandA}>
                  <div className={stylies.GrayQuestion}>
                    {"Would you like your level reviewed? Why?"}
                  </div>
                  <div className={stylies.YesNoTicks} style={{marginBottom: '10px'}}>
                    <TickBox type={PatternYesTick} usable={false} />
                    <div className={stylies.GrayNum} style={{marginLeft: '10px', marginRight: '10px', width: '20px'}}>Yes</div>
                    <TickBox type={PatternNoTick} usable={false} />
                    <div className={stylies.GrayNum} style={{marginLeft: '10px', marginRight: '10px', width: '20px'}}>No</div>
                  </div>
                  <div className={stylies.BlackAnswer}>
                    {ViewFromData?.reviewee_form_data.Q11}
                  </div>
                </div>
              </div>

              <QuestionAndAnswer         
                number="12."
                question="Is there anything else you would like to discuss?"
                answer={ViewFromData?.reviewee_form_data.Q12}
              />
            </div>
          }
        />

        <ClickableTitle titleName='Colleague Feedback Form'  
          section= {
            <div className={stylies.infoSector}>
              <div className={stylies.QuestionSection}>
                Job Knowledge and Ability
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q1.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q2.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>



              <div className={stylies.QuestionSection}>
                Quality of Work
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q3.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q4.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Timekeeping & Deadlines
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q5.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q6.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Attitude to Work
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q7.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q8.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
                Interaction with Others
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q9.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q10.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>


              <div className={stylies.QuestionSection}>
              Culture & Values
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q11.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q12.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={stylies.QuestionSection}>
              Extra Responsibilities Within Blue/Team
              </div>
              
              <div className={stylies.encompas}>
                <div className={stylies.halfie} style={{marginRight: '20px'}}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Strengths</div>
                  {ViewFromData?.colleauge_feedback_data.Q13.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>

                <div className={stylies.halfie}>
                  <div className={stylies.GrayNum} style={{marginBottom: '10px'}}>Improvements</div>
                  {ViewFromData?.colleauge_feedback_data.Q14.map((item, index) => (
                    <div key={index} className={stylies.nameandans} style={{marginBottom: '20px'}}>
                      <div className={stylies.NAME}>
                        {item.name}
                      </div>
                      <div className={stylies.BlackAnswerdif}>
                        {item.answer}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  } else {
    content = (
      <Box sx={{display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center'}}>
        <CircularProgress size={65}/>
      </Box>
    );
  }
  
  return content;
};

export default Reviews;