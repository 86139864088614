import React, { useEffect, useState, useRef } from 'react';
import styles from './styleV3.module.css';

function CommentBox({ initialData, onValueChange, invalid = false, liningColour='' }) {
  const [comment, setComment] = useState(initialData || "");
  const [isInvalid, setIsInvalid] = useState(invalid);
  const [isFocused, setIsFocused] = useState(false);
  const [color, setColor] = useState(liningColour);
  const commentRef = useRef(null);

  const handleTextChange = (e) => {
    setComment(e.target.value);
    if (onValueChange) {
      onValueChange(e.target.value);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    setIsInvalid(invalid);
    if (invalid) {
      const timerId = setTimeout(() => {
        setIsInvalid(false);
        console.log(isInvalid)
      }, 500);
      return () => clearTimeout(timerId);
    }
  }, [invalid]);

  const commentClass = `${styles.Comment} ${isInvalid ? styles.invalidInput : ''}`;

  return (
    <textarea
      ref={commentRef}
      className={commentClass}
      id='FormComment'
      placeholder={initialData}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={comment}
      onChange={handleTextChange}
      style={{borderColor: liningColour}}
    />
  );
}

export default CommentBox;
