import React, { useRef, useState, useEffect } from 'react';
import Dropdown from '../DropDownBox/Dropdown'; // Make sure to import the Dropdown component
import styles from './SearchBar.module.css';

// Initial list of all possible options
function SearchBar(props) {
  const [options, setOptions] = useState([]); // Initialize with empty array
  const [timer, setTimer] = useState(null); // To store the timer
  const [shouldOpenDropdown, setShouldOpenDropdown] = useState(true); // New state

  console.log(props.allOptions,'SearchBar')

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState('100%');

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(`${parentRef.current.offsetWidth}px`);
    }
  }, []);

  // Function to filter options based on search query
  const filterOptionsBasedOnSearch = (query) => {
    return props.allOptions?.filter(option => option.toLowerCase().includes(query.toLowerCase()));
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer); // Clear existing timer if there's any
    }
  
    // Additional condition to close the dropdown if the input is cleared or too short
    if (!props.value || !shouldOpenDropdown || !props.showDropdown) {
      setOptions([]); // Clear options immediately if search value is empty or other conditions are not met
    } else {
      // Start a new timer if search value exists and is considered valid for search
      const newTimer = setTimeout(() => {
        // Filter options based on the search query
        const filteredOptions = filterOptionsBasedOnSearch(props.value);
        setOptions(filteredOptions);
      }, 500); // 0.5 seconds
  
      setTimer(newTimer); // Save the new timer
    }
  }, [props.value, shouldOpenDropdown, props.showDropdown]);
  

  const handleSearchChange = (e) => {
    // Handle search text change
    // Update options based on new search text
    // setOptions(newOptions);
    
    setShouldOpenDropdown(true); // Open dropdown when new character is typed

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const handleOptionSelect = (selectedOption) => {
    if (props.onOptionSelect) {
      props.onOptionSelect(selectedOption);
    }
    setOptions([]); // Clear options
    setShouldOpenDropdown(false); // Close dropdown
  };

  return (
    <div ref={parentRef} className={styles.dropdownContainer}>
      <div style={{ width: props.searchWidth ? props.searchWidth : '300px' }} className={styles.searchContainer}>
        <input
          type="text"
          className={`${styles.ProjectSearch} ${props.disabled ? styles.disabled : ''}`}
          placeholder={props.value ? '' : 'Search...'}
          onFocus={(e) => e.target.placeholder = ''}
          onBlur={(e) => {
            if (!e.target.value) e.target.placeholder = 'Search...';
          }}
          onChange={handleSearchChange}
          onKeyDown={props.onKeyDown}
          disabled={props.disabled}
          value={props.value}
        />
        <button onClick={props.onClick} disabled={props.disabled} className={`${styles.searchButton} ${props.disabled ? styles.disabled : ''}`}>
          <span className={styles.searchIconPlaceholder}></span>
        </button>
      </div>
      {props.showDropdown && shouldOpenDropdown && options?.length > 0 && ( 
        <Dropdown
          options={options}
          showOnlyOptions={true}
          options_width={props.searchWidth ? props.searchWidth : parentWidth}
          onValueChange={handleOptionSelect}  // Pass the new function here
        />
      )}
    </div>
  );
}

export default SearchBar;
