import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import './Auth.css';
import styles from './SignInPage.module.css';
import blueIcon from '../../assets/BlueLogo.png';
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const SignInPage = () => {
  const { instance } = useMsal();
  const navigate = useNavigate(); // add this line

  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest)
      .then(() => {
        navigate("/Projects"); // navigate after successful loginp
      })
      .catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest)
      .then(() => {
        navigate("/Projects"); // navigate after successful login
      })
      .catch((e) => {
        console.log(e);
      });
    }
  };
  
  return (
      <div className={styles.LoginPage}>
        <div className={styles.lightBlueBlock}></div>
        <div className={styles.darkBlueBlock}></div>

        <div className={styles.loginBox}>
          <img src={blueIcon} className={styles.BlueIcon}/>
          <div className={styles.WelcomeBackText}>Welcome Back</div>
          <div className={styles.LoginPageText}>Please login to use<span style={{color: '#3C96D4', fontFamily: 'Baloo Bhai', fontWeight: 'bold', fontSize: '16px'}}> MOMENT</span></div>
          <button onClick={() => handleLogin("redirect")} className={styles.SignInButton}>
            LOGIN
          </button>
        </div>

        {/* <div className={styles.SandboxLogo}>SANDBOX</div> */}
        <div className={styles.MomentLogo}>MOMENT</div>
      </div>
  );
};