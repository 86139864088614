import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";

// Redux

// ----------------------------------------------------------------------

let  initialState = {
  searchTerm: "",
  BidSwitch: false, 
  teamFilter: "",
  statusFilter: "Live",
  runnerFilter: "All",
  tagFilter: "",
  inputValue: "",
  sortColumn: "",
  sortDirection: "asc",
  currentPage: 1,
  scrollPos: 0,
  LastStatus: "Live",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {

    // set filter values in redux
    setsearchTerm(state, action) {
      state.searchTerm = action.payload;
    },

    setBidSwitch(state, action) {
      state.BidSwitch = action.payload;
    },

    setteamFilter(state, action) {
      state.teamFilter = action.payload;
    },

    setstatusFilter(state, action) {
      state.statusFilter = action.payload;
    },

    setrunnerFilter(state, action) {
      state.runnerFilter = action.payload;
    },

    settagFilter(state, action) {
      state.tagFilter = action.payload;
    },

    setinputValue(state, action) {
      state.inputValue = action.payload;
    },

    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },

    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setScrollPos: (state, action) => {
      state.scrollPos = action.payload;
    },

    setLastStatus: (state, action) => {
      state.LastStatus = action.payload;
    },

  },

});

export const {
  setsearchTerm,
  setBidSwitch,
  setteamFilter,
  setstatusFilter,
  setrunnerFilter,
  settagFilter,
  setinputValue,
  setSortDirection,
  setSortColumn,
  setCurrentPage,
  setScrollPos,
  setLastStatus,
} = filtersSlice.actions;

// Reducer

export default filtersSlice.reducer;