const styles = (theme) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    // border: 'white',
    borderRadius: "9px",
    border: "1px solid #3C96D4",
  },
  "&::placeholder": {
    fontWeight: "100",
  },

  "& .MuiOutlinedInput-root": {
    background: "white",
    padding:0,
    borderRadius: "9px",
  },
  input: {
    fontFamily: "PT Sans",
    height: "30px",
    padding: 0,
    paddingLeft: "7px",
    paddingRight: "4px",
    fontSize: "13px",
    fontWeight: "bold",
    width: "calc(100% - 11px)",
    resize: "none",
    border: "none",
    borderRadius: "9px",

    "-webkit-transition": "0.5s",
    transition: "0.2s",
    outline: "#D9D9D9",

    background: "white",
    "&::placeholder": {
      fontWeight: "100",
    },
  },
  textarea: {
    padding: '10px !important',
    fontFamily: "PT Sans",
    height: "30px",
    paddingLeft: "7px",
    paddingRight: "4px",
    fontSize: "13px",
    fontWeight: "bold",
    width: "calc(100% - 11px)",
    resize: "none",
    border: "none",
    borderRadius: "9px",

    "-webkit-transition": "0.5s",
    transition: "0.2s",
    outline: "#D9D9D9",

    background: "white",
    "&::placeholder": {
      fontWeight: "100",
    },
    text : {
      color:'#292929',
      textAlign: 'center',
      fontFamily: "PT Sans",
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    }
  },
});

export default styles;
