import React, { useState, useEffect } from "react";
import styles from "../../Components/Table/TableV2.module.css";
import Tick from "../../assets/Tick.svg";
import MoreOptionsV2 from "../../Components/MoreOptions/MoreOptionsV2.js";
import moment from "moment";

const PlannedInvoicedTable = ({
  jsonData,
  headerMap = {},
  clickableRowAdd = false,
  handleRowEdit,
  handleRowDelete,
}) => {
  console.log(jsonData, "jsonDatacoming");
  const resolvedHeaderMap = headerMap;
  const headers = Object.keys(resolvedHeaderMap).map(
    (key) => resolvedHeaderMap[key] || key
  );

  // Initially convert JSON array to rows format
  const initialRows = jsonData?.data?.map((item) =>
    Object.keys(resolvedHeaderMap) // Use the order from defaultHeaderMap
      .map((key) => item[key]?.toString() || "") // Convert each value to string, or empty string if undefined
  );
  console.log(initialRows, "test");
  const [rows, setRows] = useState(initialRows);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    const newRows = jsonData?.data?.map((item) =>
      Object.keys(resolvedHeaderMap) // Ensure the order is maintained
        .map((key) => item[key]?.toString() || "") // Convert to string or ''
    );
    setRows(newRows);
  }, [jsonData, resolvedHeaderMap]);

  const sortRows = (index) => {
    let key = index;
    let direction = "ascending";

    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedRows = [...rows].sort((a, b) => {
      let valA = a[index];
      let valB = b[index];

      if (valA === null || valB === null) return 0;

      if (index === 1) {
        valA = moment(valA).toDate();
        valB = moment(valB).toDate();

      } else if (typeof valA === "string" && valA.charAt(0) === "£") {
        valA = parseFloat(valA.substring(1).replace(/,/g, ""));
        valB = parseFloat(valB.substring(1).replace(/,/g, ""));
      } else if (typeof valA === "string" && !isNaN(valA.charAt(0))) {
        const matchA = valA.match(/\d+/);
        const matchB = valB.match(/\d+/);
        if (matchA && matchB) {
          valA = parseFloat(matchA[0]);
          valB = parseFloat(matchB[0]);
        }
      } else if (
        typeof valA === "string" &&
        /^(\d{2})\/(\d{2})\/(\d{2})$/.test(valA)
      ) {
        const [dayA, monthA, yearA] = valA.split("/").map(Number);
        const [dayB, monthB, yearB] = valB.split("/").map(Number);
        valA = new Date(yearA, monthA - 1, dayA).getTime();
        valB = new Date(yearB, monthB - 1, dayB).getTime();
      }

      if (valA < valB) return direction === "ascending" ? -1 : 1;
      if (valA > valB) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setRows(sortedRows);
  };

  const handleRowClick = (firstColumnValue) => {
    if (typeof clickableRowAdd === "string") {
      window.location.href = `${clickableRowAdd}#${firstColumnValue}`;
    }
  };

  const rowClasses = `${styles.tableRow} ${clickableRowAdd ? styles.rowHoverHighlight : ""
    }`;

  return (
    <div
      className={styles.tableContainer}
      style={{
        maxHeight: "300px",
        width: "100%",
      }}
    >
      <table className={styles.table} style={{
        width: "100%",
        overflowY: "scroll",
        overflowX: "auto",
      }}>
        <thead style={{}}>
          <tr
            className={styles.tableHeader}
            style={{ width: "100%", minWidth: "150px", whiteSpace: "nowrap" }}
          >
            {headers?.map((header, index) => {
              // Determine the sorting arrow
              let arrow = "";
              if (sortConfig.key === index) {
                arrow = sortConfig.direction === "ascending" ? " ▲" : " ▼";
              }

              if (index === 0) {
                return <th className={styles.th}></th>;
              }
              if (index == 1 || index == 3 || index == 4 || index == 5) {
                return (
                  <th key={index} className={styles.th}>
                    <div
                      className={styles.CenterContent}
                      onClick={() => sortRows(index)}
                    >
                      {header}
                      {arrow}
                    </div>
                  </th>
                );
              }

              return (
                <th key={index} className={styles.th}>
                  <div onClick={() => sortRows(index)}>
                    {header}
                    {arrow}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {rows?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowClasses}
              onClick={() => clickableRowAdd && handleRowClick(row[0])}
              style={{
                background: "white",
              }}
            >
              {row?.map((cell, cellIndex) => {
                if (cellIndex == 0) {

                  return (
                    <MoreOptionsV2
                      key={cellIndex}
                      canEdit={true}
                      onEdit={() => handleRowEdit(row, row[0], cellIndex)}
                      onDelete={
                        row[4] === null || row[4] === ""
                          ? () => handleRowDelete(row[0])
                          : null
                      }
                    />
                  );
                } else if (cellIndex == 5) {
                  return (
                    <td
                      key={cellIndex}
                      style={{
                        fontFamily: "'PT Sans', sans-serif",
                      }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>{cell}</div>
                    </td>
                  );
                } else if (cellIndex == 1) {
                  return (
                    <td
                      key={cellIndex}
                      style={{
                        fontFamily: "'PT Sans', sans-serif",
                      }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>
                        {moment(cell).format("MMM-YY")}
                      </div>
                    </td>
                  );
                }
                if (cellIndex == 2 || cellIndex == 3) {
                  return (
                    <td
                      key={cellIndex}
                      style={{ fontFamily: "'PT Sans', sans-serif" }}
                      className={styles.td}
                    >
                      <div className={styles.CenterContent}>
                        {parseFloat(cell).toLocaleString("en-UK", {
                          style: "currency",
                          currency: "GBP",
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </td>
                  );
                }

                if (cellIndex == 10) {
                  return (
                    <td key={cellIndex} className={styles.td}>
                      <div className={styles.CenterContent}>
                        {cell === "true" ? (
                          <img src={Tick} alt="Tick" />
                        ) : null}
                      </div>
                    </td>
                  );
                }

                return (
                  <td key={cellIndex} className={styles.td}>
                    <div className="ellipsis-div">{cell}</div>
                  </td>
                );
              })}
            </tr>
          ))}

          <tr className={styles.tableBottom} style={{ background: "#EAF2F9" }}>
            <td className={styles.td}></td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div className={styles.RightAlign} style={{ marginTop: "10px" }}>
                Total
              </div>
            </td>
            <td
              className={styles.td}
              style={{
                fontFamily: "'PT Sans', sans-serif",
                whiteSpace: "unset",
                fontWeight: "700",
              }}
            >
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {parseFloat(jsonData.totalPlannedAmount).toLocaleString(
                  "en-UK",
                  {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 2,
                  }
                )}
              </div>
            </td>
            <td className={styles.td}>
              <div
                className={styles.CenterContent}
                style={{ marginTop: "10px" }}
              >
                {parseFloat(jsonData.totalInvoicedAmount).toLocaleString(
                  "en-UK",
                  {
                    style: "currency",
                    currency: "GBP",
                    maximumFractionDigits: 2,
                  }
                )}
              </div>
            </td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
            <td className={styles.td}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PlannedInvoicedTable;
