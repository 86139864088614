import React, { useState, useEffect, useContext } from "react";
import {
  setStatusFilter,
  setSearchTerm,
} from "../../../redux/slices/opportunitiesdata";

import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from "../../../redux/store";
import AdminContext from "../../../Context/adminContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";

import Dropdown from "../../../Components/DropDownBox/Dropdown";

import styles from "./Opportunities.module.css";
import { Box, CircularProgress } from "@mui/material";

import {
  setinputValue,
  setCurrentPage,
  setSortDirection,
  setSortColumn,
  getConfiguration,
  getRunners,
  getOpportunities,
  createOpportunity,
} from "../../../redux/slices/opportunitiesdata";

import OppForm from "../../../Components/OppForms/OppForm";
import ApiResponseDisplay from "../../../Components/ApiResponseDisplay/ApiResponseDisplay";
import OppListTable from "../../../Sections/opportunities/OppListTable";
import ProjectTablePagination from "../../Projects/ProjectList/ProjectTablePagination";

function Opportunities() {
  const { accounts } = useMsal();
  const dispatch = useDispatch();
  const account = accounts[0];

  const admin = useContext(AdminContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    opportunities,
    inputValue,
    isOppListLoading,
    isNewOppLoading,
    isConfigLoading,
    error,
  } = useSelector((state) => state.opportunities);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [isResponseShown, setIsResponseShown] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const searchTerm = useSelector((state) => state.opportunities.searchTerm);
  const statusFilter = useSelector((state) => state.opportunities.statusFilter);
  const sortDirection = useSelector((state) => state.opportunities.sortDirection);
  const sortColumn = useSelector((state) => state.opportunities.sortColumn);
  const currentPage = useSelector((state) => state.opportunities.currentPage);
  let scrollPos = useSelector((state) => state.opportunities.scrollPos);

  const totalPages = opportunities.totalRows > 0 ? Math.ceil(opportunities.totalRows / rowsPerPage) : 0;
  const currentPageValue = opportunities.totalRows > 0 ? currentPage : 0;

  useEffect(() => {
    dispatch(getOpportunities(
      statusFilter.toLowerCase(),
      currentPage,
      50,
      searchTerm.toLowerCase(),
      sortColumn,
      sortDirection
    ));
    dispatch(getConfiguration());
    dispatch(getRunners());
    setPaginationLoading(false);
  }, [statusFilter, searchTerm, currentPage, sortColumn, sortDirection]);

  useEffect(() => {
    if (error && isResponseShown === false && error !== null) {
      console.log("WORKING");
      setIsResponseShown(true);
    }
  }, [error]);

  const handleFormSubmit = (formData) => {
    console.log("Form Data Submitted:", formData);

    const modifiedFormData = {
      ...formData,
      formType: "project_fee", // Add 'formType' key with value 'project_fee'
      completedBy: account.username, // Add 'completedBy' key with value 'account'
    };

    dispatch(createOpportunity(modifiedFormData));
  };

  const openOppForm = () => () => {
    setModalIsOpen(true);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      dispatch(setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortColumn(column));
      dispatch(setSortDirection('asc'));
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPaginationLoading(true);
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handlestatusFilter = (service) => {
    dispatch(setStatusFilter(service));
  };

  const handleSearch = (event) => {
    dispatch(setinputValue(event.target.value));
  };

  const handleSearchSubmit = () => {
    dispatch(setSearchTerm(inputValue));
    dispatch(setCurrentPage(1));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={styles.OpportunitiesMainContent}>
      <div className={styles.Header}>
        <div className={styles.OpportunitiesTitle}>Opportunities</div>
      </div>

      <div className={styles.OpportunitiesTableFilters}>
        <div className={styles.Filters}>
          <div className={styles.statusFilter}>
            <label
              className={styles.FilterLabel}
              htmlFor="statusFilter"
              style={{ fontSize: "12px" }}
            >
              Status:
            </label>
            <div className={styles.dropdownContainer}>
              <Dropdown
                options={["All", "Open", "Lost"]}
                initialSelectedValue={statusFilter}
                onValueChange={handlestatusFilter}
                disabled={isOppListLoading}
              />
            </div>
          </div>
        </div>

        <div className={styles.SearchBar}>
          {admin && (
            <React.Fragment>
              <div
                className={`${styles.AddBtn} ${isOppListLoading ? styles.AddDisabled : ""
                  }`}
                onClick={openOppForm()}
              >
                FP FORM
              </div>
            </React.Fragment>
          )}
          <SearchBar value={inputValue} onChange={handleSearch} onKeyDown={handleKeyDown} disabled={isOppListLoading} onClick={handleSearchSubmit} />
        </div>
      </div>

      {isOppListLoading || isConfigLoading || isNewOppLoading && (
        <div className="ProfileHolidayOverlay">
          <div
            className="ProfileHolidayResponseBox"
            style={{ borderRadius: "150px", minWidth: "160px" }}
          >
            <CircularProgress size={65} />
          </div>
        </div>
      )}
      {!isOppListLoading && !isConfigLoading && !isNewOppLoading && (
        <>
          {modalIsOpen && (
            <OppForm onSubmit={handleFormSubmit} onClose={closeModal} />
          )}
        </>
      )}
      {isOppListLoading ? (
        <>
          <OppListTable
            rows={[]}
            loading={isOppListLoading}
            handleSort={handleSort}
            sortDirection={sortDirection}
            sortColumn={sortColumn}
          />


          <ProjectTablePagination
            currentPage={currentPageValue}
            totalPages={totalPages}
            onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
            onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
            rowsShown={opportunities.rows.length}
            totalRows={opportunities.totalRows}
            rowsPerPage={rowsPerPage}
            loading={isOppListLoading}
          />
        </>
      ) : (
        <>
          <OppListTable
            rows={opportunities.rows}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            loading={isOppListLoading}
          />

          <div className='ProjectPagination'>
            <ProjectTablePagination
              currentPage={currentPageValue}
              totalPages={totalPages}
              onPrevPage={paginationLoading ? null : handlePrevPage} // disable if loading
              onNextPage={paginationLoading ? null : handleNextPage} // disable if loading
              rowsShown={opportunities.rows.length}
              totalRows={opportunities.totalRows}
              rowsPerPage={rowsPerPage}
              loading={isOppListLoading}
            />
          </div>

        </>
      )}
      {error && error !== null && (
        <ApiResponseDisplay
          apiResponse={error}
          setIsResponseShown={setIsResponseShown}
        />
      )}
    </div>
  );
}

export default Opportunities;
