import React from 'react';
import styles from './TickBox.module.css';

function TickBox({type, tickClick, usable=true, coloureis=''}) {
  const isSectionVisible = true
  return (
    <>
      {usable ?
        <div className={styles.box} onClick={tickClick} style={{borderColor: coloureis}}>
          {type && <div className={styles.positionArrow} style={{marginTop: '10px', marginLeft: '7px'}}>
            <div className={styles.arrow} style={{transform: 'translateY(-50%) rotate(45deg)'}}></div>
          </div>}
        </div>
        :
        <div className={styles.boxNotUsable} style={{borderColor: coloureis}}>
          {type && <div className={styles.positionArrow} style={{marginTop: '10px', marginLeft: '7px'}}>
            <div className={styles.arrow} style={{transform: 'translateY(-50%) rotate(45deg)'}}></div>
          </div>}
        </div>
      }
    </>
  );
}

export default TickBox;