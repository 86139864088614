// Projects.js
import { React, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Topbar from '../../Components/Topbar/Topbar';;

export const TopbarData = [
  {
    title: 'Information',
    link: '/projects/ProjectProfile?scroll=Information',
    disabled: false,
  },
  {
    title: 'Log Book',
    link: '/projects/ProjectProfile?scroll=LogBook',
    disabled: false,
  },
  {
    title: 'Timesheets',
    link: '#Timesheets',
    disabled: true,
  },
];

function Projects() {
  const location = useLocation();

  const shouldShowProfileContainer = location.pathname.includes('ProjectProfile');

  return shouldShowProfileContainer ? (
    <div>
      {/* <Topbar TopbarData={TopbarData} /> */}
      <Outlet />
    </div>
  ) : (
    <Outlet />
  );
}

export default Projects;