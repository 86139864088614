// Suppliers.js
import React from 'react';
import styles from './Suppliers.module.css';
import { Outlet } from 'react-router-dom';

function Suppliers() {
  return (
    <Outlet />
  );
}

export default Suppliers;