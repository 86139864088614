import React, { useState } from 'react';
import EditIcon from '../../assets/Edit.svg';
import styles from './List.module.css'

function List({data, editable, handleEdit, message}) {
  const [isSectionVisible, setSectionVisible] = useState(false);

  const toggleSection = () => {
    setSectionVisible(!isSectionVisible);
  }

  const checkIfValid = (value) => {
    return value !== null && value !== undefined && value !== "";
  }

  return (
    <div className={styles.infoBox}>
      <ul className={styles.thinList}>
        {data.DataEdit.map(({dataName, data}) => {
        return (
          <li className={styles.dataRow}>
            <div className={styles.dataName}>{dataName + ':'}</div>
            <div className={checkIfValid(data) ? styles.data : styles.noData}>{checkIfValid(data) ? data : message}</div>
          </li>
        )})}
      </ul>

      {editable && <img className={styles.editIcon} src={EditIcon} onClick={handleEdit(data)} alt="EditIcon"/>}
    </div>
  );
}

export default List;