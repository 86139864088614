import React, { useState, useEffect, useRef } from 'react';
import styles from '../../../Components/DropDownSelect/Dropdown.module.css';

function EmpInfoStatusFilter(props) {
  const statuses = [
    { value: '', label: 'All' },
    { value: 'CURRENT', label: 'Current' },
    { value: 'LEAVER', label: 'Leaver' }
  ];

  const handleSelect = (event) => {
    props.onSelect(event.target.value);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(statuses[1].label);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    handleSelect({ target: { value: option.value } });
  };

  const handleClickOutside = (event) => {
    if (!props.disabled && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!props.disabled && dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, props.disabled]);

  return (
    <div className={`${styles.dropdown} ${props.disabled ? styles.dropdownDisabled : ''}`} ref={dropdownRef}>
      <div className={styles.dropdownSelected} onClick={toggleDropdown}>
        {selectedOption}
      </div>
      {isOpen && (
        <div className={styles.dropdownOptions}>
          {statuses.map((status) => (
            <div key={status.value} className={styles.dropdownOption} onClick={() => selectOption(status)}>
              {status.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EmpInfoStatusFilter;
