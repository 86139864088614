import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";
import CommentBox from "../CommentBox/CommentBox";
import ClientForm from "../ClientForm/ClientForm";
import { dispatch } from "../../redux/store";
// THIS V2 ALLOWS ID VALUES TO BE PASSED THROUGH WITH THE OPTIONS

import { useDispatch, useSelector } from "../../redux/store";
import { createClient } from "../../redux/slices/opportunitiesdata";

// Dropdown mode
const Dropdown = ({
  options,
  initialSelectedValue = [],
  onValueChange,
  options_width = "100%",
  PopUp = false,
  disabled = false,
  mode = "single",
  showOnlyOptions = false,
  searchable = false,
  onSearchChange,
  onAddNew = null,
  addNew = false,
  clientType = null,
  placeholder = "Select...",
  addNewEnabled = true,
  errorState = false,
}) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
  const [isOpen, setIsOpen] = useState(showOnlyOptions);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);
  const { newClient } = useSelector((state) => state.opportunities);

  const [addNewPopUp, setAddNewPopUp] = useState(false);
  useEffect(() => {
    if (isOpen) {
      dropdownRef.current.style.zIndex = 6;
    } else {
      dropdownRef.current.style.zIndex = 5;
    }
  }, [isOpen]);

  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (onSearchChange) {
      onSearchChange(e.target.value, clientType);
    }
  };

  useEffect(() => {
    // If the searchTerm is empty, we don't want to call the onSearchChange function.
    if (searchTerm === "") {
      return;
    }

    const timeoutId = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(searchTerm, clientType);
      }
    }, 500); // 500 ms delay

    // Cleanup function that clears the timeout when searchTerm changes or the component unmounts
    return () => clearTimeout(timeoutId);

    // Important: `onSearchChange` should be a stable function, not re-created on each parent render
    // If `onSearchChange` is defined in a parent component, it should be memoized with useCallback
  }, [searchTerm]); // Only re-run the effect if searchTerm changes

  const getFilteredOptions = () => {
    if (searchable && searchTerm && Array.isArray(options)) {
      return options.filter(
        (option) =>
          option.label &&
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return options;
  };

  const filteredOptions = getFilteredOptions();

  // Add new function
  const handleAddNewOption = () => {
    if (addNew) {
      setAddNewPopUp(true);
    }
    // if (onAddNew) {
    //   onAddNew(searchTerm);
    //   setSearchTerm('');
    //   setIsOpen(false);
    // }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectOption = (option) => {
    // For multi-select mode
    if (mode === "multi") {
      let newSelectedValue;
      const isIncluded = selectedValue.some(
        (selected) => selected.value === option.value
      );

      if (isIncluded) {
        newSelectedValue = selectedValue.filter(
          (selected) => selected.value !== option.value
        );
      } else {
        newSelectedValue = [...selectedValue, option];
      }

      setSelectedValue(newSelectedValue);
      if (onValueChange) {
        onValueChange(newSelectedValue);
      }
    }
    // For single-select mode
    else {
      if (searchable) {
        setSearchTerm(option.label);
      } else {
        setSearchTerm(option);
      }
      setSelectedValue(option);
      if (onValueChange) {
        onValueChange(option); // Send the selected object for single-select
      }
      setIsOpen(false); // Close the dropdown for single-select mode after selection
    }
  };

  const handleClickOutside = (event) => {
    if (!dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Perform the search or call the function that you want to debounce
      if (searchTerm) {
        // Call the function to handle the search term
        const filteredOptions = getFilteredOptions();
        // Do something with the filtered options
      }
    }, 500); // 500 ms delay

    // Cleanup function to cancel the timeout if the component unmounts
    // or if the value of searchTerm changes before the timeout completes
    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleAddNewClientSubmit = (formData) => {
    dispatch(createClient(formData));
    setAddNewPopUp(false);
    setSearchTerm("");
    setSelectedValue("");
    console.log("called");
  };

  return (
    <>
      <div
        className={`${styles.dropdown} ${
          disabled ? styles.dropdownDisabled : ""
        } ${showOnlyOptions ? styles.dropdownAbsolute : ""}`}
        ref={dropdownRef}
      >
        {!showOnlyOptions && (
          <div
            className={`${styles.dropdownSelected} ${
              errorState ? styles.errorState : ""
            } `}
            onClick={toggleDropdown}
          >
            {searchable ? (
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={placeholder} // Use placeholder prop here
                className={styles.invisibleInput}
                disabled={disabled}
              />
            ) : (
              <div
                className={`${
                  PopUp && mode !== "multi"
                    ? styles.noOverflow
                    : styles.dropdownSelectedText
                } ${mode === "multi" ? styles.multiOverflow : ""}`}
              >
                {
                  mode === "multi" ? (
                    selectedValue.length > 0 ? (
                      selectedValue.map((val) => val.label).join("; ") // For multi-select, join the labels
                    ) : (
                      <span className={styles.placeholderColor}>
                        {placeholder}
                      </span>
                    )
                  ) : (
                    selectedValue.label || (
                      <span className={styles.placeholderColor}>
                        {placeholder}
                      </span>
                    )
                  ) // For single-select, use the label property
                }
              </div>
            )}
          </div>
        )}
        {(isOpen || showOnlyOptions) && !disabled && (
          <div
            className={`${styles.dropdownOptions} ${
              showOnlyOptions ? styles.dropdownOptionsAbsolute : ""
            }`}
            style={{ width: options_width }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.id} // Use the 'id' as the key for each option
                  className={styles.dropdownOption}
                  onMouseDown={() => selectOption(option)}
                >
                  {mode === "multi" && (
                    <div
                      className={
                        selectedValue.some(
                          (selected) => selected.id === option.id
                        )
                          ? styles.selectedSquare
                          : styles.unselectedSquare
                      }
                    ></div>
                  )}
                  <span
                    className={mode === "multi" ? styles.multiTextShift : ""}
                  >
                    {option.label}
                  </span>
                </div>
              ))
            ) : addNewEnabled ? (
              <div
                className={styles.dropdownOption}
                onClick={handleAddNewOption}
              >
                Add New: "{searchTerm}"
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {addNewPopUp && (
        <ClientForm
          Name={searchTerm}
          onSubmit={handleAddNewClientSubmit}
          onClose={() => setAddNewPopUp(false)}
        />
      )}
    </>
  );
};

export default Dropdown;
