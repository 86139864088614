import React, { useState, useEffect } from 'react';
import styles from './RevieweeForm.module.css';
import BlueLogo from '../../assets/BlueLogo.png';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import TickBox from '../TickBox/TickBox';
import CommentBox from '../CommentBox/CommentBoxV2';

function RevieweeForm({data, handleFormSubmit, secretFormSubmit, setDisplayProfileForm, actionType}) {
  const [selectedValues, setSelectedValues] = useState({});
  const [ticks, setTicks] = useState({ PatternYes: false, PatternNo: false, WorkYes: false, WorkNo: false, Confirmation: false });
  const [errorColors, setErrorColors] = useState({});
  const [satisfied, setSatisfied] = useState(true);
  const [docInfo, setDocInfo] = useState(null);
  
  const [PatternYesTick, setPatternYesTick] = useState(false);
  const [PatternNoTick, setPatternNoTick] = useState(false);

  const toggleTick = (tickName) => {
    setTicks({ ...ticks, [tickName]: !ticks[tickName] });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrorColors = {};

    if (Comment1.trim() === '') {
      setComment1Colour('#D32030');
      isValid = false;
    } else {setComment1Colour('#3C96D4');}
    
    if (Comment2.trim() === '') {
      setComment2Colour('#D32030');
      isValid = false;
    } else {setComment2Colour('#3C96D4');}
    
    if (Comment3.trim() === '') {
      setComment3Colour('#D32030');
      isValid = false;
    } else {setComment3Colour('#3C96D4');}

    if (Comment4.trim() === '') {
      setComment4Colour('#D32030');
      isValid = false;
    } else {setComment4Colour('#3C96D4');}
    
    if (Comment5.trim() === '') {
      setComment5Colour('#D32030');
      isValid = false;
    } else {setComment5Colour('#3C96D4');}
    
    if (Comment6.trim() === '') {
      setComment6Colour('#D32030');
      isValid = false;
    } else {setComment6Colour('#3C96D4');}
    
    if (Comment7.trim() === '') {
      setComment7Colour('#D32030');
      isValid = false;
    } else {setComment7Colour('#3C96D4');}
    
    if (Comment8.trim() === '') {
      setComment8Colour('#D32030');
      isValid = false;
    } else {setComment8Colour('#3C96D4');}
    
    if (Comment9.trim() === '') {
      setComment9Colour('#D32030');
      isValid = false;
    } else {setComment9Colour('#3C96D4');}
    
    if (Comment10.trim() === '') {
      setComment10Colour('#D32030');
      isValid = false;
    } else {setComment10Colour('#3C96D4');}
    
    if (Comment11.trim() === '') {
      setComment11Colour('#D32030');
      isValid = false;
    } else {setComment11Colour('#3C96D4');}
    
    if (Comment12.trim() === '') {
      setComment12Colour('#D32030');
      isValid = false;
    } else {setComment12Colour('#3C96D4');}
    
    if (confirmationTick == false) {
      setColoureis('#D32030')
      isValid = false;
    } else {setColoureis('#3C96D4');}
    
    if ((PatternYesTick == false) && (PatternNoTick == false)) {
      setPatternColoureis('#D32030')
      isValid = false;
    } else {setPatternColoureis('#3C96D4');}
    
    // Add more validation logic as needed
    setErrorColors(newErrorColors);
    setSatisfied(isValid);
    return isValid;
  };

  const handleSubmissionClick = () => {
    if (validateForm()) {
      console.log('ALL GUCCHIII')
      let tickValue = 'NEITHER';
      if (PatternYesTick) {
        tickValue = 'YES'
      }
      if (PatternNoTick) {
        tickValue = 'NO'
      }
      let information = {
        'Comment1': Comment1,
        'Comment2': Comment2,
        'Comment3': Comment3,
        'Comment4': Comment4,
        'Comment5': Comment5,
        'Comment6': Comment6,
        'Comment7': Comment7,
        'Comment8': Comment8,
        'Comment9': Comment9,
        'Comment10': Comment10,
        'Comment11': Comment11,
        'Comment12': Comment12,
        'Comment13': tickValue,
      }
      handleFormSubmit('Employee Annual Review', selectedValues, 'SUBMIT', information)
    }
  };

  const handleSecretDraftClick = () => {
    let tickValue = 'NEITHER';
    if (PatternYesTick) {
      tickValue = 'YES'
    }
    if (PatternNoTick) {
      tickValue = 'NO'
    }
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
      'Comment4': Comment4,
      'Comment5': Comment5,
      'Comment6': Comment6,
      'Comment7': Comment7,
      'Comment8': Comment8,
      'Comment9': Comment9,
      'Comment10': Comment10,
      'Comment11': Comment11,
      'Comment12': Comment12,
      'Comment13': tickValue,
    }
    console.log('information:  ', information)
    console.log('Comment7:  ', Comment7)
    secretFormSubmit('Employee Annual Review', selectedValues, 'DRAFT', information)
  };

  const handleDraftClick = () => {
    let tickValue = 'NEITHER';
    if (PatternYesTick) {
      tickValue = 'YES'
    }
    if (PatternNoTick) {
      tickValue = 'NO'
    }
    let information = {
      'Comment1': Comment1,
      'Comment2': Comment2,
      'Comment3': Comment3,
      'Comment4': Comment4,
      'Comment5': Comment5,
      'Comment6': Comment6,
      'Comment7': Comment7,
      'Comment8': Comment8,
      'Comment9': Comment9,
      'Comment10': Comment10,
      'Comment11': Comment11,
      'Comment12': Comment12,
      'Comment13': tickValue,
    }
    handleFormSubmit('Employee Annual Review', selectedValues, 'DRAFT', information)
    setDisplayProfileForm(false);
  };

  const handleClose = () => {
    setDisplayProfileForm(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  const [Comment1, setComment1] = useState(data.Q1);
  const [Comment1Colour, setComment1Colour] = useState('');
  
  const [Comment2, setComment2] = useState(data.Q2);
  const [Comment2Colour, setComment2Colour] = useState('');
  
  const [Comment3, setComment3] = useState(data.Q3);
  const [Comment3Colour, setComment3Colour] = useState('');
  
  const [Comment4, setComment4] = useState(data.Q4);
  const [Comment4Colour, setComment4Colour] = useState('');
  
  const [Comment5, setComment5] = useState(data.Q5);
  const [Comment5Colour, setComment5Colour] = useState('');
  
  const [Comment6, setComment6] = useState(data.Q6);
  const [Comment6Colour, setComment6Colour] = useState('');
  
  const [Comment7, setComment7] = useState(data.Q7);
  const [Comment7Colour, setComment7Colour] = useState('');
  
  const [Comment8, setComment8] = useState(data.Q8);
  const [Comment8Colour, setComment8Colour] = useState('');
  
  const [Comment9, setComment9] = useState(data.Q9);
  const [Comment9Colour, setComment9Colour] = useState('');
  
  const [Comment10, setComment10] = useState(data.Q10);
  const [Comment10Colour, setComment10Colour] = useState('');
  
  const [Comment11, setComment11] = useState(data.Q11);
  const [Comment11Colour, setComment11Colour] = useState('');
  
  const [Comment12, setComment12] = useState(data.Q12);
  const [Comment12Colour, setComment12Colour] = useState('');

  const [coloureis, setColoureis] = useState('');
  const [confirmationTick, setConfirmationTick] = useState(false);


  useEffect(() => {
    // Set up the interval
    const intervalId = setInterval(() => {
      handleSecretDraftClick();
    }, 6000); // 60,000 milliseconds = 60 seconds

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [
    Comment1,
    Comment2,
    Comment3,
    Comment4,
    Comment5,
    Comment6,
    Comment7,
    Comment8,
    Comment9,
    Comment10,
    Comment11,
    Comment12,
    PatternYesTick,
    PatternNoTick
    // add any other dependencies here
  ]);


  const handleTickClick_confirmation = () => {
    if (confirmationTick == false) {
      setConfirmationTick(true)
      setColoureis('')
    } else if (confirmationTick) {
      setConfirmationTick(false)
      setColoureis('')
    }
    console.log('confirmation: ', confirmationTick)
  };

  useEffect(() => {
    if (data.Q13 == 'YES') {
      setPatternYesTick(true)
    }
    if (data.Q13 == 'NO') {
      setPatternNoTick(true)
    }
  }, [data]);

  const [PatternColoureis, setPatternColoureis] = useState('');
  const handleTickClick_PatternYes = () => {
    if (PatternYesTick == false) {
      setPatternYesTick(true)
      setPatternNoTick(false)
      setPatternColoureis('')
    } else if (PatternYesTick) {
      setPatternYesTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };
  const handleTickClick_PatternNo = () => {
    if (PatternNoTick == false) {
      setPatternNoTick(true)
      setPatternYesTick(false)
      setPatternColoureis('')
    } else if (PatternNoTick) {
      setPatternNoTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };

  return (
    <div className={styles.backGround} onClick={handleDraftClick}>
      <div className={styles.form} onClick={handleFormClick}>
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Employee Annual Review</div>

        <div className={styles.BlackText} style={{marginBottom: '14px'}}>The below form is to be completed by the employee ahead of their biannual review.</div>
        <div className={styles.BlackText}>Please answer these questions in line with the company values of <span className={styles.BoldBlueText}>Authenticity</span> and <span className={styles.BoldBlueText}>Transparency</span> to ensure the answers provided are a true reflection of how you are getting on at Blue.</div>
        

        <div className={styles.QuestionSection}>How are you doing?</div>

        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            1.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              What targets were you set in your last review? Write your targets in full and comment either how you have met them, or what you need to do to meet them.
            </div>
            <CommentBox initialData={Comment1} onValueChange={setComment1} liningColour={Comment1Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            2.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              What have you been up to since your last review? Have your responsibilities changed? Which skills have you developed/consolidated?
            </div>
            <CommentBox initialData={Comment2} onValueChange={setComment2} liningColour={Comment2Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            3.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              How have you found the difficulty of the work you have been undertaking? Would you like to change it?
            </div>
            <CommentBox initialData={Comment3} onValueChange={setComment3} liningColour={Comment3Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            4.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Do you have an Internal Project, if so what is it? How is it going? What is the next milestone?
            </div>
            <CommentBox initialData={Comment4} onValueChange={setComment4} liningColour={Comment4Colour} />
          </div>
        </div>


        <div className={styles.QuestionSection}>How are you progressing?</div>

        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            5.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              What are you aiming to achieve in the next 3 months? What do you need to do/learn in order to achieve it?
            </div>
            <CommentBox initialData={Comment5} onValueChange={setComment5} liningColour={Comment5Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            6.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              What can Blue do more of to help you progress your career? What would you like to learn next?
            </div>
            <CommentBox initialData={Comment6} onValueChange={setComment6} liningColour={Comment6Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            7.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              How is your IPD/Uni/College course going? When are you taking your next professional qualification? If you are not at Uni, how are you progressing your skills?
            </div>
            <CommentBox initialData={Comment7} onValueChange={setComment7} liningColour={Comment7Colour} />
          </div>
        </div>

        <div className={styles.QuestionSection}>How are we doing?</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            8.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              How do you feel you are managed? Is it too loose, micromanaged, not enough clarity, target too hard etc.
            </div>
            <CommentBox initialData={Comment8} onValueChange={setComment8} liningColour={Comment8Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            9.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              How do you find the communication within your team? What do you like? What would you change?
            </div>
            <CommentBox initialData={Comment9} onValueChange={setComment9} liningColour={Comment9Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            10.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              How do you find the communication within Blue? Do you know about the direction of the company? Are roll outs of new process and systems clear and understandable?
            </div>
            <CommentBox initialData={Comment10} onValueChange={setComment10} liningColour={Comment10Colour} />
          </div>
        </div>


        <div className={styles.QuestionSection}>Level</div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            11.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
              Would you like your level reviewed? Why?
            </div>
            <div className={styles.YesNoTicks} style={{marginBottom: '10px'}}>
              <TickBox type={PatternYesTick} tickClick={handleTickClick_PatternYes} coloureis={PatternColoureis}/>
              <div className={styles.Yes}>Yes</div>
              <TickBox type={PatternNoTick} tickClick={handleTickClick_PatternNo} coloureis={PatternColoureis}/>
              <div className={styles.No}>No</div>
            </div>
            <CommentBox initialData={Comment11} onValueChange={setComment11} liningColour={Comment11Colour} />
          </div>
        </div>
        <div className={styles.NumAndQandA}>
          <div className={styles.GrayNum}>
            12.
          </div>
          <div className={styles.QandA}>
            <div className={styles.GrayQuestion}>
            Is there anything else you would like to discuss?
            </div>
            <CommentBox initialData={Comment12} onValueChange={setComment12} liningColour={Comment12Colour} />
          </div>
        </div>

        <div className={styles.confirmationSection}>
          <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', whiteSpace: 'nowrap', width: '200px'}}>I confirm the above information is correct</div>
          <div className={styles.YesNoTicks}>
            <TickBox type={confirmationTick} tickClick={handleTickClick_confirmation} coloureis={coloureis}/>
          </div>
        </div>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.DraftButton} onClick={handleDraftClick}>SAVE DRAFT</div>
          <div className={styles.updateButton} onClick={handleSubmissionClick}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <div className={styles.BlueLogoSection}><img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/></div>
      </div>
    </div>
  );
}

export default RevieweeForm;
