import React, { useState, useEffect } from 'react';
import styles from './AdminForm.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';
import TickBox from '../TickBox/TickBox';
import BlueLogo from '../../assets/BlueLogo.png';
import CommentBox from '../CommentBox/CommentBox';

function AdminForm({data, handleFormSubmit, setDisplayAdminForm, actionType}) {
  const [selectedValues, setSelectedValues] = useState({});
  const updateSelectedValue = (dataName) => (newValue) => {
    console.log(`Updating ${dataName} with ${newValue}`); // add this line
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [dataName]: newValue
    }));
  };

  const handleClose = () => {
    setDisplayAdminForm(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  const [adminComment, setAdminComment] = useState('');
  const [PatternYesTick, setPatternYesTick] = useState(false);
  const [PatternNoTick, setPatternNoTick] = useState(false);

  useEffect(() => {
    if (data.Pattern == 'YES') {
      setPatternYesTick(true)
    } else if (data.Pattern == 'NO') {
      setPatternNoTick(true)
    }
  }, []);

  useEffect(() => {
    if (data.Work == 'YES') {
      setPatternYesTick(true)
    } else if (data.Work == 'NO') {
      setPatternNoTick(true)
    }
  }, []);

  const handleTickClick_PatternYes = () => {
    if (PatternYesTick == false) {
      setPatternYesTick(true)
      setPatternNoTick(false)
    } else if (PatternYesTick) {
      setPatternYesTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };
  const handleTickClick_PatternNo = () => {
    if (PatternNoTick == false) {
      setPatternNoTick(true)
      setPatternYesTick(false)
    } else if (PatternNoTick) {
      setPatternNoTick(false)
    }
    console.log('PatternYesTick: ', PatternYesTick)
    console.log('PatternNoTick: ', PatternNoTick)
  };

  const [coloureis, setColoureis] = useState('');
  const [confirmationTick, setConfirmationTick] = useState(false);
  const handleTickClick_confirmation = () => {
    if (confirmationTick == false) {
      setConfirmationTick(true)
      setColoureis('')
    } else if (confirmationTick) {
      setConfirmationTick(false)
      setColoureis('')
    }
    console.log('confirmation: ', confirmationTick)
  };
  const [satisfied, setSatisfied] = useState(true);
  const handleSubmissionClick = () => {
    console.log('selectedValues: ', selectedValues)
    setSatisfied(true)

    if (confirmationTick == false) {
      setColoureis('#D32030')
      setSatisfied(false)
    }
    
    if (confirmationTick & satisfied) {
      handleFormSubmit(adminComment)
    }
  };

  return (
    <div className={styles.backGround} onClick={handleClose}>
      <div className={styles.form} onClick={handleFormClick}>
        <div className={styles.momentTitle}>MOMENT</div>
        <div className={styles.sectionTitle}>Admin Return To Work Form</div>

        <div className={styles.dataSection}>
          <div className={styles.row}>
            <div className={styles.dataTitle}>Name:</div>
            <div className={styles.NonEditable}>{data.Name}</div>
          </div>

          <div className={styles.doubleRow}>
            <div className={styles.row}>
              <div className={styles.dataTitle}>First Date of Absence:</div>
              <div className={styles.doubleRowNonEditable}>{data.StartDate}</div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleRowDataTitle}>Return to Work Date:</div>
              <div className={styles.doubleRowNonEditable}>{data.RTWdate}</div>
            </div>
          </div>

          <div className={styles.doubleRow}>
            <div className={styles.row}>
              <div className={styles.dataTitle}>Last Date of Absence:</div>
              <div className={styles.doubleRowNonEditable}>{data.EndDate}</div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleRowDataTitle}>No. of Working Days Absent:</div>
              <div className={styles.doubleRowNonEditable}>{data.Length}</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitleWithDescription}>
              <div className={styles.dataTitleWithExplanation}>Reason For Absence:</div>
              <div className={styles.Explanation}>
                If sick, please specify illness
                <br /><br />
                If injured, please specify injury and how it occurred
              </div>
            </div>
            <div className={styles.LongTExt}>{data.Reason}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>Is this absence part of an overall pattern?</div>
            <div className={styles.YesNoTicks}>
              <TickBox type={PatternYesTick} tickClick={handleTickClick_PatternYes} usable={false} />
              <div className={styles.Yes}>Yes</div>
              <TickBox type={PatternNoTick} tickClick={handleTickClick_PatternNo} usable={false} />
              <div className={styles.No}>No</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>If you answered Yes, please explain how:</div>
            <div className={styles.LongTExt}>{data.PatternExplanation}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>Has work contributed to your absence?</div>
            <div className={styles.YesNoTicks}>
              <TickBox type={PatternYesTick} tickClick={handleTickClick_PatternYes} usable={false} />
              <div className={styles.Yes}>Yes</div>
              <TickBox type={PatternNoTick} tickClick={handleTickClick_PatternNo} usable={false} />
              <div className={styles.No}>No</div>
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px'}}>If you answered Yes, please explain how we can support you more moving forward:</div>
            <div className={styles.LongTExt}>{data.PatternExplanation}</div>
          </div>

          
          <div className={styles.row}>
            <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', alignItems: 'baseline'}}>Admin Comment:</div>
            <div className={styles.LongTExt} style={{marginRight: '5px'}} >
              <CommentBox initialData={adminComment} onValueChange={setAdminComment} />
            </div>
          </div>
        </div>

        <div className={styles.row} style={{marginTop: '10px', whiteSpace: 'nowrap'}}>
          <div className={styles.dataTitle} style={{margin: '10px 25px 10px 25px', whiteSpace: 'nowrap'}}>I confirm the above information is correct</div>
          <div className={styles.YesNoTicks}>
            <TickBox type={confirmationTick} tickClick={handleTickClick_confirmation} coloureis={coloureis} />
          </div>
        </div>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleSubmissionClick}>
            <div className={buttonStyles.YesButtonComponentStyling}>{actionType}</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>CANCEL</div>
          </div>
        </div>

        <img className={styles.blueLogo} src={BlueLogo} alt="BlueLogo"/>
      </div>
    </div>
  );
}

export default AdminForm;