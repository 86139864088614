import React, { useState, useEffect } from 'react';
import styles from '../Holiday/Holiday.module.css';
import CompanyCalander from "../../../Components/ResourceCalander/ResourceCalander";
import BookedLeaveTable from './BookedLeaveTable';
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import '../../../Components/YesNoButtonsCSS/YesNoButtons.module.css';
import Table from '../../../Components/Table/TableV2.js'

function Holiday() {
  const [reload, setReload] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [rows, setRows] = useState([]); 
  const [holidayOverviewRows, setHolidayOverviewRows] = useState([]); 
  const { accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const account = accounts[0]; 
  const [apiResponse, setApiResponse] = useState(null);
  const [isResponseShown, setIsResponseShown] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [calendarData, setCalendarData] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setLoading(true)
    const fetchRequestedLeaveList = fetch(`${API_URL}/api/AdminHoliday/RequestedLeaveList?ApproversEmail=${encodeURIComponent(account.username)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    })

    const fetchCalendarData = fetch(`${API_URL}/api/CalendarData`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    });

    const fetchHolidayOverview = fetch(`${API_URL}/api/Admin/Leave/HolidayOverviewRows`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    });

    Promise.all([fetchRequestedLeaveList, fetchCalendarData, fetchHolidayOverview])
    .then(responses => Promise.all(responses.map(response => response.json())))
    .then(([fetchedRequestedLeaveList, fetchedCalendarData, fetchHolidayOverview]) => {
        setRows(fetchedRequestedLeaveList);
        setHolidayOverviewRows(fetchHolidayOverview);
        setCalendarData(fetchedCalendarData);
        setIsDataFetched(true);
    });

    setLoading(false)
  }, [reload]);

  const handleApproval = async (id) => {
    setLoading(true);
    try {
    const response = await fetch(`${API_URL}/api/AdminHoliday/ApprovingLeaveRequest?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({leaveID: id, ApproversEmail: account.username}),
    })
    if (response.ok) {
      const data = await response.json();
      setApiResponse(data.message); // We only need the message part of the response
    } else {
      setApiResponse('Error Approving Leave Request (backend ran into a logical issue, please let a DevOps engineer know)');
    }
    setReload(reload + 1);
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    } finally {
      setLoading(false);
    }
    setIsResponseShown(true);
  };

  const handleRejection = async (id, RejectionComment) => {
    setLoading(true);
    try {
    const response = await fetch(`${API_URL}/api/AdminHoliday/RejectingLeaveRequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({leaveID: id, ApproversEmail: account.username, comment: RejectionComment}),
    })
    if (response.ok) {
      const data = await response.json();
      setApiResponse(data.message); // We only need the message part of the response
    } else {
      setApiResponse('Error Rejecting Leave Request (backend ran into a logical issue, please let a DevOps engineer know)');
    }
    setReload(reload + 1);
    } catch (error) {
      console.error("Error in handleCancelationReload:", error);
    } finally {
      setLoading(false);
    }
    setIsResponseShown(true);
  };

  const CalanderAPItrigger = async (changeData) => {
    try {
      const queryParams = new URLSearchParams(changeData).toString();
      const response = await fetch(`${API_URL}/api/CalendarData?${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCalendarData(data);
      }
    } catch (error) {
      console.error('Error:', error);
      setCalendarData('Error occurred');
    }
  }

  return (
    <>
      {isDataFetched ? (
        <div className={styles.mainContent}>
          <div className={styles.content}>
            <div className={styles.title}>Holiday Booking</div>
          </div>
          <div className={styles.approvalsHolidayManagment}>
            <div className={styles.bookedLeaveBox} style={{ display: isDataFetched ? 'block' : 'none' }}> 
              <BookedLeaveTable rows={rows} handleApproval={handleApproval} handleRejection={handleRejection}/>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Holiday Overview</div>
          </div>
          <Table data={holidayOverviewRows}/>
          
          
          { loading ? // Here is the code for displaying error/success messages
              <div className={styles.ProfileHolidayOverlay}>
                <CircularProgress size={65} />
              </div>
              : 
              isResponseShown && (
                <div className={styles.ProfileHolidayOverlay}>
                  <div className={styles.ProfileHolidayResponseBox}>
                  <div
                    style={{
                      fontSize: 16,
                      fontFamily: 'PT Sans',
                      textAlign: 'center',
                      margin: '20px',
                      marginLeft: '25px',
                      marginRight: '25px'
                    }}
                  
                    dangerouslySetInnerHTML={{ __html: apiResponse }}
                    >
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button className={styles.ProfileHolidayButton}
                      onClick={() => setIsResponseShown(false)}
                    >
                      OK
                    </button>
                  </div>
                  </div>
                </div>
                )}
        </div>
      ) : (
        <Box sx={{display: 'flex', alignItems: 'flex-start', paddingTop: '250px', justifyContent: 'center'}}>
          <CircularProgress size={65} />
        </Box>
      )}
    </>
  );
}

export default Holiday;