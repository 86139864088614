import React, { useState, useEffect, useRef } from 'react';
import styles from '../DropDownSelect/Dropdown.module.css';

function Filter(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.selectedOption);
  const dropdownRef = useRef(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props.selectedOption]);

  useEffect(() => {
    setOptions(props.Options);
  }, [props.Options]);

  const handleSelect = (event) => {
    props.onSelect(event.target.value);
  };

  const toggleDropdown = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option.name);
    setIsOpen(false);
    handleSelect({ target: { value: option.value } });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!props.disabled && dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, props.disabled]);

  return (
    <div className={`${styles.dropdown} ${props.disabled ? styles.dropdownDisabled : ''}`} ref={dropdownRef}>
      <div className={styles.dropdownSelected} onClick={toggleDropdown}>
        {selectedOption}
      </div>
      {isOpen && (
        <div className={styles.dropdownOptions}>
          {options.map((option, index) => (
            <div key={index} className={styles.dropdownOption} onClick={() => selectOption(option)}>
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Filter;
