import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import styles from '../../../Components/DropDownSelect/Dropdown.module.css';

function EmpInfoTeamFilter(props) {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All'); // Initialize with 'All'
  const dropdownRef = useRef(null);
  const [teamOptions, setTeamOptions] = useState([]);

  const fetchTeams = async () => {
    try {
      const result = await axios.get(`${API_URL}/api/teamOptions`);
      const transformedData = result.data.map(team => ({name: team, value: team.toUpperCase()}));
      
      // Prepend the "Fields" option
      transformedData.unshift({name: 'Fields', value: 'FIELDS'});
      // Prepend the "All" option
      transformedData.unshift({name: 'All', value: ''});

      // Sort the array by name in alphabetical order
      transformedData.sort((a, b) => a.name.localeCompare(b.name));
      
      setTeamOptions(transformedData);
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const handleSelect = (event) => {
    props.onSelect(event.target.value);
  };

  const toggleDropdown = () => {
    if (!props.disabled) {
      setIsOpen(!isOpen);
    }
  };

  const selectOption = (option) => {
    setSelectedOption(option.name);
    setIsOpen(false);
    handleSelect({ target: { value: option.value } });
  };

  useEffect(() => {
    fetchTeams(); // Fetch teams when component mounts
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!props.disabled && dropdownRef.current && !dropdownRef.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, props.disabled]);

  return (
    <div className={`${styles.dropdown} ${props.disabled ? styles.dropdownDisabled : ''}`} ref={dropdownRef}>
      <div className={styles.dropdownSelected} onClick={toggleDropdown}>
        {selectedOption}
      </div>
      {isOpen && (
        <div className={styles.dropdownOptions}>
          {teamOptions.map((team) => (
            <div key={team.value} className={styles.dropdownOption} onClick={() => selectOption(team)}>
              {team.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EmpInfoTeamFilter;