import React, { useState } from 'react';
import styles from './DocumentUpload.module.css';
import buttonStyles from '../YesNoButtonsCSS/YesNoButtons.module.css';

function DocumentUpload({DocUpload, setCloseDocUpload}) {
  
  const handleClose = (e) => {
    e.stopPropagation();
    setCloseDocUpload(false);
  };

  const handleFormClick = (e) => {
    e.stopPropagation();  // Stops the click event from bubbling up to the parent div
  };

  const [comment, setComment] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  }

  const handleBlur = () => {
    if (comment === '') {
      setIsFocused(false);
    }
  }

  const [fileDisplayName, setFileDisplayName] = useState("");
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const currentFileName = e.target.files[0].name;
      setFileName(currentFileName);
      setSelectedFile(e.target.files[0]);

      if (currentFileName.length > 37) {
          setFileDisplayName(currentFileName.substring(0, 37) + '...');
      } else {
          setFileDisplayName(currentFileName);
      }
    }
  };

  const handleDocUploadClick = () => {
    const formData = new FormData();
    formData.append('doctors_note_file', selectedFile); // Append the file
    formData.append('doctors_note_file_name', fileName); // Append the file name
    formData.append('doctors_note_comment', comment); // Append the comment  
    
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    DocUpload(formData);
    setCloseDocUpload(false)
  };

  return (
    <div className={styles.backGround} onClick={handleClose}>
      <div className={styles.form} onClick={handleFormClick}>
        <div className={styles.sectionTitle}>Upload Document:</div>

        <div className={styles.row} style={{marginTop: '10px', whiteSpace: 'nowrap', maxWidth: '400px', marginBottom: '25px'}}>
          <div className={styles.fileName}>{fileDisplayName}</div>
          <input 
            type="file" 
            onChange={handleFileChange} 
            style={{ display: 'none' }} 
            id="fileUpload" 
          />
          <label htmlFor="fileUpload" className={styles.UPLOAD}>
            UPLOAD
          </label>
        </div>
 
        <div className={styles.bookingLeaveRowComment}>
          <textarea className={isFocused ? styles.bookingLeaveComment : styles.nonBookingLeaveComment} id='comment' placeholder={isFocused ? '' : 'Comment...'} onFocus={handleFocus} onBlur={handleBlur} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        
        <div className={styles.updateAndCancel}>
          <div className={styles.updateButton} onClick={handleDocUploadClick}>
            <div className={buttonStyles.YesButtonComponentStyling}>YES</div>
          </div>
          <div className={styles.cancelButton} onClick={handleClose}>
            <div className={buttonStyles.NoButtonComponentStyling}>NO</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentUpload;
