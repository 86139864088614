// Profile.js
import React from 'react';
import styles from './Dashboard.module.css';
import { Outlet } from 'react-router-dom';
import Topbar from '../../Components/Topbar/Topbar';;

export const TopbarData = [
  {
    title: 'Personal',
    link: '/dashboard/personal',
    disabled: true,
  },
  {
    title: 'Opportunities',
    link: '/dashboard/opportunities',
    disabled: false,
  },
  {
    title: 'Invoicing',
    link: '/dashboard-invoicing',
    disabled: false,
  },
];

function Dashboard() {
  return (
    <div className={styles.dashboardContainer}>
      <Topbar TopbarData={TopbarData}  />
      <Outlet />
    </div>
  );
}

export default Dashboard;