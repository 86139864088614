// DateRangePickerComponent.js

import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Holiday.css';

const DateRangePickerComponent = ({ selectedDates, onDatesChange }) => {
  const [state, setState] = useState(selectedDates);

  useEffect(() => {
    if (selectedDates.length === 0) {
      setState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        }
      ]);
    } else {
      setState(selectedDates);
    }
  }, [selectedDates]);

  const [isOpen, setIsOpen] = useState(false);
  const [clickNum, setClickNum] = useState(1)

  const handleRangeChange = (item) => {
    setClickNum((prevClickNum) => prevClickNum + 1);

    console.log(item);

    if (clickNum == 2) {
      setClickNum(1);
      setState([item.selection]);
      setIsOpen(false);
    }
    onDatesChange([item.selection]);
  };

  const formatDate = (date) => {
    const localDate = new Date(date.toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }));
    
    const day = localDate.getDate();
    const month = localDate.getMonth() + 1;
    const year = localDate.getFullYear();
  
    return `${day}/${month}/${year}`;
  };
  

  return (
    <div>
      <input 
        className='inputDateLeaveProfile'
        onClick={() => setIsOpen(true)} 
        value={state[0]?.startDate && state[0]?.endDate 
          ? `${formatDate(state[0].startDate)} - ${formatDate(state[0].endDate)}` 
          : ''} 
        readOnly
      />

      {isOpen && (
        <div onClick={() => setIsOpen(false)} className='backgroundOfDatePicker'>
          <div onClick={(e) => e.stopPropagation()} className='datePickerDiv'>
            <DateRangePicker
              onChange={handleRangeChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={state}
              direction="horizontal"
              staticRanges={[]} 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
